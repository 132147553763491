<div class="grid">
    <div class="row flex-row-reverse"><button type="button" class="btn-close close float-right align-self-end" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
    </div>
    <div class="row flex-row">
        <span aria-hidden="true" class="image">{{description}}</span>
    </div>
    <div class="row flex-row">
        <img class="image" src="assets/{{id}}"> 
    </div>
</div>