import { Component, OnInit } from '@angular/core';
import {dataBranchesService} from './services/dataBranches.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CardComponent } from './card/card.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    bsModalRef:BsModalRef;
    title = "";
    subtitle = "";

    bodyParts:any[];

    constructor(private modalService: BsModalService,
        private dataBranchesService:dataBranchesService){

    }

    ngOnInit() {
        this.bodyParts=this.dataBranchesService.startingOnes();
        this.title = this.dataBranchesService.siteTitle;
        this.subtitle = this.dataBranchesService.siteSubtitle;
    }

    openModal(id:number){
        const initialState = {
            id: id
        }
        this.bsModalRef = this.modalService.show(CardComponent, {initialState});
    }
}
