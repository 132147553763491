import { Component, Input, OnInit } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { dataBranchesService } from '../services/dataBranches.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCardComponent } from '../image-card/image-card.component';

@Component({
  selector: 'app-form-step',
  templateUrl: './form-step.component.html',
  styleUrls: ['./form-step.component.scss']
})
export class FormStepComponent implements OnInit {

  @Input() id:string;
  data;
  title:string;
  description:string;
  type:string;
  sons:any[]=[];
  images:any[];
  bsModalRef:BsModalRef;
  selectedSon:number;
  isFirstOpen:boolean;

  constructor(private modalService: BsModalService,
    private dataBranchesService:dataBranchesService) { }

  ngOnInit() {
    console.log("init: "+this.id);
    let data = this.dataBranchesService.getDataById(this.id);
    this.title = data.title;
    this.description = this.descriptionformat(data.description);
    this.sons=[];
    this.selectedSon=0;
    if (data.leadsTo!=null){
      data.leadsTo.forEach(element => {
        console.log("son: "+element);
        this.sons.push(this.dataBranchesService.getDataById(element));
        console.log(this.dataBranchesService.getDataById(element));
      });
    }
    this.images = [];
    if (data.pictures!=null){
      this.images = data.pictures;
    }
    this.isFirstOpen=true;
    console.log("images: "+data.pictures);
  }
  
  ngOnChanges() {
    this.isFirstOpen=false;
    console.log(this.id + ": change Triggered, isFirstOpen="+this.isFirstOpen);
    this.ngOnInit();
  }

  setColNumber(val:string){
    let table = [];
    if (val == "image"){
      table = this.images;
    } else {
      table = this.sons;
    }
    if (table.length <=2 || table.length == 4){
      return 2;
    } else {
      return 3;
    }
  }

  colNumber2bootstrap(val:string){
    let colNumber = this.setColNumber(val);
    if (colNumber === 2){
      return "col-6";
    } else {
      return "col-4";
    }
  }

  descriptionformat(descr:string){
    let temp = "";
    temp=descr.replace(new RegExp("\\[\\[",'g'),"<ul><li>");
    temp=temp.replace(new RegExp("\\]\\]",'g'),"</li></ul>");
    temp=temp.replace(new RegExp("\\[",'g'),"<li>");
    temp=temp.replace(new RegExp("\\]",'g'),"</li>");
    let i=1;
//    let chaine = "<span id=\""+i+"\" class=\"green\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"Examen indiqué en urgence ou en première intention\">$1<div>coucou</div></span>"
    temp=temp.replace(new RegExp("\\*v([^*]*)\\*([^*]*)\\*\\*",'g'),"<span class=\"[v]\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"[vt] [$1]\">$2</span>");
    temp=temp.replace(new RegExp("\\*o([^*]*)\\*([^*]*)\\*\\*",'g'),"<span class=\"[o]\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"[ot] [$1]\">$2</span>");
    temp=temp.replace(new RegExp("\\*r([^*]*)\\*([^*]*)\\*\\*",'g'),"<span class=\"[r]\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"[rt] [$1]\">$2</span>");
    temp=temp.replace(new RegExp("\\[v\\]",'g'),"green");
    temp=temp.replace(new RegExp("\\[o\\]",'g'),"orange");
    temp=temp.replace(new RegExp("\\[r\\]",'g'),"red");
    temp=temp.replace(new RegExp("\\[vt\\]",'g'),this.dataBranchesService.vert);
    temp=temp.replace(new RegExp("\\[ot\\]",'g'),this.dataBranchesService.orange);
    temp=temp.replace(new RegExp("\\[rt\\]",'g'),this.dataBranchesService.rouge);

    //Les raccourcis
    temp=temp.replace(new RegExp("\\[neuro\\]",'g'),"[#gbu#neurol#neuroch]");
    temp=temp.replace(new RegExp("\\[orl\\]",'g'),"[#gbu#orl]");
    temp=temp.replace(new RegExp("\\[face\\]",'g'),"[#gbu#orl#opht]");
    temp=temp.replace(new RegExp("\\[pneumo\\]",'g'),"[#gbu#pneumo]");
    temp=temp.replace(new RegExp("\\[cardio\\]",'g'),"[#gbu#cardio]");
    temp=temp.replace(new RegExp("\\[nephro\\]",'g'),"[#gbu#nephro]");
    temp=temp.replace(new RegExp("\\[uro\\]",'g'),"[#gbu#uro]");
    temp=temp.replace(new RegExp("\\[gyneco\\]",'g'),"[#gbu#gyneco]");
    temp=temp.replace(new RegExp("\\[gastro\\]",'g'),"[#gbu#gastro]");
    temp=temp.replace(new RegExp("\\[dig\\]",'g'),"[#gbu#dig]");
    temp=temp.replace(new RegExp("\\[ortho\\]",'g'),"[#gbu#ortho]");
    temp=temp.replace(new RegExp("\\[rhumato\\]",'g'),"[#gbu#rhumato]");
    temp=temp.replace(new RegExp("\\[gbu\\]",'g'),"[#gbu]");

    temp=temp.replace(new RegExp("\\[\\]",'g'),"");
    temp=temp.replace(new RegExp("\\[#([^\\]#]+)\\]",'g'),"#perma #$1");
    temp=temp.replace(new RegExp("\\[#([^\\]#]+)#([^\\]#]+)\\]",'g'),"#perma #$1 et #$2");
    temp=temp.replace(new RegExp("\\[#([^\\]#]+)#([^\\]#]+)#([^\\]#]+)\\]",'g'),"#perma #$1, #$2 et #$3");
    temp=temp.replace(new RegExp("\\[#([^\\]#]+)#([^\\]#]+)#([^\\]#]+)#([^\\]#]+)\\]",'g'),"#perma #$1, #$2, #$3 et #$4");
    temp=temp.replace(new RegExp("\\[#([^\\]#]+)#([^\\]#]+)#([^\\]#]+)#([^\\]#]+)#([^\\]#]+)\\]",'g'),"#perma #$1, #$2, #$3, #$4 et #$5");
    temp=temp.replace(new RegExp("#perma",'g'),"selon les recommandations");

    this.dataBranchesService.hashtags.forEach(element =>
      temp=temp.replace(new RegExp(element.key,'g'),element.text)
      );
    console.log("Descr: "+temp);
    return temp;
  }


  openModal(i:any){
    const initialState = {
      id: i.img,
      description: i.caption
    }
    

    this.bsModalRef = this.modalService.show(ImageCardComponent, {initialState});
  }

  selectSon(id:number){
    this.isFirstOpen=false;
    this.selectedSon=id;
  }
}
