import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './card/card.component';
import {dataBranchesService} from './services/dataBranches.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormStepComponent } from './form-step/form-step.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ImageCardComponent } from './image-card/image-card.component';
import { TextColorierComponent } from './text-colorier/text-colorier.component';

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    FormStepComponent,
    ImageCardComponent,
    TextColorierComponent
  ],
  entryComponents: [CardComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    AccordionModule.forRoot()
  ],
  providers: [dataBranchesService],
  bootstrap: [AppComponent]
})
export class AppModule { }
