<!--The content below is only a placeholder and can be replaced.-->
<div style="text-align:center">
  <h1 [innerHTML]="title">
  </h1>
  <div [innerHTML]="subtitle">
  </div>
<div class='content'>
  <img class="image" src='assets/corps humain.jpg'/>
  <div class="firstCards" *ngFor="let data of bodyParts" 
  [ngStyle]="{top:data.coord[0],left:data.coord[1],height:data.coord[2],width:data.coord[3]}"
  (click)="openModal(data.id)" data-toggle="tooltip" data-placement="right" title="{{data.title}}"
  >{{data.title}}
<!--    <app-card [id]="data.id"></app-card> -->
  </div>
</div>
<router-outlet></router-outlet>
