import { Component, OnInit, Input } from '@angular/core';
import { dataBranchesService } from '../services/dataBranches.service';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import {FormStepComponent} from '../form-step/form-step.component';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  id:string;
//  @Input() id: number;
  data:any;
  title:string;
  description:string;
  type:string;
  coord:string[];
  leadsTo:any[];
  oneAtATime: boolean = true;

  constructor(public bsModalRef:BsModalRef,
    private dataBranchesService:dataBranchesService) { }

  ngOnInit() {
    let data = this.dataBranchesService.getDataById(this.id);
    this.title = data.title;
    this.description = data.description;
    //no use in there but already historical TODO remove this if
    if (data.type === "initial"){
      this.coord=data.coord;
    }
    if (data.type!=="final"){
      this.leadsTo = data.leadsTo;
    }
  }

}
