import { Injectable } from "@angular/core";
@Injectable()
export class dataBranchesService {

    siteTitle = "Bienvenue sur Urgence Imagerie";
    siteSubtitle = "Ce site vous aide à la demande d'examens d'imagerie en urgence.<br/>\
    Bonne navigation !";

    // v, o, r
    vert = "Examen indiqué en urgence ou en première intention";
    orange = "Examen indiqué en semi-urgence ou en seconde intention";
    rouge = "Examen non indiqué dans le contexte de l'urgence";

    /*  Liste des hashtags
        On peut en cumuler jusqu'à 5 sans mettre d'espace avec une couleur, exemple : *v#gbu#neurol#neuroch*
    */
    hashtags = [
        { key: "#neurol", text: "du Référentiel du Collège des Enseignements de Neurologie" },
        { key: "#neuroch", text: "du Référentiel du Collège de Neurochirurgie" },
        { key: "#orl", text: "du Référentiel du Collège Français d'ORL et de chirurgie cervico-faciale" },
        { key: "#opht", text: "du Collège des Ophtalmologistes Universitaires de France" },
        { key: "#pneumo", text: "du Référentiel du Collège des Enseignants de Pneumologie" },
        { key: "#cardio", text: "du Référentiel du Collège National des Enseignants de Cardiologie - Société Française de Cardiologie" },
        { key: "#nephro", text: "du Référentiel du Collège Universitaires des Enseignants de Néphrologie" },
        { key: "#uro", text: "du Référentiel du Collège Français des Urologues" },
        { key: "#gyneco", text: "du Référentiel du Collège national des Gynécologues et Obstétriciens Français" },
        { key: "#gastro", text: "du Référentiel du Collège des Universitaires en Hépato-Gastro-Entérologie" },
        { key: "#dig", text: "du Référentiel du Collège National des Universités de Chirurgie Viscérale et Digestive" },
        { key: "#ortho", text: "du Référentiel du Collège Français des Chirurgiens Orthopédistes et Traumatologues" },
        { key: "#rhumato", text: "du Référentiel du Collège Français des enseignants en Rhumatologie" },
        { key: "#gbu", text: "du Guide du Bon Usage des examens d'imagerie médicale (GBU)" },
        { key: "#rea", text: "du Référentiel du Collège des Enseignants de Médecine Intensive Réanimation." },
        { key: "#vascu", text: "du Référentiel du Collège des Enseignants de Médecine Vasculaire." },
        { key: "#radio", text: "de la Société Française de Radiologie." },
        { key: "#atlanta", text: "des Recommandations d'Atlanta.'" },
        { key: "#cirtaci", text: "des dernières Recommandations du CIRTACI (Comité Interdisciplinaire de Recherche et de Travail sur les Agents de Contraste en Imagerie - Société Française de Radiologie).'" }
    ];

    /*  Liste des raccourcis, a utiliser avec une couleur, exemple *vneuro*
        neuro   = #gbu#neurol#neuroch
        orl     = #gbu#orl
        face    = #gbu#orl#opht
        pneumo  = #gbu#pneumo
        cardio  = #gbu#cardio
        nephro  = #gbu#nephro
        uro     = #gbu#uro
        gyneco  = #gbu#gyneco
        gastro  = #gbu#gastro
        dig     = #gbu#dig
        ortho   = #gbu#ortho
        rhumato = #gbu#rhumato
        gbu     = #gbu
    */

    dataBase = [
        {
            "id": "1",
            "type": "initial",
            //haut,gauche,hauteur,largeur
            "coord": ['-800px', '340px', '90px', '110px'],
            "title": "CRANE-FACE NEUROLOGIE",
            "description": "Choisissez la localisation de la pathologie suspectée.",
            "leadsTo": ["1.0", "1.1", "1.2"]
        },
        {
            "id": "2",
            "type": "initial",
            "coord": ['-800px', '355px', '50px', '80px'],
            "title": "ORL",
            "description": "De quelle situation clinique relève votre patient?",
            "leadsTo": ["2.0", "2.1", "2.2", "2.3", "2.4", "2.5", "2.6"]
        },
        {
            "id": "3",
            "type": "initial",
            "coord": ['-800px', '310px', '140px', '170px'],
            "title": "THORAX",
            "description": "Choisissez la localisation de la pathologie suspectée.",
            "leadsTo": ["3.0", "3.1", "3.2", "3.3", "3.4", "3.5"]
        },
        {
            "id": "4",
            "type": "initial",
            "coord": ['-800px', '310px', '140px', '170px'],
            "title": "ABDOMEN-PELVIS",
            "description": "Choisissez le système dont dépend la pathologie suspectée.",
            "leadsTo": ["4.0", "4.1", "4.2", "4.3", "4.4", "4.5", "4.6"]
        },
        {
            "id": "5",
            "type": "initial",
            "coord": ['-1080px', '90px', '200px', '220px'],
            "title": "MUSCULOSQUELETTIQUE NON TRAUMATIQUE",
            "description": "",
            "leadsTo": ["5.0.0", "5.0.1", "5.0.2"]
        },
        {
            "id": "6",
            "type": "initial",
            "coord": ['-1000px', '310px', '280px', '170px'],
            "title": "VASCULAIRE",
            "description": "De quelle situation relève votre patient?",
            "leadsTo": ["6.0", "6.1", "6.2", "6.3", "1.2.0"]
        },
        {
            "id": "7",
            "type": "initial",
            "coord": ['-1560px', '480px', '200px', '220px'],
            "title": "PATHOLOGIES TRAUMATIQUES / POLYTRAUMATISME",
            "description": "De quelle situation relève votre patient?",
            "leadsTo": ["7.0", "7.1", "7.2", "7.3", "7.4", "7.5", "7.6", "7.7"]
        },
        {
            "id": "8",
            "type": "initial",
            "coord": ['-1400px', '90px', '100px', '130px'],
            "title": "SOS PRODUIT DE CONTRASTE",
            "description": "Quel examen / Quel type de produit ?",
            "leadsTo": ["8.0", "8.1"]
        },
        /*      {
                    "id": "9",
                    "type": "initial",
                    "coord": ['-1400px', '90px', '100px', '130px'],
                    "title": "A VOIR",
                    "description": "Quel examen / Quel type de produit ?",
                    "leadsTo": []
                    //TODO remplir l'objet
                },
        */
        {
            "id": "Credits",
            "type": "initial",
            "coord": ['-1500px', '570px', '100px', '130px'],
            "title": "A PROPOS DE CE SITE",
            "description": "Dans cette section, retrouvez des informations utiles à la compréhension de ce travail, comme par exemple l'ensemble de la documentation qui a été utilisée en amont de la réalisation du site Urgence Imagerie.",
            "leadsTo": ["Credits.0", "Credits.1", "Credits.2", "Credits.3"]
        },
         {
            "id": "Credits.0",
            "title": "Manuel et Généralités",
            "description": "<span>Ce site a été développé par Maxime Gouguet (interne en Radiologie et Imagerie médicale) et Nicolas Depierreux (ingénieur informaticien) dans le cadre de la Thèse d'exercice de Médecine de Maxime Gouguet, dirigée par le Docteur Marie BESSON.<br/>\
            Les données présentées proviennent de sources multiples (dont la nouvelle version du Guide du Bon Usage des examens d'imagerie médicale, intitulée désormais Aide à la demande d'examen de Radiologie et Imagerie médicale (ADERIM) ; dont les Référentiels des Collèges Universitaires des Enseignants des spécialités d'intérêt ; dont des Recommandations de Bonne pratique de la Haute Autorité de Santé, entre autres).<br/><br/>\
            </span><div class=\"video\"><span>Video de présentation : <br/></span>\
            <video controls width=\"400\">\
            <source src=\"assets/Urgence Imagerie.mp4\" type=\"video/mp4\"></video></div><br/><br/>\
            <span>Manuel :<br/>\
            Pour utiliser l'application, cliquez sur une zone du corps humain et suivez les questions permettant d'atteindre votre cas<br/>\
            A des fins de lisibilité pour l'utilisateur, il a été utilisé un code couleur : \
            [[*v*La couleur verte indique que l'examen d'imagerie cité est indiqué en urgence ou en première intention dans le contexte de l'urgence**]\
            [*o*La couleur orange indique que l'examen d'imagerie cité est indiqué en semi-urgence ou en seconde intention dans le contexte de l'urgence**]\
            [*r*La couleur violette indique que l'examen d'imagerie cité n'est pas indiqué dans le contexte de l'urgence**]]<br/>\
            Lorsque l'utilisateur passe sa souris (ou son doigt) au-dessus d'un code couleur, celui-ci le renvoie vers les sources d'intérêt.\
            </span>"
        },
        {
            "id": "Credits.1",
            "title": "Documentation",
            "description": "Vous trouverez ci-dessous la thèse et ses annexes :<br/>\
            [[<a href='assets/These-GOUGUET-Maxime-final.pdf'>Manuscrit de Thèse de Maxime GOUGUET.</a>]\
            [<a href='assets/1-Annexe-Neurologie.pdf'>Annexe des pathologies neurologiques et neurochirurgicales.</a>]\
            [<a href='assets/2-Annexe-Face-ORL.pdf'>Annexe des pathologies cervico-faciales.</a>]\
            [<a href='assets/3-Annexe-Thorax.pdf'>Annexe des pathologies thoraciques.</a>]\
            [<a href='assets/4-Annexe-Senologie.pdf'>Annexe des pathologies sénologiques.</a>]\
            [<a href='assets/5-Annexe-Pathologies-Digestives.pdf'>Annexe des pathologies du système digestif.</a>]\
            [<a href='assets/6-Annexe-Urologie-Nephrologie-Genital-masculin.pdf'>Annexe des pathologies urologiques, néphrologiques et genitales masculines.</a>]\
            [<a href='assets/7-Annexe-Gynecologie-Obstetrique.pdf'>Annexe de pathologies gynéco-obstétricales et de la grossesse.</a>]\
            [<a href='assets/8-Annexe-Traumatismes.pdf'>Annexe des pathologies musculo-squelettiques traumatiques.</a>]\
            [<a href='assets/9-Annexe-Squelettique-Non-Traumatique.pdf'>Annexe des pathologies musculo-squelettiques non-traumatiques.</a>]\
            [<a href='assets/10-Annexe-Vasculaire.pdf'>Annexe des pathologies vasculaires.</a>]]\
            <br/><br/>\
           Dernière mise à jour des données réalisée le 30/01/2022<br/><br/>"
        },
        {
            "id": "Credits.2",
            "title": "Contact",
            "description": "Pour toute question ou demande de précision, veuillez contacter notre support à l'adresse suivante : urgence.imagerie@gmail.com",
        },
        {
            "id": "Credits.3",
            "title": "Remerciements",
            "description": "Remerciements :<br/>\
            A Monsieur Nicolas DEPIERREUX, pour son aide incroyable tout au long de ce projet. Tu as toute ma reconnaisance, et bien plus que cela.<br/>\
            A Madame le Docteur Marie BESSON, directrice de ce travail, de m'avoir aidé de l'idée initiale aux finalisations du site et du papier. Merci de m'avoir fait aimer l'imagerie digestive. Merci pour tout Marie.<br/>\
            A mon jury de Thèse, Messieurs les Professeurs Laurent BRUNEREAU, Jean-Philippe COTTIER ; Messieurs les Docteur Baptiste MOREL (MCU-PH) et Antoine BONNIOL ; et Madame le Docteur Marie BESSON.<br/>\
            Au CHRU de TOURS pour son soutien.<br/>\
            A Monsieur Pierre-Antoine DURAND (société PAD PROD VIDEO) pour sa précieuse aide dans la diffusion de ce travail et dans la communication autour de lui.<br/>\
            A mes maîtres pour leurs enseignements et leurs conseils.<br/>\
            A Corinne et Bernard, mes parents tant aimés, pour leur soutien indéfectible et leur Amour incommensurable. Je vous aime.<br/>\
            A Marie-Alice, pour tous les bons moments passés ensemble, les douces années déjà passées à tes côtés et celles à venir. Je t'aime et je veux vivre auprès de toi.<br/>\
            A tous mes amis et mes co-internes.<br/>\
            A ma belle-famille et mes neveux et nièces tant chéris.<br/>\
            A tous ceux qui ont cru en nous et en ce projet.<br/>\<br/>\
            Autres:<br/>\
            icone du site sous licence Creative Common <a href='https://thenounproject.com/search/?q=Xray&i=3075756'>xray</a> by Clea Doltz from the Noun Project<br/>\
            Ecorché : <a href='https://www.fotosearch.fr'>(c) ArtAndSoulPhoto www.fotosearch.fr Stock Photography</a> Droit d'utilisation acheté par Mr Nicolas DEPIERREUX, co-créateur du site"
        },
        {
            "id": "Erreur",
            "title": "Erreur : Donnée manquante",
            "description": "La donnée à laquelle vous souhaitez accéder n'a pas été entrée dans la base de données.<br/>\
            Veuillez indiquer au responsable du site le chemin d'accès de cette information pour qu'il puisse l'ajouter. Support : urgence.imagerie@gmail.com<br/>\
            Cliquez en dehors du cadre pour revenir à la page principale et continuer votre navigation.",
        },
        //START NEURO
        {
            "id": "1.0",
            "title": "Crâne",
            "description": "",
            "leadsTo": ["1.0.0", "1.0.1", "1.0.2", "1.0.3", "1.0.4", "1.0.5", "1.0.6", "1.0.7", "1.0.8", "1.0.9"]
        },
        {
            "id": "1.1",
            "title": "Face - Paires crâniennes",
            "description": "",
            "leadsTo": ["1.1.0", "1.1.1", "1.1.2", "1.1.3", "1.1.4", "1.1.5"]
        },
        {
            "id": "1.2",
            "title": "Rachis",
            "description": "De quelle situation relève votre patient?",
            "leadsTo": ["1.2.1", "1.2.2", "7.3", "1.2.3"]
        },
        //start trauma cranien
        {
            "id": "1.0.0",
            "title": "Traumatisme crânien",
            "description": "Le patient présente-t-il au moins un des critères suivants?<br/>\
            [[Patient GCS < ou = 13]\
            [Altération de conscience]\
            [Fracture embarrure]\
            [Plaie pénétrante]\
            [Signes neurologiques focaux]]",
            "leadsTo": ["1.0.0.0", "1.0.0.1"]
        },
        {
            "id": "1.0.0.0",
            "title": "Au moins un des critères cités",
            "description": "*v#gbu#neurol#neuroch*Scanner encéphalique sans injection en urgence.**",
            "pictures": [
                { "img": "1000.1.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant un hématome extra-dural de la convexité gauche (étoile rouge), de multiples spots d'hémorragie sous-arachnoïdienne (flèches rouges) et une infiltration hématique des parties molles sous-cutanées (flèche verte)" },
                { "img": "1000.2.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant une fracture-embarrure complexe de la voûte crânienne (étoile rouge), associée à un saignement récent dans la vallée sylvienne droite (flèche rouge), un très volumineux hématome des parties molles sous-cutanées en regard de la fracture (étoile verte). L'oedème cérébral local est responsable d'un engagement sous-falcoriel (flèche rose)." }
            ]
        },
        {
            "id": "1.0.0.1",
            "title": "Aucun des critères cités",
            "description": "Le patient présente-t-il au moins un des critères suivants?<br/>\
            [[Patient de plus de 65 ans]\
            [Patient GCS=14]\
            [Perte de connaissance]\
            [Amnésie]\
            [Comitialité]\
            [Intoxication associée]\
            [Céphalée progressive]\
            [Vomissements]\
            [Polytraumatisé]\
            [Coagulopathie / Anticoagulation au long cours]\
            [Lésions faciales sévères]\
            [Suspicion de fracture de la base du crâne]]",
            "leadsTo": ["1.0.0.2", "1.0.0.3"]
        },
        {
            "id": "1.0.0.2",
            "title": "Au moins un des critères cités",
            "description": "*v#gbu#neurol#neuroch*Scanner encéphalique sans injection à 6 heures du traumatisme**",
            "pictures": [
                { "img": "1002.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant une hémorragie sous-arachnoïdienne diffuse, notamment au sein de la vallée sylvienne gauche (grande étoile rouge), du fond des sillons corticaux (petite étoile rouge), avec inondation du système ventriculaire (flèches rouges)." },
            ]
        },
        {
            "id": "1.0.0.3",
            "title": "Aucun des critères",
            "description": "*r#gbu#neurol#neuroch*Pas d'examen d'imagerie indiqué en urgence.**",
        },
        //start syndrome confusionnel
        {
            "id": "1.0.1",
            "title": "Syndrome confusionnel",
            "description": "Le patient présente-t-il au moins un des critères suivants?<br/>\
            [[Traumatisme crânien (même mineur)]\
            [Suspicion d'hémorragie méningée]\
            [Signe de localisation neurologique]]",
            "leadsTo": ["1.0.1.0", "1.0.1.1"]
        },
        {
            "id": "1.0.1.0",
            "title": "Au moins un des critères",
            "description": "*v#neurol*Scanner encéphalique sans injection en urgence.**",
            "pictures": [
                { "img": "1010.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant un hématome intraparenchymateux profond récent (flèche rouge), déplaçant les structures de la ligne médiane (engagement sous-falcoriel)." },
            ]
        },
        {
            "id": "1.0.1.1",
            "title": "Aucun critère",
            "description": "*r#neurol*Pas de scanner encéphalique en urgence.**<br/>\
            *o#neurol*Scanner encéphalique à discuter en seconde intention (hors urgence) si l'étiologie du syndrôme confusionnel n'est pas évidente.**",
        },
        //Start deficit neurologique
        {
            "id": "1.0.2",
            "title": "Déficit neurologique récent",
            "description": "Le déficit neurologique est-il fébrile ?",
            "leadsTo": ["1.0.2.0", "1.0.2.1"]
        },
        {
            "id": "1.0.2.0",
            "title": "Fébrile",
            "description": "",
            "leadsTo": ["1.0.2.2", "1.0.2.3"]
        },
        {
            "id": "1.0.2.1",
            "title": "Non fébrile",
            "description": "Le déficit est-il permanent ou transitoire ?",
            "leadsTo": ["1.0.2.6", "1.0.2.5"]
        },
        {
            "id": "1.0.2.2",
            "title": "Suspicion de méningo-encéphalite",
            "description": "Traitement en urgence avant prise en charge en imagerie.<br/>\
            *v#gbu#neurol*IRM encéphalique en urgence.<br/>\
            Si IRM non disponible, scanner encéphalique injecté en urgence.**",
            "pictures": [
                { "img": "1022.jpg", "caption": "Coupe axiale d'une IRM en pondération FLAIR montrant un important oedème cortico-sous-cortical temporal droit (étoile rouge), en faveur d'une encéphalite (à Herpès)." },
                { "img": "1022.0.jpg", "caption": "Coupes axiales d'une IRM (en pondération FLAIR et en pondération T1 EG après injection de sels de Gadolinium, montrant de multiples lésions prenant le contraste en cocarde (flèches rouges), associées à de larges plages oedémateuses au pourtour (étoiles bleues) chez une patiente immunodéprimée (séropositive VIH). L'aspect et le contexte clinique font évoquer une toxoplasmose cérébrale." }
            ]
        },
        {
            "id": "1.0.2.3",
            "title": "Suspicion d'AVC surajouté à une cause de fièvre.",
            "description": "Renvoi vers déficit neurologique récent non fébrile.",
            "leadsTo": ["1.0.2.1"]
        },
        {
            "id": "1.0.2.5",
            "title": "Transitoire",
            "description": "*v#gbu#neurol*En urgence : [[Orientation en UNV souhaitable]\
            [Si syndrome de menace : IRM dans les 24H ou scanner encéphalique injecté en urgence]]**\
            *o#gbu#neurol*Hors Urgence : [[Echographie-Doppler des TSA (en première intention),]\
            [Complétée si besoin par une imagerie en coupe (Angio-RM ou angioscanner)]]**",
        },
        {
            "id": "1.0.2.6",
            "title": "Permanent",
            "description": "Le patient est déficitaire depuis :",
            "leadsTo": ["1.0.2.8", "1.0.2.9"]
        },
        {
            "id": "1.0.2.8",
            "title": "Moins de 4H30 (ou horaire de début inconnu)",
            "description": "*v#gbu#neurol*En urgence : <br/>[[Orientation en UNV.]\
            [IRM encéphalique ou scanner à défaut (Scanner de perfusion si possible) en urgence avant éventuelle thrombolyse ou thrombectomie.]]**\
            *o#gbu#neurol*Hors Urgence : [[Echographie-Doppler des TSA en première intention.]\
            [Si besoin compléter par une imagerie en coupe (Angio-RM ou angioscanner).]\
            [Echographie trans-thoracique pour bilan cardiologique.]]**",
            "pictures": [
                { "img": "1028.jpg", "caption": "Coupe axiale en diffusion d'une IRM réalisée dans le cadre d'une alerte thrombolyse, montrant des hypersignaux du territoire sylvien superficiel gauche, secondaire à une occlusion de l'artère sylvienne homolatérale (voir image 2)." },
                { "img": "1028.1.jpg", "caption": "Reconstruction tridimensionnelle d'une acquisition TOF réalisée à l'IRM dans le cadre d'une alerte thrombolyse. Pas de flux dans l'artère sylvienne gauche, induisant une ischémie d'aval (voir image 1)." }
            ]
        },
        {
            "id": "1.0.2.9",
            "title": "Plus de 4H30",
            "description": "Appeler en urgence l'UNV (Unité Neuro-Vasculaire) dont dépend votre centre pour avis",
            "leadsTo": ["1.0.2.10", "1.0.2.11", "1.0.2.12"]
        },
        {
            "id": "1.0.2.10",
            "title": "Patient accepté en UNV",
            "description": "*v#gbu#neurol*En Urgence : IRM encéphalique (ou scanner de perfusion à défaut) à réaliser dans le centre d'UNV.**<br\>\
            *o#gbu#neurol*Hors Urgence : [[Echographie - Doppler des TSA en première intention.]\
            [Si besoin compléter par une imagerie en coupe (Angioscanner ou Angio-RM).]\
            [Echographie trans-thoracique pour bilan cardiologique.]]**",
            "pictures": [
                { "img": "10210.jpg", "caption": "Coupe axiale en diffusion d'une IRM réalisée dans le cadre d'une alerte thrombolyse, montrant une souffrance ischémique dans le territoire du tronc basilaire (flèche rouge). Voir aussi image 2 " },
                { "img": "10210.1.jpg", "caption": "Coupe axiale d'une séquence TOF réalisée dans le cadre d'une alerte thrombolyse, montrant l'absence de flux au sein du tronc basilaire (flèche rouge), alors que les artères carotides internes présentent un flux normal. Voir aussi image 1." }
            ]
        },
        {
            "id": "1.0.2.11",
            "title": "Patient possiblement éligible à une thrombectomie tardive",
            "description": "*v#gbu#neurol*En Urgence : Scanner encéphalique injecté (à réaliser si possible dans le centre d'UNV).**<br\>\
            *o#gbu#neurol*Hors Urgence : [[Echographie - Doppler des TSA en première intention.]\
            [Si besoin compléter par une imagerie en coupe (Angioscanner ou Angio-RM.]\
            [Echographie trans-thoracique pour bilan cardiologique.]]**",
            "pictures": [
                { "img": "10211.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant un oedème cérébral localisé au territoire sylvien droit, avec dédifférenciation cortico-sous-corticale (flèche rouge), absence de visibilité du noyau lenticulaire homolatéral (étoile rouge) et effacement de la vallée sylvienne (étoile verte), en faveur d'un accident vasculaire cérébral ischémique sylvien récent." },
                { "img": "10211.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique avec injection montrant une dissection de l'artère vertébrale droite (flèche rouge) sous la forme d'une réduction de calibre focale et non constitutionnelle, comme en témoigne un foramen osseux plus large que le calibre luminal de l'artère. Celle-ci est responsable d'une ischémie aigue de fosse postérieure (non montrée ici). L'artère vertébrale controlatérale est marquée d'une flèche verte, pour comparaison." },
            ]
        },
        {
            "id": "1.0.2.12",
            "title": "Patient récusé de l'UNV",
            "description": "*o#gbu#neurol*En semi-urgence : scanner encéphalique sans injection**<br\>\
            *o#gbu#neurol*Hors Urgence : [[Echographie - Doppler des TSA en première intention.]\
            [Si besoin compléter par une imagerie en coupe (Angioscanner ou Angio-RM).]\
            [Echographie trans-thoracique pour bilan cardiologique.]]**",
            "pictures": [
                { "img": "10212.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection (reconstruction en coupes épaisses), montrant le signe dit de la << trop belle artère sylvienne >> droite (flèche rouge) comparativement à l'artère sylvienne controlatérale (flèche verte). Ce signe est en faveur d'une thrombose récente de l'artère sylvienne droite." },
                { "img": "10212.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant un accident vasculaire cérébral ischémique sylvien droit (étoile rouge) en cours de constitution, avec oedème cérébral marqué (flèches roses) et engagement sous-falcoriel controlatéral (flèche bleue)." },
                { "img": "10212.1.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant un saignement intra-axial fronto-pariétal droit (étoile rouge) nodulaire associé à oedème cérébral local marqué. Cet aspect fait évoquer un saignement secondaire à une thrombophlébite d'une veine de drainage (non visible ici) en premier lieu. La flèche verte montre, quant à elle, un hématome des parties molles sous-cutanées (probablement en lien avec un traumatisme crânien)." }
            ]
        },
        //Start Syndrome meninge
        {
            "id": "1.0.3",
            "title": "Syndrome méningé",
            "description": "Fébrile ou non fébrile ?",
            "leadsTo": ["1.0.3.0", "1.0.3.1"]
        },
        {
            "id": "1.0.3.0",
            "title": "Fébrile",
            "description": "Y a-t-il un déficit neurologique associé ?",
            "leadsTo": ["1.0.3.2", "1.0.3.3"]
        },
        {
            "id": "1.0.3.1",
            "title": "Non fébrile",
            "description": "*v#gbu#neurol#neuroch*Scanner encéphalique sans injection en urgence, complété par une injection au temps artériel (sur le polygone de Willis) si anomalie décelée.**",
            "pictures": [
                { "img": "1031.1.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant une hémorragie sous-arachnoïdienne diffuse et massive (flèches rouges). Voir aussi image 2." },
                { "img": "1031.2.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique injecté sur le polygone de Willis avec reconstruction en MIP. Volumineux anévrisme de l'artère communicante antérieure rompu (flèche bleue), responsable de l'hémorragie sous-arachnoïdienne. Voir aussi image 1." }
            ]
        },
        {
            "id": "1.0.3.2",
            "title": "Sans déficit neurologique",
            "description": "Le patient présente-t-il au moins un des signes suivants?<br/>\
            Patient GCS < ou = 11<br/>\
            Signes de localisation<br/>\
            Signes cliniques d'hypertension intra-crânienne<br/>\
            Crise convulsive généralisée ou focalisée récente",
            "leadsTo": ["1.0.3.4", "1.0.3.5"]
        },
        {
            "id": "1.0.3.3",
            "title": "Avec déficit neurologique",
            "description": "*v#gbu#neurol#neuroch*IRM encéphalique en urgence (scanner encéphalique en urgence si IRM indisponible).**",
            "pictures": [
                { "img": "1022.jpg", "caption": "Coupe axiale d'une IRM en pondération FLAIR montrant un important oedème cortico-sous-cortical temporal droit (étoile rouge), en faveur d'une encéphalite (à Herpès)." }
            ]
        },
        {
            "id": "1.0.3.4",
            "title": "Au moins un des signes cités",
            "description": "*v#gbu#neurol#neuroch*IRM encéphalique (ou à défaut scanner encéphalique) avant réalisation de la ponction lombaire\
             afin d'éliminer formellement une contre-indication au geste.**",
            "pictures": [
                { "img": "1034.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant une hydrocéphalie massive : dilatation de l'ensemble des cavités ventriculaires (étoiles roses), contre-indiquant la réalisation de la ponction lombaire." },
                { "img": "1034.1.jpg", "caption": "Coupe axiale d'une IRM cranio-encéphalique en pondération T1 après injection de sels de Gadolinium, montrant une prise de contraste méningée (fond des sillons notamment) prédominant sur l'hémisphère gauche, en faveur d'une méningite." },
                { "img": "1034.2.jpg", "caption": "Coupe axiale d'une IRM cranio-encéphalique en pondération T1 après injection de sels de Gadolinium, montrant une prise de contraste méningé panhémisphérique gauche (flèches rouges). Les flèches vertes montrent l'aspect attendu (le côté controlatéral étant normal). Le contexte et les autres séquences (non montrées ici) sont en faveur d'un angiome pial (syndrome de Sturge-Webber)." },
            ]
        },
        {
            "id": "1.0.3.5",
            "title": "Aucun des signes cités",
            "description": "*v#gbu#neurol#neuroch*Pas d'examen d'imagerie nécessaire avant réalisation de la ponction lombaire.**",
        },
        //Start crise convulsive
        {
            "id": "1.0.4",
            "title": "Crise(s) convulsive(s)",
            "description": "Le patient présente-t-il au moins un des critères suivants?<br/>\
            [[Etat de mal épileptique]\
            [Fièvre]\
            [Déficit neurologique persistant post-critique]]",
            "leadsTo": ["1.0.4.0", "1.0.4.1"]
        },
        {
            "id": "1.0.4.0",
            "title": "Au moins l'un des critères",
            "description": "*v#gbu#neurol*IRM encéphalique en urgence (ou scanner encéphalique injecté en urgence si IRM non disponible).**",
            "pictures": [
                { "img": "1022.0.jpg", "caption": "Coupes axiales d'une IRM (en pondération FLAIR et en pondération T1 EG après injection de sels de Gadolinium, montrant de multiples lésions prenant le contraste en cocarde (flèches rouges), associées à de larges plages oedémateuses au pourtour (étoiles bleues) chez une patiente immunodéprimée (séropositive VIH). L'aspect et le contexte clinique font évoquer une toxoplasmose cérébrale." },
                { "img": "1040.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique injecté montrant une lésion se réhaussant en cocarde (étoile rouge), développée au carrefour fronto-pariétal droit." }, 
                 { "img": "1040.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant une hémorragie sous-arachnoïdienne hémisphérique gauche de la vallée sylvienne (flèche rouge) et du fond des sillons (tête de flèche bleue)." },
            ]
        },
        {
            "id": "1.0.4.1",
            "title": "Aucun des critères",
            "description": "*v#gbu#neurol*Scanner encéphalique sans injection en urgence (pour éliminer un saignement intracrânien).**<br/>\
            *o*IRM encéphalique sans urgence pour bilan étiologique.**",
            "pictures": [
                { "img": "1041.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant des hématomes sous-duraux chroniques (étoiles vertes) avec dépôts déclives en leur sein (flèches roses) signant un re-saignement semi-récent bilatéral et des plages denses (flèches rouges) témoignant de saignements aigus dans l'hématome sous-dural droit." },
                { "img": "10212.1.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant un saignement intra-axial fronto-pariétal droit (étoile rouge) nodulaire associé à oedème cérébral local marqué. Cet aspect fait évoquer un saignement secondaire à une thrombophlébite d'une veine de drainage (non visible ici) en premier lieu. La flèche verte montre, quant à elle, un hématome des parties molles sous-cutanées (probablement en lien avec un traumatisme crânien)." },
                 { "img": "1040.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant une hémorragie sous-arachnoïdienne hémisphérique gauche de la vallée sylvienne (flèche rouge) et du fond des sillons (tête de flèche bleue)." },
            ]
        },
        //Start hydrocephalie
        {
            "id": "1.0.5",
            "title": "Hydrocéphalie",
            "description": "*v#gbu#neurol*IRM encéphalique en urgence.<br/>\
            Si IRM non disponible : scanner encéphalique en urgence puis IRM à distance.<br/>\
            Si sonde de dérivation déjà en place : contrôle radiographique de sa position souhaitable en urgence.**",
            "pictures": [
                { "img": "105.jpg", "caption": "Coupe axiale d'une scanner cranio-encéphalique sans injection montrant une dilatation massive du système ventriculaire (étoile rose)." },
                { "img": "105.1.jpg", "caption": "Coupe coronale d'une IRM en pondération T2 montrant une hydrocéphalie (étoile bleue) obstructive sur une masse ventriculaire (flèche rouge)." },
                { "img": "105.00.jpg", "caption": "Coupe sagittale d'une IRM cranio-encéphalique pondérée en T1, montrant un ptose des amygdales cérébelleuses (flèche rouge) en arrière de la moelle cervicale. Le plan virtuel du foramen magnum est matérialisé par une ligne de pointillés verts. Le tout fait évoquer une malformation de Chiari." },
            ]
        },
        //Start cephalees
        {
            "id": "1.0.6",
            "title": "Céphalées récentes inhabituelles",
            "description": "L'examen clinique est-il normal ?",
            "leadsTo": ["1.0.6.0", "1.0.6.1"]
        },
        {
            "id": "1.0.6.0",
            "title": "Examen clinique normal",
            "description": "Comment les céphalées ont-elles commencé ?",
            "leadsTo": ["1.0.6.2", "1.0.6.3"]
        },
        {
            "id": "1.0.6.1",
            "title": "Examen clinique anormal",
            "description": "Quel diagnostic clinique suspectez-vous ?",
            "leadsTo": ["1.0.6.4", "1.0.6.5", "1.0.6.6", "1.0.6.7", "1.0.6.8", "1.0.6.9"]
        },
        {
            "id": "1.0.6.2",
            "title": "Début brutal",
            "description": "*v#gbu#neurol*En urgence : Scanner encéphalique sans injection complété par une injection au temps artériel (sur le polygone de Willis) si anomalie décelée.<br/>\
            En fonction du contexte, il peut être complété soit par une IRM encéphalique soit par un angioscanner des troncs supra-aortiques.**",
            "pictures": [
                { "img": "105.jpg", "caption": "Coupe axiale d'une scanner cranio-encéphalique sans injection montrant une dilatation massive du système ventriculaire (étoile rose)." },
                { "img": "105.0.jpg", "caption": "Coupe axiale d'une scanner cranio-encéphalique réalisé après injection de produit de contraste iodé, montrant un défaut d'opacification du sinus latéral gauche (flèches rouges) et de la veine jugulaire interne gauche, en rapport avec une thrombophlébite cérébrale." }
            ]
        },
        {
            "id": "1.0.6.3",
            "title": "Début insidieux ou progressif",
            "description": "*v#gbu#neurol*IRM encéphalique et Angio-RM encéphalique en urgence (pour éliminer une thrombose veineuse encéphalique).<br/>\
            Si IRM non disponible: scanner encéphalique injecté en urgence.**",
            "pictures": [
                { "img": "1063.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique réalisé à 50 secondes de l'injection de produit de contraste, montrant une absence d'opacification du sinus latéral gauche (flèche rouge), un thrombus non obstructif (flèche orange) visible dès la région proximale du sinus latéral gauche et une opacification conservée du sinus latéral controlatéral (flèche verte)." },
                { "img": "105.00.jpg", "caption": "Coupe sagittale d'une IRM cranio-encéphalique pondérée en T1, montrant un ptose des amygdales cérébelleuses (flèche rouge) en arrière de la moelle cervicale. Le plan virtuel du foramen magnum est matérialisé par une ligne de pointillés verts. Le tout fait évoquer une malformation de Chiari." }
            ]
        },
        {
            "id": "1.0.6.4",
            "title": "Absence d'orientation clinique évidente",
            "description": "*v#gbu#neurol*IRM encéphalique en urgence.<br/>\
            Si IRM non disponible : scanner encéphalique sans injection en urgence. Il pourra être complété par une injection au besoin.**",
            "pictures": [
                { "img": "1064.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique réalisé après injection de produit de contraste (sur le polygone de Willis) montrant des dilatations vasculaires (artérielles et veineuses) avec un nidus, en faveur d'une malformation artério-veineuse (flèche verte)." },
                { "img": "1064.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique réalisé après injection de produit de contraste (sur le polygone de Willis) montrant un volumineux anévrisme de l'artère communicante antérieure (flèche rouge), avec une hémorragie sous-arachnoïdienne diffuse secondaire (étoiles roses)." }
            ]
        },
        {
            "id": "1.0.6.5",
            "title": "Syndrome méningé non fébrile",
            "description": "Suspicion d'hémorragie sous-arachnoïdienne.<br/>\
            Redirection vers syndrome méningé non fébrile",
            "leadsTo": ["1.0.3.1"]
        },
        {
            "id": "1.0.6.6",
            "title": "Syndrome méningé fébrile",
            "description": "Suspicion de méningite.<br/>\
            Redirection vers syndrome méningé fébrile",
            "leadsTo": ["1.0.3.0"]
        },
        {
            "id": "1.0.6.7",
            "title": "Signe de localisation non fébrile",
            "description": "Suspicion d'AVC.<br/>\
            Redirection vers trouble neurologique récent non fébrile",
            "leadsTo": ["1.0.2.1"]
        },
        {
            "id": "1.0.6.8",
            "title": "Signe de localisation fébrile",
            "description": "Suspicion d'encéphalite ou processus infection intra-crânien. Redirection vers trouble neurologique récent Fébrile",
            "leadsTo": ["1.0.2.0"]
        },
        {
            "id": "1.0.6.9",
            "title": "Suspicion d'intoxication",
            "description": "*o*Indication à l'imagerie (si possible IRM encéphalique ou à défaut scanner injecté) seulement en cas de coma durable après traitement de l'intoxication.**",
        },
        {
            "id": "1.0.7",
            "title": "Comas non traumatiques",
            "description": "Quelle est votre orientation clinique?",
            "leadsTo": ["1.0.7.0", "1.0.7.1", "1.0.7.2", "1.0.7.3", "1.0.7.4", "1.0.7.5"]
        },
        {
            "id": "1.0.7.0",
            "title": "Absence d'orientation clinique évidente",
            "description": "*v#gbu#neurol*IRM encéphalique en urgence.<br/>\
            Si IRM non disponible, scanner encéphalique sans injection pouvant être complété par une injection au besoin.**",
            "pictures": [
                { "img": "1070.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique sans injection montrant un aspect spontanément dense du tronc basilaire (flèche rouge), faisant suspecter une thrombose récente de celui-ci. A titre de comparaison, l'artère carotide interne gauche est marquée d'une flèche verte. Voir aussi image 2." },
                { "img": "1070.0.jpg", "caption": "Coupe sagittale oblique d'un angioscanner du polygone de Willis, déroulant le tronc basilaire, qui apparaît non opacifié, en faveur d'une thrombose basilaire (flèches rouges). Voir aussi image 1." }
            ]
        },
        {
            "id": "1.0.7.1",
            "title": "Syndrome méningé non fébrile",
            "description": "Suspicion d'hémorragie sous-arachnoïdienne.<br/>\ Redirection vers syndrome méningé non fébrile",
            "leadsTo": ["1.0.3.1"]
        },
        {
            "id": "1.0.7.2",
            "title": "Syndrome méningé fébrile",
            "description": "Suspicion de méningite.<br/>\
            Redirection vers syndrome méningé fébrile",
            "leadsTo": ["1.0.3.0"]
        },
        {
            "id": "1.0.7.3",
            "title": "Signe de localisation non fébrile",
            "description": "Suspicion d'AVC.<br/>\
            Redirection vers trouble neurologique récent non fébrile",
            "leadsTo": ["1.0.2.1"]
        },
        {
            "id": "1.0.7.4",
            "title": "Signe de localisation fébrile",
            "description": "Suspicion d'encéphalite ou processus infectieux intra-crânien.<br/>\
            Redirection vers trouble neurologique récent fébrile",
            "leadsTo": ["1.0.2.0"]
        },
        {
            "id": "1.0.7.5",
            "title": "Suspicion d'intoxication",
            "description": "*o#gbu#neurol#rea*Indication à l'imagerie en cas de coma durable après traitement de l'intoxication.<br/>\
            Préférer une IRM encéphalique dans ce cas.<br/>\
            Scanner si IRM non disponible**",
        },
        {
            "id": "1.0.8",
            "title": "Trouble psychiatrique inaugural",
            "description": "",
            "leadsTo": ["1.0.8.0", "1.0.8.1"]
        },
        {
            "id": "1.0.8.0",
            "title": "Examen neurologique normal",
            "description": "*o#gbu#neurol*IRM encéphalique sans urgence (ou scanner encéphalique à défaut si IRM non disponible).**",
        },
        {
            "id": "1.0.8.1",
            "title": "Examen neurologie anormal",
            "description": "",
            "leadsTo": ["1.0.8.1.0", "1.0.8.1.1"]
        },
        {
            "id": "1.0.8.1.0",
            "title": "Signe de localisation non fébrile",
            "description": "Suspicion d'AVC.<br/>\
            Redirection vers trouble neurologique récent non fébrile",
            "leadsTo": ["1.0.2.1"]
        },
        {
            "id": "1.0.8.1.1",
            "title": "Signe de localisation fébrile",
            "description": "Suspicion d'encéphalite ou processus infection intra-crânien.<br/>\
            Redirection vers trouble neurologique récent fébrile",
            "leadsTo": ["1.0.2.0"]
        },
        //start vertiges
        {
            "id": "1.0.9",
            "title": "Vertiges d'apparition brutale ou rapide",
            "description": "La symptomatologie fait-elle évoquer une cause centrale ou périphérique ?",
            "leadsTo": ["1.0.9.0", "1.0.9.1"]
        },
        {
            "id": "1.0.9.0",
            "title": "Vertige périphérique",
            "description": "Redirection vers anomalie des paires crâniennes",
            "leadsTo": ["1.1.1"]
        },
        {
            "id": "1.0.9.1",
            "title": "Vertige central",
            "description": "Suspicion d'AVC.<br/>\
            Redirection vers Trouble neurologique récent non fébrile",
            "leadsTo": ["1.0.2.1"]
        },
        //start trauma facial
        {
            "id": "1.1.0",
            "title": "Traumatisme facial",
            "description": "",
            "leadsTo": ["1.1.0.0", "1.1.0.1"]
        },
        {
            "id": "1.1.0.0",
            "title": "Sans traumatisme crânien associé",
            "description": "",
            "leadsTo": ["1.1.0.2", "1.1.0.3", "1.1.0.4"]
        },
        {
            "id": "1.1.0.1",
            "title": "Traumatisme crânien associé",
            "description": "*v#gbu#orl#neuroch*Scanner facial en urgence.**<br/>\
            *o*Un scanner encéphalique peut y être associé. (voir ci-dessous)**",
            "leadsTo": ["1.0.0"]
        },
        {
            "id": "1.1.0.2",
            "title": "Suspicion de fracture de l'os nasal",
            "description": "",
            "leadsTo": ["1.1.0.2.1", "1.1.0.2.2"]
        },
        {
            "id": "1.1.0.3",
            "title": "Traumatisme orbitaire",
            "description": "Avis spécialisé.<br/>\
            *v#gbu#orl#opht*Scanner du massif facial sans injection en urgence.**<br/>\
            *o#gbu#orl#opht*IRM du massif facial (en seconde intention) et selon avis spécialisé.**<br/>\
            *r#gbu#orl#opht*Radiographies des orbites non indiquées**",
            "pictures": [
                { "img": "1103.jpg", "caption": "Scanner du massif facial sans injection présenté en fenêtre osseuse et dans le plan coronal, montrant une fracture de type blow-out du plancher de l'orbite gauche (flèche rouge). Malgré la ptose du corps musculaire du muscle droit inférieur au sein du site fracturaire, il n'y a pas de risque d'incarcération des structures musculo-tendineuses oculaires." },
                { "img": "1103.0.jpg", "caption": "Scanner du massif facial sans injection présenté en fenêtre osseuse et dans le plan coronal, montrant une fracture en trappe du plancher de l'orbite droit (flèche rouge), avec incarcération du tendon du muscle droit inférieur (étoile verte). La tête de flèche bleue désigne de l'air dans la cavité orbitaire (pneumorbitie)." },
                { "img": "1103.1.jpg", "caption": "Scanner cranio-facial sans injection présenté en fenêtre osseuse et dans le plan coronal, montrant une fracture en trappe du plancher de l'orbite droit (flèche rouge), avec incarcération du tendon du muscle droit inférieur (étoile verte). La tête de flèche bleue désigne un hémosinus associé." },
            ]
        },
        {
            "id": "1.1.0.4",
            "title": "Traumatisme facial grave",
            "description": "*v#gbu#orl#neuroch*Scanner du massif facial sans injection en urgence**.<br/>\
        	*r#gbu#orl#neuroch*Radiographies du massif facial non indiquées.**",
        	 "pictures": [
                { "img": "1104.jpg", "caption": "Fracture faciale grave (flèches rouges) avec disjonction de l'os maxillaire sans atteinte zygomatique (classée II selon LE FORT)." },
                { "img": "1104.0.jpg", "caption": "Scanner du massif facial présenté en coupes épaisses. Luxation incudo-malléaire droite (flèche rouge) post-traumatique avec remplissage hématique des cellules mastoïdiennes homolatérales. A gauche, absence de luxation incudo-malléaire et aération normale des cellules mastoïdiennes." }
            ]
        },
        {
            "id": "1.1.0.2.1",
            "title": "Suspicion de fracture isolée de l'os nasal",
            "description": "*o#gbu#orl*Radiographies de l'os nasal en urgence (face et profil) à discuter en fonction du contexte. Pas de nécessité hors contexte médico-légal.**",
            "pictures": [
                { "img": "11021.jpg", "caption": "Fracture de l'os nasal (flèche rouge) sur une incidence radiographique de profil centrée sur l'os nasal." }
            ]
        },
        {
            "id": "1.1.0.2.2",
            "title": "Suspicion de fracture dépassée de l'os nasal",
            "description": "*v#gbu#orl*Scanner facial sans injection en urgence.<br/>\
            Si non disponible, radiographies faciales.**",
            "pictures": [
                { "img": "11022.jpg", "caption": "Coupe axiale d'un scanner du massif facial présenté en fenêtre osseuse, montrant une fracture de l'os nasal (flèche verte) dépassée, avec fracture de la cloison nasale (flèche rouge). A noter, des sédiments hémorragiques des cellules mastoïdiennes (étoile bleue)." },
                { "img": "11022.0.jpg", "caption": "Coupe axiale d'un scanner sans injection du massif facial, présenté en fenêtre parties molles, montrant une infiltration hématique péri-orbitaire bilatérale marquée (flèches rouges)." }
            ]
        },
        //start paires craniennes
        {
            "id": "1.1.1",
            "title": "Anomalie clinique des paires crâniennes",
            "description": "*v#gbu#neurol#neuroch*IRM encéphalique en urgence.<br/>\
            Si non disponible : scanner encéphalique non injecté (complété par une injection si anomalie décelée).**",
            "pictures": [
                { "img": "111.jpg", "caption": "Coupe axiale d'une IRM en séquence T2 millimétrique sur la fosse postérieure, montrant un syndrome de masse développé aux dépens du paquet acoustico-facial gauche (flèche rouge). Très probable neurinome." }
            ]
        },
        //start exophtalmie
        {
            "id": "1.1.2",
            "title": "Exophtalmie",
            "description": "Avis spécialisé.<br/>\
            *o#gbu#neurol#neuroch#opht*IRM encéphalique en semi-urgence (sauf exophtalmie pulsatile, nécessitant une imagerie en urgence (IRM ou à défaut scanner encéphalique injecté)).<br/>\
            Si IRM non disponible : Scanner encéphalique sans injection (éventuellement complété par une injection au besoin).**<br/>\
            Echographie des globes oculaires par l'ophtalmologiste",
            "pictures": [
                { "img": "112.jpg", "caption": "Coupe axiale d'une IRM en pondération T1 après injection de sels de Gadolinium montrant un syndrome de masse rétro-orbitaire développé dans l'orbite gauche (étoile rouge), responsable d'une exophtalmie (trait vert)." },
                 { "img": "112.0.jpg", "caption": "Coupe coronale d'un scanner sans injection centré sur les cavités orbitaires, montrant une hypertrophie massive des muscles oculomoteurs (étoiles rouges). Seuls les muscles droits latéraux (flèche verte) sont de calibre normal. Le tout s'intègre dans une orbithopathie associée à une maladie de Basedow déjà connue." },
            ]
        },
        //start oculomotricite
        {
            "id": "1.1.3",
            "title": "Trouble de l'oculomotricité",
            "description": "*v#gbu#neurol#neuroch*IRM encéphalique en urgence en première intention.<br/>\
            Si IRM non disponible, scanner encéphalique en urgence (injection à discuter en fonction du contexte clinique).**",
            "pictures": [
                { "img": "112.jpg", "caption": "Coupe axiale d'une IRM en pondération T1 après injection de sels de Gadolinium montrant un syndrome de masse rétro-orbitaire développé dans l'orbite gauche (étoile rouge), responsable d'une exophtalmie (trait vert)." }
            ]
        },
        //start vision
        {
            "id": "1.1.4",
            "title": "Trouble de la vision de cause non ophtalmologique",
            "description": "*v#gbu#neurol*IRM encéphalique avec acquisitions spécifiques sur les orbites en urgence.<br/>\
            Si non disponible, scanner encéphalique injecté en urgence.**",
            "pictures": [
                { "img": "112.jpg", "caption": "Coupe axiale d'une IRM en pondération T1 après injection de sels de Gadolinium montrant un syndrome de masse rétro-orbitaire développé dans l'orbite gauche (étoile rouge), responsable d'une exophtalmie (trait vert)." }
            ]
        },
        {
            "id": "1.1.5",
            "title": "Douleurs dentaires",
            "description": "*r#gbu*Avis spécialisé avant toute réalisation d'un orthopantomogramme ou d'un cone-beam CT.**",
        },
        //start souffle cervical
        {
            "id": "1.2.0",
            "title": "Souffle cervical suspecté vasculaire",
            "description": "",
            "leadsTo": ["1.2.0.0", "1.2.0.1"]
        },
        {
            "id": "1.2.0.0",
            "title": "Asymptomatique",
            "description": "*o#gbu#vascu*Echographie des troncs supra-aortiques sans urgence.<br/>\
            Eventuellement complétée par angio-RM ou angioscanner des troncs supra-aortiques avant prise en charge chirurgicale (en fonction du délai souhaité de la chirurgie).**",
        },
        {
            "id": "1.2.0.1",
            "title": "Symptomatique",
            "description": "Suspicion d'AVC.<br/>\
            Redirection vers trouble neurologique récent non fébrile",
            "leadsTo": ["1.0.2.1"]
        },
        //start racine nerveuse
        {
            "id": "1.2.1",
            "title": "Névralgie cervico-brachiale",
            "description": "Est-elle déficitaire? (déficit classé inférieur à 3 selon l'échelle MRC)<br/>\
            Est-elle hyperalgique? (douleur rebelle aux traitements antalgiques (opiacés) bien conduits) ",
            "leadsTo": ["50.0.1", "50.0.2"]
        },
        {
            "id": "1.2.2",
            "title": "Lombosciatique / Cruralgie",
            "description": "Est-elle déficitaire? (déficit classé inférieur à 3 selon l'échelle MRC)<br/>\
            Est-elle hyperalgique? (douleur rebelle aux traitements antalgiques (opiacés) bien conduits) ",
            "leadsTo": ["50.2.1", "50.2.2"]
        },
        //Start syndrome medullaire
        {
            "id": "1.2.3",
            "title": "Syndrome médullaire aigu non traumatique / Queue de cheval non traumatique",
            "description": "Demander un avis neurochirurgical en urgence.<br/>\
            *v#gbu#neurol#neuroch#ortho*IRM médullaire en urgence.<br/>\
            Si IRM non disponible, faire un scanner du rachis centré sur la zone suspecte de compression en urgence, possiblement guidé par des radiographies du rachis entier.**",
            "pictures": [
                { "img": "5023.jpg", "caption": "IRM en pondération T1 et dans le plan sagittal, montrant une volumineuse hernie discale exclue en L5-S1 (flèche rouge). Elle refoule en arrière et comprime les racines de la queue de cheval. Voir aussi image 2." },
                { "img": "5023.0.jpg", "caption": "IRM en pondération T2 et dans le plan axial, montrant une volumineuse hernie discale exclue (flèche rouge). Elle refoule en arrière et comprime les racines de la queue de cheval. Globe urinaire (étoile verte). Voir aussi image 1." }
            ]
        },
        //START ORL ( OK correction crane )
        {
            "id": "2.0",
            "title": "Atteinte infectieuse de la sphère ORL",
            "description": "Quel est le site touché?",
            "leadsTo": ["2.0.0", "2.0.1", "2.0.2"]
        },
        {
            "id": "2.1",
            "title": "Paralysie faciale périphérique",
            "description": "La paralysie faciale remplit-elle au moins l'un des critères énoncés?<br/>\
            [[PF progressive,]\
            [PF récidivante,]\
            [PF associée à un vertige, une surdité, une autre atteinte nerveuse périphérique]]",
            "leadsTo": ["2.1.0", "2.1.1"]
        },
        {
            "id": "2.2",
            "title": "Epistaxis",
            "description": "Avis spécialisé ORL en urgence.<br/>\
            *r#gbu#orl*Pas d'examen d'imagerie indiqué.**<br/>\
            *o#gbu#orl*Selon un avis spécialisé (radiologie interventionnelle en vue d'une embolisation par exemple), un angioscanner peut être réalisé avant le geste endovasculaire.**",
        },
        {
            "id": "2.3",
            "title": "Dyspnée laryngée / Corps étrangers de la sphère ORL",
            "description": "",
            "leadsTo": ["2.3.0", "2.3.1", "2.3.2"]
        },
        {
            "id": "2.4",
            "title": "Vertige périphérique et/ou surdité neuro-sensorielle",
            "description": "*v#gbu#neurol#orl*IRM en urgence.<br/>\
            Si non disponible, scanner encéphalique injecté indiqué en urgence.**",
            "leadsTo": ["1.0.2"]
        },
        {
            "id": "2.5",
            "title": "Acouphènes",
            "description": "Quelles sont les caractéristiques des acouphènes (après avis spécialisé)?",
            "leadsTo": ["2.5.0", "2.5.1", "2.5.2", "2.5.3"]
        },
        {
            "id": "2.6",
            "title": "Otorrhée",
            "description": "*o#gbu*Scanner des rochers sans injection en semi-urgence.<br/>\
            En cas d'immunodépression ou de suspicion de brèche ostéo-durale ; IRM de la base du crâne en complément du scanner.**",
            "pictures": [
                { "img": "2001.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique présenté en fenêtre osseuse montrant un remplissage liquidien de l'oreille moyenne droite (flèche rouge) et des cellules mastoïdiennes homolatérales (flèche verte). Le côté controlatéral est normal, en dehors d'un bouchon de cérumen dans le conduit auditif externe (tête de flèche bleue)." }
            ]
        },

        //start atteinte infectieuse de la sphère ORL
        {
            "id": "2.0.0",
            "title": "Otite",
            "description": "",
            "leadsTo": ["2.0.0.0", "2.0.0.1", "2.0.0.2"]
        },
        {
            "id": "2.0.1",
            "title": "Angine",
            "description": "",
            "leadsTo": ["2.0.1.0", "2.0.1.1"]
        },
        {
            "id": "2.0.2",
            "title": "Sinusite aigue",
            "description": "Quelle est la localisation de la sinusite?",
            "leadsTo": ["2.0.2.0", "2.0.2.1"]
        },
        {
            "id": "2.0.0.0",
            "title": "Otite simple",
            "description": "*r#gbu#orl*Pas d'examen d'imagerie indiqué**"
        },
        {
            "id": "2.0.0.1",
            "title": "Otite compliquée",
            "description": "Avis spécialisé (ORL).<br/>\
            *v#gbu#orl*Scanner cranio-encéphalique injecté en urgence après avis spécialisé.**",
            "pictures": [
                { "img": "2001.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique présenté en fenêtre osseuse montrant un remplissage liquidien de l'oreille moyenne droite (flèche rouge) entourant les osselets (apparaissant comme un petit cornet de glace portant une boule) et des cellules mastoïdiennes homolatérales (tête de flèche verte)." },
                { "img": "2001.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique présenté en fenêtre osseuse montrant un remplissage liquidien de l'oreille moyenne droite (flèche rouge) et des cellules mastoïdiennes homolatérales (flèche verte). Le côté controlatéral est normal, en dehors d'un bouchon de cérumen dans le conduit auditif externe (tête de flèche bleue)." }
            ]
        },
        {
            "id": "2.0.0.2",
            "title": "Cholestéatome",
            "description": "Avis spécialisé ORL.<br/>\
            *r#gbu#orl*Pas d'imagerie indiquée en urgence sauf si déficit neurologique associé**<br/>\
            Redirection vers Déficit neurologique récent",
            "leadsTo": ["1.0.2"],
            "pictures": [
                { "img": "2002.jpg", "caption": "Coupe coronale d'un scanner du rocher droit présenté en fenêtre osseuse, montrant un cholestéatome (étoile rouge) avec aspect mité du tegmen tympani (flèche rouge)." }
            ]
        },
        {
            "id": "2.0.1.0",
            "title": "Angine simple",
            "description": "*r#gbu#orl*Pas d'examen d'imagerie indiqué**",
            "pictures": [
                { "img": "2010.jpg", "caption": "Coupe coronale d'un scanner cervical réalisé à 2 minutes de l'injection de produit de contraste iodé, montrant une amygdalite bilatérale (flèches rouges) réduisant le calibre des voies aéro-digestives supérieures (étoile verte). Voir aussi image 2." },
                 { "img": "2011.0.jpg", "caption": "Coupe axiale d'un scanner cervical réalisé à 2 minutes de l'injection de produit de contraste iodé, montrant une amygdalite bilatérale (flèches rouges) réduisant le calibre des voies aéro-digestives supérieures (étoile verte). Voir aussi image 1." }
            ]
        },
        {
            "id": "2.0.1.1",
            "title": "Angine compliquée",
            "description": "Avis spécialisé.<br/>\
            *v#gbu#orl*Scanner cervical injecté indiqué en urgence après avis spécialisé.**",
            "pictures": [
                { "img": "2011.jpg", "caption": "Coupe sagittale paramédiane d'un scanner cervical réalisé à 2 minutes de l'injection de produit de contraste iodé, montrant un abcès de l'espace rétropharyngé (flèches rouges) et un important état inflammatoire cervical (étoiles vertes)." },
                 { "img": "2011.0.jpg", "caption": "Coupe axiale d'un scanner cervical réalisé à 2 minutes de l'injection de produit de contraste iodé, montrant une amygdalite bilatérale (flèches rouges) réduisant le calibre des voies aéro-digestives supérieures (étoile verte)." }
            ]
        },
        {
            "id": "2.0.2.0",
            "title": "Sinusite aigue maxillaire",
            "description": "*r#gbu#orl*Pas d'examen d'imagerie indiqué.**<br/>\
            Le scanner facial injecté se discute en cas de sinusite aigue maxillaire résistante après trois jours d'antibiothérapie bien conduite.",
            "pictures": [
                { "img": "2020.jpg", "caption": "Coupe axiale d'un scanner facial sans injection présenté en fenêtre osseuse (réalisé devant une symptomatologie de sinusite aigue maxillaire droite récidivante), montrant la migration d'un amalgame dentaire (tête de flèche verte) dans la cavité sinusienne. Aspect évocateur de sinusite chronique maxillaire droite devant l'hyperostose des parois maxillaires (flèches rouges)." }
            ]
        },
        {
            "id": "2.0.2.1",
            "title": "Sinusite aigue maxillaire compliquée ou sinusite aigue frontale, sphénoïdale, ethmoïdale.",
            "description": "*v#gbu#orl*Scanner du massif facial (pouvant être associé à un scanner encéphalique) en urgence**<br/>\
            *o#gbu#orl*L'IRM encéphalique se discute en seconde intention en cas de complication.**<br/>\
            *r#gbu#orl*Les radiographies du massif facial ne sont pas indiquées.**",
            "pictures": [
                { "img": "2021.jpg", "caption": "Coupe axiale d'un scanner facial réalisé après injection de produit de contraste iodé, montrant une prolifération tissulaire délabrante au sein du sinus maxillaire droit (flèche rouge). L'origine fongique de la sinusite compliquée est très probable." },
                { "img": "2021.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique présenté en fenêtre osseuse, montrant une sphénoïdite (remplissage liquidien des cavités de l'os sphénoïde marqué d'une étoile rouge) compliquée d'une brèche méningé (flèche verte), expliquant la méningite bactérienne qui a amené le patient a consulter." }
            ]
        },
        //start dyspnée et corps étrangers
        {
            "id": "2.3.0",
            "title": "Dyspnée laryngée aigue à priori sans corps étranger",
            "description": "Avis spécialisé ORL en urgence.<br/>\
            *o#gbu#orl*Scanner cervical si nécessaire en urgence et en dernier recours.**"
        },
        {
            "id": "2.3.1",
            "title": "Dyspnée laryngée aigue avec syndrome de pénétration (corps étranger)",
            "description": "Avis spécialisé ORL en urgence.<br/>\
            *o#gbu#orl#pneumo*Radiographies cervicales et/ou scanner cervico-thoracique en dernier recours si l'état clinique le permet.**",
            "pictures": [
                {"img": "232.jpg", "caption": "Radiographie du thorax et de l'abdomen de face chez une enfant de 3 ans, après ingestion d'une pièce de monnaie (flèche rouge)."}
            ]
        },
        {
            "id": "2.3.2",
            "title": "Corps étranger de la sphère ORL hors urgence vitale",
            "description": "*r#gbu#orl*Avis spécialisé ORL avant toute imagerie en urgence.**",
            "pictures": [
                {"img": "232.44.jpg", "caption": "Radiographie de la région cervicale de profil chez un enfant, montrant une pièce de monnaie (flèche rouge) qui se projette à la jonction hypopharynx-oesophage cervical, laissant libres les voies aériennes (têtes de flèches vertes)."},
                {"img": "232.0.jpg", "caption": "Coupe axiale d'un scanner thoracique réalisé au temps portal de l'injection de produit de contraste iodé, montrant une perforation oesophagienne (flèches rouges), et un épanchement pleural droit avec atélectasie secondaire (étoile verte)."}
            ]
        },
        //start Paralysie faciale périphérique
        {
            "id": "2.1.0",
            "title": "Paralysie faciale périphérique ne remplissant aucun des critères cités",
            "description": "Paralysie faciale unilatérale et isolée.<br/>\
            *r#gbu#neurol#orl*Pas d'examen d'imagerie indiqué.**"
        },
        {
            "id": "2.1.1",
            "title": "Paralysie faciale remplissant au moins un des critères cités.",
            "description": "*v#gbu#neurol#orl*IRM encéphalique indiqué en urgence.<br/>\
            A défaut, un scanner encéphalique injecté est à réaliser en urgence.**",
            "pictures": [
                { "img": "111.jpg", "caption": "Coupe axiale d'une IRM en séquence T2 millimétrique sur la fosse postérieure, montrant un syndrome de masse développé aux dépens du paquet acoustico-facial gauche (flèche rouge). Très probable neurinome." }
            ] 
        },
        //start acouphènes
        {
            "id": "2.5.0",
            "title": "Non pulsatiles",
            "description": "*r#gbu*IRM de l'oreille interne et du méat acoustique interne sans urgence.**",
            "pictures": [
                { "img": "111.jpg", "caption": "Coupe axiale d'une IRM en séquence T2 millimétrique sur la fosse postérieure, montrant un syndrome de masse développé au dépens du paquet acoustico-facial gauche (flèche rouge). Très probable neurinome." }
            ] 
        },
        {
            "id": "2.5.1",
            "title": "Pulsatiles",
            "description": "*o#gbu*Echo-doppler des troncs supra-aortiques.<br/>\
            IRM de l'oreille interne et du méat acoustique interne.<br/>\
            Le délai de réalisation de ces examens est à confronter à la clinique.**",
            "pictures": [
                { "img": "251.jpg", "caption": "Coupe coronale d'un scanner réalisé au temps artériel de l'injection de produit de contraste (dans le contexte de l'urgence) chez une patiente prrésentant des acouphènes pulsatiles. Dilatation variqueuse d'une veine méningée opacifiée précocément (flèches rouges), en faveur d'une fistule durale. Saignement intra-crânien matérialisé par les étoiles roses. Voir aussi image 2." },
                { "img": "251.0.jpg", "caption": "Même contexte et modalité d'imagerie que l'image 1 (ici coupe axiale). Opacification précoce de la veine jugulaire interne droite (flèche rose) comparativement à la veine jugulaire interne gauche (flèche verte), plaidant pour une fistule durale. Voir aussi image 1." }
            ]
        },
        {
            "id": "2.5.2",
            "title": "Otoscopie pathologique.",
            "description": "*o#gbu*IRM des rochers<br/>\
            A défaut, un scanner des rochers peut également être réalisé.<br/>\
            Le délai de réalisation de cet/ces examen(s) est à confronter à la clinique.**",
            "pictures": [
                { "img": "2001.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique présenté en fenêtre osseuse montrant un remplissage liquidien de l'oreille moyenne droite (flèche rouge) entourant les osselets (apparaissant comme un petit cornet de glace portant une boule) et des cellules mastoïdiennes homolatérales (tête de flèche verte)." },
                { "img": "2001.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique présenté en fenêtre osseuse montrant un remplissage liquidien de l'oreille moyenne droite (flèche rouge) et des cellules mastoïdiennes homolatérales (flèche verte). Le côté controlatéral est normal, en dehors d'un bouchon de cérumen dans le conduit auditif externe (tête de flèche bleue)." }
            ]
        },
        {
            "id": "2.5.3",
            "title": "Otoscopie normale.",
            "description": "",
            "leadsTo": ["2.5.3.0", "2.5.3.1"]
        },
        {
            "id": "2.5.3.0",
            "title": "Surdité de transmission.",
            "description": "*r#gbu*Scanner des rochers sans urgence.**",
            "pictures": [
                { "img": "2001.0.jpg", "caption": "Coupe axiale d'un scanner cranio-encéphalique présenté en fenêtre osseuse montrant un remplissage liquidien de l'oreille moyenne droite (flèche rouge) et des cellules mastoïdiennes homolatérales (flèche verte). Le côté controlatéral est normal, en dehors d'un bouchon de cérumen dans le conduit auditif externe (tête de flèche bleue)." }
            ]
        },
        {
            "id": "2.5.3.1",
            "title": "Surdité de perception.",
            "description": "*r#gbu*IRM de l'oreille interne et du méat auditif interne sans urgence.**",
            "pictures": [
                { "img": "111.jpg", "caption": "Coupe axiale d'une IRM en séquence T2 millimétrique sur la fosse postérieure, montrant un syndrome de masse développé au dépens du paquet acoustico-facial gauche (flèche rouge). Très probable neurinome." },
                { "img": "2531.jpg", "caption": "Scanner du rocher gauche sans injection, montrant un foyer d'otospongiose (flèche rose) au contact de la base de la cochlée (flèche verte)." }
            ]
        },
        // START THORAX
        {
            "id": "3.0",
            "title": "Coeur / Aorte",
            "description": "Quelle pathologie suspectez-vous parmi les suivantes?",
            "leadsTo": ["3.0.0", "3.0.1", "3.0.2", "3.0.3", "3.0.4"]
        },
        {
            "id": "3.1",
            "title": "Poumons / Plèvre",
            "description": "Quelle pathologie suspectez-vous parmi les suivantes?",
            "leadsTo": ["3.1.0", "3.1.1", "3.1.2"]
        },
        {
            "id": "3.2",
            "title": "Artères pulmonaires / Artères bronchiques",
            "description": "Quelle pathologie suspectez-vous?",
            "leadsTo": ["3.2.0", "3.2.1"]
        },
        {
            "id": "3.3",
            "title": "Voies aériennes",
            "description": "Quelle pathologie suspectez-vous parmi les suivantes?",
            "leadsTo": ["3.3.0", "3.3.1", "3.3.2", "3.3.3", "3.1.0"]
        },
        {
            "id": "3.4",
            "title": "Sénologie",
            "description": "Quelle pathologie suspectez-vous parmi les suivantes?",
            "leadsTo": ["3.4.0", "3.4.1", "3.4.2", "3.4.3"]
        },
        // start coeur aorte 3.0
        {
            "id": "3.0.0",
            "title": "Infarctus aigu du myocarde",
            "description": "*r#gbu#cardio*Pas d'examen d'imagerie diagnostique en urgence.**<br/>\
            Avis cardiologique en urgence avant coronarographie.",
            "pictures": [
                { "img": "300.jpg", "caption": "Coupe pseudo-quatre-cavité d'un scanner thoracique réalisé après injection de produit de contraste, montrant un thrombus de la pointe du ventricule gauche (flèche rouge), secondaire à un infarctus du myocarde ancien avec aspect aminci du myocarde (tête de flèche jaune) comparativement au myocarde normal (marqué d'une flèche verte)." },
                { "img": "300.0.jpg", "caption": "Coupe pseudo-deux-cavité d'un scanner thoracique réalisé après injection de produit de contraste, montrant un thrombus de la pointe du ventricule gauche (flèche rouge), secondaire à un infarctus du myocarde ancien avec aspect aminci du myocarde (tête de flèche jaune)." }
            ]
        },
        {
            "id": "3.0.1",
            "title": "Insuffisance cardiaque aigue",
            "description": "*v#gbu#cardio*Echographie cardiaque en urgence.<br/>\
            Radiographie pulmonaire en urgence (bilan étiologique de la décompensation cardiaque).**<br/>\
            *r*Attention : l'injection de produit de contraste Iodé est formellement contre-indiquée en cas d'insuffisance cardiaque aigue, au risque de dégrader l'état clinique du patient.**",
            "pictures": [
                { "img": "301.jpg", "caption": "Radiographie pulmonaire basale d'une personne âgée, connue pour des épisodes de décompensation cardiaque sur fibrillation atriale mal tolérée. Voir l'image 2." },
                { "img": "301.0.jpg", "caption": "Radiographie pulmonaire de la même personne lors d'un épisode de décompensation cardiaque, montrant des opacités diffuses et hilifuges (étoiles rouges), typiques d'oedème aigu pulmonaire. Voir l'image 1." }
            ]
        },
        {
            "id": "3.0.2",
            "title": "Myocardite",
            "description": "*v#gbu#cardio*Radiographie thoracique en urgence.<br/>\
            Echographie trans-thoracique en urgence.**<br/>\
            *o#gbu#cardio*IRM cardiaque indiquée sous quelques jours (après avis spécialisé).**",
            "pictures": [
                { "img": "302.jpg", "caption": "Coupe selon un plan 4 cavités d'une IRM cardiaque où le myocarde normal du VG (étoile rouge) apparaît en hyposignal (noir) alors que les zones pathologiques apparaissent en hypersignal (blanc - flèches roses)." }
            ]
        },
        {
            "id": "3.0.3",
            "title": "Péricardite / Tamponnade",
            "description": "*v#gbu#cardio*Echographie cardiaque en urgence ou en extrême urgence, en fonction de l'état clinique.**<br/>\
            L'échographie diagnostique ne doit pas retarder un éventuel drainage péricardique de sauvetage.",
            "pictures": [
                { "img": "303.jpg", "caption": "Coupe axiale d'un scanner thoracique réalisé au temps portal, montrant un épanchement péricardique circonférentiel (flèches vertes) avec discret réhaussement des feuillets péricardiques. L'étoile rouge montre une masse pulmonaire de découverte fortuite, plaidant pour une néoplasie pulmonaire primitive." }
            ]
        },
        {
            "id": "3.0.4",
            "title": "Dissection aortique",
            "description": "Avis en extrême urgence en chirurgie vasculaire.<br/>\
            *v#gbu#cardio#vascu*Echographie trans-thoracique en extrême urgence en milieu spécialisé.<br/>\
            Angioscanner aortique en extrême urgence.**",
            "pictures": [
                { "img": "304.jpg", "caption": "Coupe axiale d'un angioscanner aortique montrant une image de flap intimal (flèche rouge) de la crosse aortique. Le faux chenal comprime le vrai chenal. Voir aussi image 2." },
                { "img": "304.1.jpg", "caption": "Coupe coronale du même examen (image 1), montrant le flap intimal de l'aorte ascendante (flèche rouge) s'étendant au tronc artériel brachio-céphalique (flèche rose). Voir aussi image 1." }
            ]
        },
        //start poumon plèvre 3.1
        {
            "id": "3.1.0",
            "title": "Bronchite aigue",
            "description": "*r#gbu#pneumo*Aucune imagerie indiquée en l'absence de signe de gravité (même si fièvre modérée ou toux).**<br/>\
            *o#gbu#pneumo*Une radiographie pulmonaire en urgence peut être réalisée en cas de doute avec une pneumopathie débutante.**",
            "pictures": [
                {"img": "310.jpg", "caption": "Radiographie pulmonaire debout de face, montrant un foyer organisé effaçant le bord droit du coeur (signe de la silhouette) évoquant un foyer organisé lobaire moyen. La radiographie a été réalisée dans un contexte de bronchite aigue s'aggravant (reconsultation)."}
            ]
        },
        {
            "id": "3.1.1",
            "title": "Pneumopathie",
            "description": "",
            "leadsTo": ["3.1.1.0", "3.1.1.1"]
        },
        {
            "id": "3.1.2",
            "title": "Epanchement pleural",
            "description": "Nature de l'épanchement",
            "leadsTo": ["3.1.2.0", "3.1.2.1"]
        },
        {
            "id": "3.1.1.0",
            "title": "Patient immunocompétent",
            "description": "*v#gbu#pneumo*Radiographie thoracique en urgence.**<br/>\
            *o#gbu#pneumo*Scanner thoracique sans injection en complément en cas de forme grave, de doute diagnostique persistant après radiographie ou de suspicion de pneumopathie compliquée.**",
            "pictures": [
                { "img": "3110.jpg", "caption": "Radiographie pulmonaire debout de face, montrant un foyer organisé en base droite (étoile rouge). L'absence de signe de la silhouette (c'est-à-dire la visibilité du bord droit du médiastin (flèche rose)) est en faveur d'un foyer du lobe inférieur droit. Si le bord droit du médiastin était effacé par le foyer, cela serait en faveur d'un foyer du lobe moyen." }
            ]
        },
        {
            "id": "3.1.1.1",
            "title": "Patient immunodéprimé",
            "description": "*v#gbu#pneumo*Radiographie thoracique en urgence (débrouillage).<br/>\
            Scanner du thorax sans injection indiqué en urgence.**",
            "pictures": [
                { "img": "3111.jpg", "caption": "Coupe axiale d'un scanner thoracique présentée en fenêtre parenchymateuse, montrant une lésion nodulaire apicale droite (flèche rouge) entourée d'un halo en verre dépoli, évoquant une aspergillose angio-invasive. Voir aussi image 2." },
                { "img": "3111.0.jpg", "caption": "Coupe axiale d'un scanner thoracique présentée en fenêtre parenchymateuse, montrant une lésion nodulaire cerclée de verre dépoli (flèche rouge) et une lésion nodulaire excavée (flèche verte), évoquant une aspergillose angio-invasive. Voir aussi image 1." }
            ]
        },
        {
            "id": "3.1.2.0",
            "title": "Epanchement pleural liquidien",
            "description": "*v#gbu#pneumo*Radiographie thoracique de face en urgence.<br/>\
            Radiographie thoracique de profil en urgence en complément si besoin.**<br/>\
            *o#gbu#pneumo*Scanner thoracique indiqué en semi-urgence seulement dans des cas particuliers (épanchement pleural cloisonné avant drainage par exemple).**",
            "pictures": [
                { "img": "3120.jpg", "caption": "Radiographie pulmonaire en position debout de face, montrant un épanchement pleural liquidien de moyenne abondance à droite (étoile rouge) avec une limite supérieure (marquée par les flèches rouge) correspondant cliniquement à la ligne de Damoiseau." }
            ]
        },
        {
            "id": "3.1.2.1",
            "title": "Pneumothorax",
            "description": "*v#gbu#pneumo*Radiographie thoracique de face en urgence.**<br/>\
            *r#gbu#pneumo*Ne pas réaliser de radiographie thoracique en expiration!**<br/>\
            *o#gbu#pneumo*Scanner du thorax sans injection indiqué dans des cas particuliers, hors urgence.**",
            "pictures": [
                { "img": "3121.jpg", "caption": "Radiographie pulmonaire debout de face montrant un pneumothorax complet gauche (flèches roses). Pas de déviation médiastinale controlatérale." },
                { "img": "3121.1.jpg", "caption": "Coupe axiale d'un scanner thoracique sans injection en fenêtre parenchymateuse montrant un pneumothorax gauche (étoiles roses) et une rétraction pulmonaire réactionnelle." }
            ]
        },
        //start art pulm et art bronchiques
        {
            "id": "3.2.0",
            "title": "Embolie pulmonaire",
            "description": "Quelle est la probabilité clinique d'embolie pulmonaire ?",
            "leadsTo": ["3.2.0.0"]
        },
        {
            "id": "3.2.1",
            "title": "Hémoptysie",
            "description": "*v#gbu#pneumo*Radiographie thoracique de face en urgence (débrouillage).<br/>\
            Le scanner thoracique injecté en urgence est l'examen de référence.**",
           "pictures": [
                { "img": "32111.jpg", "caption": "Coupe coronale épaisse d'un angioscanner thoracique montrant une hypertrophie marquée de l'artère bronchique droite (flèche rouge), dans un contexte de déficit immunitaire chez un jeune homme. Voir aussi image 2." },
                { "img": "32111.0.jpg", "caption": "Artériographie du patient de l'image 1. Les flèches vertes désignent les systèmes de micro-cathéters. La flèche rouge désigne l'artère bronchique droite hypertrophiée et l'étoile rouge le système capillaire d'aval hypertrophié." },
                { "img": "321.0.jpg", "caption": "Coupe axiale d'un scanner thoracique présentée en fenêtre parenchymateuse, évoquant une tuberculose pulmonaire devant des cavernes (étoiles rouges), des infiltrats (flèche verte) et des micronodules (flèche rose)." }
            ]
        },
        {
            "id": "3.2.0.0",
            "title": "Score de Genève modifié simplifié",
            "description": "Additionnez les points de chaque item présenté par votre patient.<br/>\
            [[Patient de 65 ans ou plus *r*(1 point)**]\
            [Antécédent de TVP ou EP *r*(1 point)**]\
            [Dans le mois précédent : chirurgie, anesthésie ou fracture de membre inférieur *r*(1 point)**]\
            [Antécédent récent ou ancien de cancer (solide ou hématologique) *r*(1 point)**]\
            [Douleur unilatérale d'un membre inférieur *r*(1 point)**]\
            [Hémoptysie *r*(1 point)**]\
            [Fréquence cardiaque > ou = 75 BPM *r*(1 point)**]\
            [Si fréquence cardiaque > ou = 95 BPM *r*(1 point supplémentaire)**]\
            [Douleur à la palpation d'un trajet veineux ou oedème unilatéral d'un membre *r*(1 point)**]]",
            "leadsTo": ["3.2.0.0.0", "3.2.0.0.1"]
        },
        {
            "id": "3.2.0.0.0",
            "title": "Le score est de 4 ou moins",
            "description": "La probabilité clinique du patient n'est pas forte.<br/>\
            *r#gbu#pneumo#cardio*Le dosage des D-dimères doit être réalisé avant toute imagerie.**<br/>\
            *v#gbu#pneumo#cardio*Si le taux de D-dimères corrigé pour l'âge est supérieur à la norme:<br/>\
            L'angioscanner thoracique est à réaliser en urgence.<br/>\
            La scintigraphie de ventilation-perfusion sera réalisée en cas de contre-indication au scanner.**<br/>\
            <br/>\
            *r#gbu#pneumo#cardio*Si le taux de D-dimères corrigé pour l'âge est inférieur à la norme:<br/>\
            L'angioscanner thoracique ne doit pas être réalisé.**",
            "pictures": [
                { "img": "32000.jpg", "caption": "Coupe axiale d'un angioscanner de l'artère pulmonaire, montrant de multiples defects d'opacification intraluminale des artères pulmonaires (flèches rouges), en faveur d'une embolie pulmonaire bilatérale et proximale. Dilatation marquée du tronc de l'artère pulmonaire et de ses branches de division primitive (mesures présentées)." },
                { "img": "32000.0.jpg", "caption": "Reconstruction dans le plan de la branche droite de l'artère pulmonaire (étoile rose), montrant de multiples thrombi (têtes de flèches rouges) dont un thrombus en moustache, à cheval sur la branche lobaire supérieure et la branche lobaire moyenne (flèche verte)." }
            ]
        },
        {
            "id": "3.2.0.0.1",
            "title": "Le score est de 5 ou plus",
            "description": "La probabilité clinique du patient est forte.<br/>\
            *v#gbu#pneumo#cardio*L'angioscanner thoracique est à réaliser en urgence sans doser les D-dimères.**",
            "pictures": [
                { "img": "320.jpg", "caption": "Coupe axiale d'un angioscanner de l'artère pulmonaire (étoile rose), montrant de multiples defects d'opacification intraluminale des artères pulmonaires (flèches rouges), en faveur d'une embolie pulmonaire bilatérale et proximale." },
                { "img": "320.0.jpg", "caption": "Coupe axiale d'un angioscanner de l'artère pulmonaire, montrant un aspect de coeur pulmonaire aigu dans un contexte d'embolie pulmonaire (flèches rouges). Pour rappel, le rapport VD/VG est normal lorsqu'il est inférieur à 90%." }
            ]
        },
        //start voies aériennes
        {
            "id": "3.3.0",
            "title": "Corps étranger des voies aériennes",
            "description": "",
            "leadsTo": ["3.3.0.0", "3.3.0.1"]
        },
        {
            "id": "3.3.1",
            "title": "Crise d'asthme",
            "description": "*o#gbu#pneumo*Radiographie thoracique indiquée en urgence dans des cas particuliers:<br/>\
            En cas de crise d'asthme inaugurale,<br/>\
            En cas d'exacerbation d'un asthme chronique mal équilibré,<br/>\
            En cas de suspicion de complication de la crise d'asthme.**<br/>\
            *o#gbu#pneumo*Un scanner thoracique est indiqué sans urgence en l'absence de réponse à un traitement chronique bien conduit.**",
            "pictures": [
                { "img": "RPN.jpg", "caption": "Radiographie pulmonaire debout de face normale." },
                { "img": "3121.jpg", "caption": "Radiographie pulmonaire debout de face montrant un pneumothorax complet gauche (flèches roses), dont la clinique se présentait comme des symptômes respiratoires inhabituels (chez un patient asthmatique)." },
                { "img": "3311.jpg", "caption": "Radiographie pulmonaire debout de face montrant une déformation du bord médiastinal gauche (flèches rouges). Le scanner complémentaire réalisé au décours montre une volumineuse masse suspecte."},
            ]
        },
        {
            "id": "3.3.2",
            "title": "Exacerbation de BPCO",
            "description": "*v#gbu#pneumo*Radiographie du thorax indiquée en urgence.**<br/>\
            *o#gbu#pneumo*Scanner thoracique à discuter en dehors de l'urgence pour bilan étiologique.**",
            "pictures": [
                { "img": "332.jpg", "caption": "Radiographie pulmonaire debout de face, montrant une hyperclarté des deux champs pulmonaires, un élargissement thoracique franc et un aplatissement des coupoles diaphragmatiques, en faveur d'une BPCO." }
            ]
        },
        {
            "id": "3.3.3",
            "title": "Manifestations allergiques respiratoires",
            "description": "Quel est l'état clinique du patient? ",
            "leadsTo": ["3.3.3.0", "3.3.3.1"]
        },
        {
            "id": "3.3.0.0",
            "title": "Syndrome de pénétration en cours",
            "description": "*r#gbu#pneumo*Pas d'examen d'imagerie indiqué en urgence**<br/>\
            Prise en charge thérapeutique urgente par une équipe spécialisée.<br/>\
            *o#gbu#pneumo*L'intérêt d'une radiographie pulmonaire pourra être discuté après résolution du syndrome de pénétration.**",
            "pictures": [
                { "img": "RPN.jpg", "caption": "Radiographie pulmonaire debout de face normale." },
                {"img": "232.jpg", "caption": "Radiographie du thorax et de l'abdomen de face chez une enfant de 3 ans, après ingestion d'une pièce de monnaie (flèche rouge)."}
            ]
        },
        {
            "id": "3.3.0.1",
            "title": "Syndrome de pénétration régressif (ou absent)",
            "description": "*v#gbu#pneumo*Radiographie pulmonaire en urgence.<br/>\
            Afin de ne pas méconnaître la présence d'un corps étranger, même si celui-ci a à priori été expulsé.**<br/>\
            Attention: seuls 10% des corps étrangers sont radio-opaques.",
            "pictures": [
                { "img": "RPN.jpg", "caption": "Radiographie pulmonaire debout de face normale."},
                {"img": "232.jpg", "caption": "Radiographie du thorax et de l'abdomen de face chez une enfant de 3 ans, après ingestion d'une pièce de monnaie (flèche rouge)."}
            ]
        },
        {
            "id": "3.3.3.0",
            "title": "Détresse respiratoire",
            "description": "*v#gbu#pneumo*Radiographie pulmonaire en urgence après prise en charge thérapeutique**",
            "pictures": [
                { "img": "3330.jpg", "caption": "Radiographie pulmonaire debout de face montrant un syndrome interstitiel diffus et bilatéral, prédominant dans les zones marquées d'étoiles rouges." },
                { "img": "RPN.jpg", "caption": "Radiographie pulmonaire debout de face normale." }
            ]
        },
        {
            "id": "3.3.3.1",
            "title": "Pas de détresse respiratoire",
            "description": "*r#gbu#pneumo*Pas d'imagerie indiquée en urgence.**",
        },
        //start mammo
        {
            "id": "3.4.0",
            "title": "Ecoulement mammelonnaire",
            "description": "*r#gbu#gyneco*Bilan sénologique sans urgence.**",
            "pictures": [
                { "img": "342.jpg", "caption": "Mammographie selon une incidence de face, montrant une volumineuse masse mammaire (étoile rouge), siège d'un petit clip (flèche verte), qui a été laissé en place lors de la microbiopsie réalisée précédemment." },
                { "img": "342.1.jpg", "caption": "Mammographie selon une incidence oblique, montrant une masse mammaire rétromamelonnaire (flèche verte)." }
            ]
        },
        {
            "id": "3.4.1",
            "title": "Gynécomastie",
            "description": "*r#gbu#gyneco*Bilan sénologique sans urgence.**",
            "pictures": [
                { "img": "341.jpg", "caption": "Coupe axiale d'un scanner thoracique réalisé au temps portal de l'injection de produit de contraste iodé chez une personne de sexe masculin, montrant une gynécomastie bilatérale (développement de glande mammaire) fléchée en rouge, de découverte fortuite." }
            ]
        },
        {
            "id": "3.4.2",
            "title": "Masse palpable du sein",
            "description": "*r#gbu#gyneco*Bilan sénologique sans urgence, à réaliser dans un délai raisonnable.**",
            "pictures": [
                { "img": "342.jpg", "caption": "Mammographie selon une incidence de face, montrant une volumineuse masse mammaire (étoile rouge), siège d'un petit clip (flèche verte), qui a été laissé en place lors de la microbiopsie réalisée précédemment." },
                { "img": "342.1.jpg", "caption": "Mammographie selon une incidence oblique, montrant une masse mammaire rétromamelonnaire (flèche verte)." }
            ]
        },
        {
            "id": "3.4.3",
            "title": "Sein inflammatoire",
            "description": "*v#gbu#gyneco*Echographie mammaire en urgence.**<br/>\
            *o#gbu#gyneco*Mammographie à réaliser en semi-urgence dans certains cas (souvent différée en pratique).**<br/>\
            *r#gbu#gyneco*IRM mammaire à réaliser dans des cas particulier et hors urgence.**",
            "pictures": [
            { "img": "343.jpg", "caption": "Coupe échographique centrée sur le sein gauche, retrouvant une collection mammaire organisée avec un contenu hypoéchogène (étoile rouge), une large paroi hypoéchogène (flèche verte). Au pourtour, la graisse est inflammatoire (têtes de flèches roses)." }
            ]
        },
        // start COVID
        {
            "id": "3.5",
            "title": "COVID 19",
            "description": "De quelle situation relève votre patient?",
            "leadsTo": ["3.5.0", "3.5.1"]
        },
        {
            "id": "3.5.0",
            "title": "PCR SARS-CoV-2 négative ou indisponible",
            "description": "Votre patient présente-t-il des signes cliniques de gravité?",
            "leadsTo": ["3.5.0.0", "3.5.0.1"]
        },
        {
            "id": "3.5.1",
            "title": "PCR SARS-CoV-2 positive",
            "description": "",
            "leadsTo": ["3.5.1.0", "3.5.1.1"]
        },
        //boucle PCR neg ou non dispo
        {
            "id": "3.5.0.0",
            "title": "Etat clinique grave",
            "description": "*v#radio*Scanner thoracique sans injection indiqué en urgence.**<br/>\
            *o#radio*En cas de suspicion d'embolie pulmonaire associée, un scanner thoracique injecté pourra être réalisé.**",
            "pictures": [
                { "img": "3500.jpg", "caption": "Coupe axiale d'un scanner thoracique sans injection en fenêtre parenchymateuse, montrant une atteinte pulmonaire modérée évocatrice de COVID-19 (flèches rouges), sous la forme de plages sous-pleurales de verre dépoli. La trame pulmonaire normale est marquée d'une étoile verte. Cette image rappelle que l'atteinte scanographique n'est pas toujours correlée à l'état clinique." },
                { "img": "3500.0.jpg", "caption": "Coupe axiale d'un scanner thoracique présentée en fenêtre parenchymateuse, montrant une atteinte pulmonaire très sévère évocatrice de COVID-19 sous la forme de verre dépoli diffus et condensations postérieures. La sonde d'intubation trachéale est montrée en rouge, chez ce patient qui a également un antécédent de chirurgie cardiaque (fils de sternotomie marqués en vert)." },
                { "img": "320.jpg", "caption": "Coupe axiale d'un angioscanner de l'artère pulmonaire (étoile rose), montrant de multiples defects d'opacification intraluminale des artères pulmonaires (flèches rouges), en faveur d'une embolie pulmonaire bilatérale et proximale." }
            ]
        },
        {
            "id": "3.5.0.1",
            "title": "Etat clinique non grave",
            "description": "Votre patient présente-t-il au moins une comorbidité?",
            "leadsTo": ["3.5.0.1.0", "3.5.0.1.1"]
        },
        {
            "id": "3.5.0.1.0",
            "title": "Pas de comorbidité majeure",
            "description": "*r#radio*Scanner thoracique non indiqué.**<br/>\
            *o#radio*Radiographie thoracique à discuter (à visée de diagnostic différentiel).**",
            "pictures": [
                { "img": "RPN.jpg", "caption": "Radiographie pulmonaire debout de face normale." },
                { "img": "35010.jpg", "caption": "Radiographie pulmonaire debout de face, montrant un syndrome interstitiel diffus, prédominant dans les bases pulmonaires, sans foyer organisé visible." }
            ]
        },
        {
            "id": "3.5.0.1.1",
            "title": "Au moins une comorbidité majeure",
            "description": "*v#radio*Le scanner thoracique sans injection est indiqué (notamment pour aide au diagnostic positif) si le patient est à risque de décompensation brutale.**<br/>\
            Attention : l'absence d'anomalie scanographique dans les trois premiers jours d'évolution des symptômes ne réfute pas le diagnostic d'infection à SARS-CoV-2.",
            "pictures": [
                { "img": "35011.jpg", "caption": "Coupe axiale d'un scanner thoracique sans injection en fenêtre parenchymateuse chez une patiente obèse morbide, ne montrant pas de signe typique d'atteinte de COVID 19, dans les limites de la mauvaise qualité (surtout liée à la respiration de la patiente)." },
                { "img": "35011.0.jpg", "caption": "Radiographie pulmonaire réalisée debout de face chez un patient immunodéprimé infecté par SARS-CoV-2 et qui présente une dégradation clinique. Pneumopathie organisée du lobe moyen (effaçant le bord droit du coeur) marquée d'une étoile rouge, venant compliquer l'infection virale." },
            ]
        },
        //boucle PCR positive
        {
            "id": "3.5.1.0",
            "title": "Etat clinique grave",
            "description": "*v#radio*Scanner thoracique avec injection de produit de contraste indiqué en urgence.**",
            "pictures": [
                { "img": "3500.jpg", "caption": "Coupe axiale d'un scanner thoracique sans injection en fenêtre parenchymateuse, montrant une atteinte pulmonaire sévère évocatrice de COVID 19 (flèches rouges), sous la forme de plages sous-pleurales diffuses en verre dépoli. La trame pulmonaire normale est marquée d'une étoile verte." },
                { "img": "320.jpg", "caption": "Coupe axiale d'un angioscanner de l'artère pulmonaire (étoile rose), montrant de multiples defects d'opacification intraluminale des artères pulmonaires (flèches rouges), en faveur d'une embolie pulmonaire bilatérale et proximale." }
            ]
        },
        {
            "id": "3.5.1.1",
            "title": "Etat clinique non grave",
            "description": "Votre patient présente-t-il au moins une comorbidité?",
            "leadsTo": ["3.5.1.1.0", "3.5.1.1.1"]
        },
        {
            "id": "3.5.1.1.0",
            "title": "Pas de comorbidité majeure",
            "description": "*r#radio*Aucune imagerie indiquée.**",
        },
        {
            "id": "3.5.1.1.1",
            "title": "Au moins une comorbidité majeure",
            "description": "*v#radio*Le scanner thoracique sans injection est indiqué si le patient est à risque de décompensation brutale.**",
            "pictures": [
                { "img": "35111.jpg", "caption": "Coupe axiale d'un scanner thoracique sans injection en fenêtre parenchymateuse, montrant une atteinte pulmonaire modérée évocatrice de COVID 19 (flèches rouges), sous la forme de plages sous-pleurales en verre dépoli avec réticulations intra-lobulaires. La trame pulmonaire normale est marquée d'une étoile verte." }
            ]
        },
        //start abdomen pelvis
        {
            "id": "4.0",
            "title": "PATHOLOGIES DU SYSTEME DIGESTIF",
            "description": "A quel système appartient la pathologie suspectée?",
            "leadsTo": ["4.0.0", "4.0.1", "4.0.2", "4.0.3"]
        },
        {
            "id": "4.1",
            "title": "PATHOLOGIES UROLOGIQUES ET NEPHROLOGIQUES",
            "description": "Quelle est votre suspicion clinique?",
            "leadsTo": ["4.1.0", "4.1.1", "4.1.2", "4.1.3", "4.1.4", "4.1.5"]
        },
        {
            "id": "4.2",
            "title": "PATHOLOGIES GENITALES MASCULINES",
            "description": "",
            "leadsTo": ["4.2.0", "4.2.1"]
        },
        {
            "id": "4.3",
            "title": "PATHOLOGIES GYNECO-OBSTETRICALES ET POST-PARTUM",
            "description": "",
            "leadsTo": ["4.3.0", "4.3.1"]
        },
        //start système digestif
        {
            "id": "4.0.0",
            "title": "Système hépato-biliaire",
            "description": "Quelle est votre suspicion clinique?",
            "leadsTo": ["4.0.0.0", "4.0.0.1", "4.0.0.2", "4.0.0.3"]
        },
        {
            "id": "4.0.1",
            "title": "Système pancréatico-splénique",
            "description": "Quelle est votre suspicion clinique?",
            "leadsTo": ["4.0.1.0", "4.0.1.1"]
        },
        {
            "id": "4.0.2",
            "title": "Système oeso-gastro-duodénal",
            "description": "Quelle est votre suspicion clinique?",
            "leadsTo": ["4.0.2.0", "4.0.2.1", "4.0.2.2", "4.0.2.3", "4.0.2.4"]
        },
        {
            "id": "4.0.3",
            "title": "Système grêlo-colique",
            "description": "",
            "leadsTo": ["4.0.3.0", "4.0.3.1", "4.0.3.2", "4.0.3.3", "4.0.3.4", "4.0.3.5", "4.0.3.6"]
        },
        {
            "id": "4.0.4",
            "title": "Autres",
            "description": "",
            "leadsTo": ["4.0.4.0", "4.0.4.1", "4.0.4.2", "4.0.4.3", "4.0.4.3"]
        },
        //start hepatobiliaire 400
        {
            "id": "4.0.0.0",
            "title": "Perturbation isolée du bilan hépatique",
            "description": "",
            "leadsTo": ["4.0.0.0.0", "4.0.0.0.1", "4.0.0.0.2"]
        },
        {
            "id": "4.0.0.1",
            "title": "Cholécystite / Angiocholite",
            "description": "*v#gbu#gastro#dig*Echographie abdomino-pelvienne en urgence.**<br/>\
            *o#gbu#gastro#dig*Le scanner abdomino-pelvien injecté se discute en complément après l'échographie.**",
            "pictures": [
                { "img": "400111.jpg", "caption": "Coupe dans le plan de la vésicule biliaire issue d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une cholécystite (étoile rouge) probablement nécrosante (perte de la paroi antérieure montrée par une flèche rouge) et infiltration de la graisse de la loge vésiculaire (tête de flèche rouge). Le patient présente également un globe urinaire (étoile jaune)." },
                { "img": "4001.1.jpg", "caption": "Coupe échographique à la sonde convexe basse fréquence, montrant un épaississement de la paroi (flèche rouge) de la vésicule biliaire, qui contient du sludge (étoile verte), en faveur du diagnostic de cholécystite aigue. Le foie est marqué d'une étoile bleue." },
                { "img": "4001.2.jpg", "caption": "Coupe dans le plan de la vésicule biliaire issue d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une cholécystite gangréneuse (flèches rouges), la paroi de la vésicule biliaire étant le siège d'une infiltration gazeuse diffuse. La loge graisseuse vésiculaire est infiltrée, témoignant de l'inflammation locale. Par ailleurs, la vésicule biliaire est le siège d'images denses en son sein, pouvant plaider pour des lithiases (tête de flèche verte)." },
            ]
        },
        {
            "id": "4.0.0.2",
            "title": "Calcul de la vésicule biliaire sans complication",
            "description": "*o#gbu#gastro#dig*Echographie abdomino-pelvienne sans urgence.**",
            "pictures": [
                { "img": "4002.jpg", "caption": "Coupe échographique à la sonde convexe basse fréquence sur la vésicule biliaire, montrant un volumineux calcul (étoile rouge) avec cône d'ombre postérieur (flèche rouge) au sein de la vésicule. La paroi de la vésicule biliaire est fine. Le foie est marqué d'une étoile bleue. " }
            ]
        },
        {
            "id": "4.0.0.3",
            "title": "Complication de chirurgie des voies biliaires / Fistules",
            "description": "Avis spécialisé en urgence.<br/>\
            *v#gbu#dig*Echographie abdomino-pelvienne en première intention.**<br/>\
            *o#gbu#dig*Scanner abdomino-pelvien injecté en seconde intention.<br/>\
            IRM hépatique à discuter en complément et en milieu spécialisé.**",
            "pictures": [
                { "img": "4003.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un réhaussement diffus du péritoine (flèches rouges) signant une péritonite, associé à un épanchement liquidien diffus (étoiles rouges) au contact de l'anastomose bilio-digestive (non visible sur cette coupe). Le tout est en faveur d'un lâchage de l'anastomose, chez cette patiente qui a bénéficié d'une duodéno-pancréatectomie céphalique pour prise en charge d'une tumeur de la tête du pancréas." },
                { "img": "4003.0.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un hématome intra-hépatique (densité de 50 UH spontanément sur le temps sans injection non montré ici), découvert dans les suites d'une chirurgie abdominale." }
            ]
        },
        {
            "id": "4.0.0.0.0",
            "title": "Cholestase isolée",
            "description": "*v#gastro*Echographie abdomino-pelvienne dans un délai raisonnable à confronter à la clinique (semi-urgence habituellement).**",
            "pictures": [
                { "img": "40000.jpg", "caption": "Coupe échographique à la sonde convexe à basse fréquence, montrant une dilatation de la voie biliaire principale (étoile rouge) qui contient en son sein des lithiases (mesurées sur cette coupe), en faveur d'un empierrement de la voie biliaire principale. Le foie est marqué d'une étoile bleue." },
                { "img": "40000.0.jpg", "caption": "Coupe échographique à la sonde convexe à basse fréquence, montrant une stéatose hépatique diffuse avec un gradient hépato-rénal marqué. En effet, l'échogénicité du foie est plus marquée que celle du rein homolatéral." }
            ]
        },
        {
            "id": "4.0.0.0.1",
            "title": "Cytolyse isolée < 5N sans comorbidité",
            "description": "*r#gastro*Pas d'examen d'imagerie en urgence.**<br/>\
            Contrôle de la biologie à 3 mois.",
              "pictures": [
                { "img": "4001.jpg", "caption": "Coupe axiale d'un scanner présenté en contraste spontané, montrant une importante stéatose hépatique diffuse. Le foie (étoiles rouges) est beaucoup moins dense que la rate (flèche rose), et il y a une inversion du contraste spontané foie-vaisseaux (flèches vertes)." }
            ]
        },
        {
            "id": "4.0.0.0.2",
            "title": "Cytolyse > 5N / Cytolyse + comorbidité",
            "description": "*v#gastro*Echographie abdomino-pelvienne en urgence.**<br/>\
            *o#gastro*Scanner abdomino-pelvien injecté à discuter en complément en fonction du contexte.**",
            "pictures": [
                { "img": "40000.jpg", "caption": "Coupe échographique à la sonde convexe à basse fréquence, montrant une dilatation de la voie biliaire principale (étoile rouge) qui contient en son sein des lithiases (mesurées sur cette coupe), en faveur d'un empierrement de la voie biliaire principale. Le foie est marqué d'une étoile bleue." },
                { "img": "40002.jpg", "caption": "Coupe échographique à la sonde convexe à basse fréquence, montrant du matériel échogène au sein du tronc porte et de la bifurcation portale (flèche rouge), en faveur d'une thrombose porte. A titre de comparaison, la veine cave inférieure, qui est normalement perméable, est marquée d'une tête de flèche verte." }
            ]
        },
        //start pancreatico splénique 401
        {
            "id": "4.0.1.0",
            "title": "Pancréatite aigue",
            "description": "Quel est l'état clinique du patient",
            "leadsTo": ["4.0.1.0.0", "4.0.1.0.1", "4.0.1.0.2"]
        },
        {
            "id": "4.0.1.1",
            "title": "Splénomégalie isolée",
            "description": "*o#gbu#gastro*Echographie abdomino-pelvienne sans urgence pour bilan.<br/>\
            Discuter l'intérêt d'un scanner abdomino-pelvien injecté en complément, hors urgence.**",
            "pictures": [
                { "img": "4011.jpg", "caption": "Coupe échographique montrant la rate (étoile rouge) dans son plus grand axe (qui est mesuré à plus de 25 cm de flèche)." },
                { "img": "4011.0.jpg", "caption": "Coupe coronale d'un scanner abdomino-pelvien au temps portal de l'injection de contraste, montrant une splénomégalie homogène (flèche rouge), secondaire à une hypertension portale massive. Multiples voies de dérivation porto-systémiques (têtes de flèches vertes) et cavernome portal (flèche rose) signant une thrombose porte chronique." },
            ]
        },
        {
            "id": "4.0.1.0.0",
            "title": "Au moins une défaillance organique sévère",
            "description": "Pancréatite aigue dite sévère.<br/>\
            *v#atlanta*Scanner abdomino-pelvien injecté indiqué en urgence.**<br/>\
            *v#atlanta*L'échographie abdomino-pelvienne pourra également être réalisée en urgence.**",
            "pictures": [
                { "img": "40100.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant des coulées de nécrose avec du matériel gazeux (flèches rouges) dans la loge pancréatique, associées à un réhaussement des feuillets péritonéaux (flèche jaune) et un réhaussement très peu marqué du pancréas (étoile rouge). Le tout fait évoquer une pancréatite aigue nécrotique avec péritonite." },
                { "img": "40000.jpg", "caption": "Coupe échographique à la sonde convexe à basse fréquence, montrant une dilatation de la voie biliaire principale (étoile rouge) qui contient en son sein des lithiases (mesurées sur cette coupe), en faveur d'un empierrement de la voie biliaire principale. Le foie est marqué d'une étoile bleue. Le tout fait évoquer une origine lithiasiue pour la pancréatite aigue." }
            ]
        },
        {
            "id": "4.0.1.0.1",
            "title": "Au moins une défaillance organique non grave",
            "description": "Pancréatite aigue dite modérément sévère.<br/>\
            *v#atlanta*Echographie hépatique en urgence.**<br/>\
            *o#atlanta*Scanner abdomino-pelvien injecté à réaliser à au moins 96h du début des symptômes.**",
            "pictures": [
                { "img": "40101.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant des pseudokystes (flèches rouges) séquellaires d'un épisode ancien de pancréatite aigue. Le pancréas apparaît discrètement oedémateux (étoile rouge), sans infiltration de sa loge." },
                { "img": "40101.1.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un oedème du pancréas (marqué par des étoiles rouges) et une infiltration liquidienne de la loge pancréatique (flèches jaunes)." }
            ]
        },
        {
            "id": "4.0.1.0.2",
            "title": "Pas de défaillance organique",
            "description": "Pancréatite aigue dite légère.<br/>\
            *v#atlanta*Echographie hépatique en urgence.**<br/>\
            *r#atlanta*Pas de scanner abdomino-pelvien systématique (recommandations Atlanta 2012).**",
            "pictures": [
                { "img": "40000.jpg", "caption": "Coupe échographique à la sonde convexe à basse fréquence, montrant une dilatation de la voie biliaire principale (étoile rouge) qui contient en son sein des lithiases (mesurées sur cette coupe), en faveur d'un empierrement de la voie biliaire principale. Le foie est marqué d'une étoile bleue. Le tout fait évoquer une origine lithiasiue pour la pancréatite aigue." },
                 { "img": "40102.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant des collections de la loge pancréatique (flèches rouges)." }
            ]
        },
        // start OGD 402
        {
            "id": "4.0.2.0",
            "title": "Reflux gastro-oesophagien / Hernie hiatale",
            "description": "*r#gbu#gastro#dig*Pas d'imagerie en urgence.**<br/>\
            *o#gbu#gastro#dig*Scanner thoraco-abdomino-pelvien injecté si suspicion de complication.**",
            "pictures": [
                {"img": "4020.jpg", "caption": "Coupe axiale d'un scanner thoraco-abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une volumineuse hernie hiatale à contenu liquidien (flèche rouge), non compliquée. Voir aussi image 2."},
                {"img": "4020.0.jpg", "caption": "Coupe sagittale d'un scanner thoraco-abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une volumineuse hernie hiatale à contenu liquidien (flèche rouge), non compliquée. Voir aussi image 1"}
            ]
        },
        {
            "id": "4.0.2.1",
            "title": "Perforation oesophagienne",
            "description": "*v#gbu#gastro#dig*Radiographie thoracique en urgence (débrouillage).<br/>\
            Scanner thoracique injecté en urgence (avec ou sans opacification haute, à l'appréciation du radiologue).**",
            "pictures": [
                {"img": "4021.76.jpg", "caption": "Radiographie pulmonaire de face semi-assise, montrant un pneumopéritoine (étoiles rouges) et un pneumomédiastin (flèches jaunes), faisant suspecter la perforation d'un organe creux."},
                {"img": "232.0.jpg", "caption": "Coupe axiale d'un scanner thoracique réalisé au temps portal de l'injection de produit de contraste iodé, montrant une perforation oesophagienne (flèches rouges), et un épanchement pleural droit avec atélectasie secondaire (étoile verte)."}
            ]
        },
        {
            "id": "4.0.2.2",
            "title": "Hématémèse",
            "description": "Avis gastro-entérologique en urgence.<br/>\
            Priorité à la fibroscopie oeso-gastro-duodénale (FOGD).<br/>\
            *o#gbu#gastro#dig*Scanner abdomino-pelvien injecté à discuter après avis spécialisé.**",
                "pictures": [
                { "img": "4023.0.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un épaississement marqué et circonférentiel du pylore, ainsi qu'une hyperhémie muqueuse associée (flèches rouges), en faveur d'un ulcère pylorique à priori non compliqué. A confronter à la fibroscopie."},
                { "img": "4030.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps tardif de l'injection de produit de contraste, mettant en évidence un saignement actif au sein d'une anse grêle (flèche rouge) sous la forme d'une nappe de produit de contraste se répandant en intra-digestif. La flèche jaune matérialise un trajet de chirurgie récent, ce qui pourrait plaider pour une complication liée au geste."},
                { "img": "4022.jpg", "caption": "Volumineux faux-anévrisme compliquant une pancréatite aigue révélé par une hématémèse abondante. L'image A montre une reconstruction volumique de l'angioscanner, et l'image B une vue d'artériographie pré-thérapeutique. Le faux-anévrisme est développé au dépens d'une branche de l'artère gastro-duodénale (flèches rouges). L'image C montre la vue artériographique après exclusion d'amont et d'aval par coils (flèche verte)."}
            ]
        },
        {
            "id": "4.0.2.3",
            "title": "Ulcère gastrique ou duodénal",
            "description": "*r#gbu#gastro#dig*Pas d'indication au scanner en cas de suspicion d'ulcère non compliqué.**<br/>\
            *v#gbu#gastro#dig*Scanner abdomino-pelvien injecté en urgence si suspicion de complication.**",
            "pictures": [
                { "img": "4023.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un ulcère duodénal perforé (flèche rose), avec infiltration liquidienne et gazeuse de la graisse au pourtour (flèches jaunes). La lumière duodénale et les parois duodénales inflammatoires sont marquées d'une étoile verte." },
                { "img": "4023.0.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un épaississement marqué et circonférentiel du pylore, ainsi qu'une hyperhémie muqueuse associée (flèches rouges), en faveur d'un ulcère pylorique à priori non compliqué. A confronter à la fibroscopie." }
            ]
        },
        {
            "id": "4.0.2.4",
            "title": "Suites compliquées d'une chirurgie",
            "description": "Avis spécialisé en urgence.<br/>\
            *v#gbu#dig*Scanner (thoraco-)abdomino-pelvien en urgence.**<br/>\
            *o#gbu#dig*Radiographies couplées à une opacification digestive haute en cas de non disponibilité du scanner.**",
            "pictures": [
                { "img": "4030.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps tardif de l'injection de produit de contraste, mettant en évidence un saignement actif au sein d'une anse grêle (flèche rouge) sous la forme d'une nappe de produit de contraste se répandant en intra-digestif. La flèche jaune matérialise un trajet de chirurgie récent, ce qui pourrait plaider pour une complication liée au geste." },
                { "img": "4024.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, mettant en évidence un abcès partiellement calcifié (étoile rouge) para-hépatique droit et une collection intra-hépatique en cours d'organisation (flèches vertes) plusieurs semaines après une  cholécystectomie. L'exploration chirurgicale réalisée a retrouvé une compresse chirurgicale oubliée en région para-hépatique droite." }
            ]
        },
        //start grelo colique 403
        {
            "id": "4.0.3.0",
            "title": "Melena / Saignement digestif",
            "description": "Avis spécialisé en urgence.<br/>\
            Priorité à la fibroscopie oeso-gastro-duodénale (FOGD).<br/>\
            *o#gbu#gastro#dig*Le scanner (thoraco-)abdomino-pelvien injecté peut se discuter dans des cas particuliers.**",
            "pictures": [
                { "img": "4030.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps tardif de l'injection de produit de contraste, mettant en évidence un saignement actif au sein d'une anse grêle (flèche rouge) sous la forme d'une nappe de produit de contraste se répandant en intra-digestif. La flèche jaune matérialise un trajet de chirurgie récent." }
            ]
        },
        {
            "id": "4.0.3.1",
            "title": "Syndrome occlusif / Hernies pariétales",
            "description": "*v#gbu#dig*Scanner abdomino-pelvien injecté en urgence.**<br/>\
            *o#gbu#dig*Echographie abdomino-pelvienne en urgence dans des cas particuliers (grossesse).**<br/>\
            *r#gbu#dig*La radiographie n'a pas de place dans le syndrome occlusif. L'ASP ne doit plus être réalisé.**<br/>\
            *r#gbu#dig*Le bilan d'une hernie pariétale non compliquée se fait par scanner abdomino-pelvien sans injection et sans urgence.**",
            "pictures": [
                { "img": "4031.jpg", "caption": "Coupe coronale d'un scanner abdomino-pelvien injecté montrant un syndrome occlusif à anse fermée avec jonction anse plate - anse dilatée (flèche rouge) et souffrance d'une anse grêle (étoile verte), avec notamment un défaut de réhaussement pariétal et un oedème pariétal marqués (flèche verte) comparativement aux anses digestives d'amont et d'aval." },
                { "img": "4031.0.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien injecté montrant un syndrome occlusif grêlique distal en amont d'une zone de jonction anse plate-anse dilatée en fosse iliaque droite (flèche jaune). Dilatation et niveaux hydro-aériques de l'intestin grêle d'amont (étoiles rouge), alors que l'intestin grêle d'aval est plat (têtes de flèches vertes)." }
            ]
        },
        {
            "id": "4.0.3.2",
            "title": "Entérite / Colite",
            "description": "*r#gbu#gastro*Pas d'examen d'imagerie en urgence en l'absence de signe clinique de gravité.**<br/>\
            *v#gbu#gastro*Scanner abdomino-pelvien injecté en urgence en cas de signe clinique de gravité.**<br/>\
            Discuter l'intérêt d'une imagerie à distance de l'épisode aigu, après avis spécialisé (doute avec une poussée initiale d'une MICI par exemple).",
            "pictures": [
                { "img": "4032.jpg", "caption": "Coupe en plan oblique d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un franc épaississement et une franche hyperhémie diffuse des parois coliques (flèches rouges), en faveur d'une colite." },
                { "img": "4032.1.jpg", "caption": "Coupe d'un scanner abdomino-pelvien au temps portal de l'injection de produit de contraste, dans un plan oblique déroulant la jonction iléo-caecale, et montrant une iléite terminale (flèche rouge), devant un épaississement de la paroi iléale terminale (qui apparaît hyperhémiée) et dont la graisse au contact est infiltrée." }
            ]
        },
        {
            "id": "4.0.3.3",
            "title": "Ischémie mésentérique",
            "description": "Avis spécialisé en urgence.<br/>\
            *v#gbu#dig*Scanner abdomino-pelvien injecté en urgence.**<br/>\
            *o#gbu#dig*Echographie abdomino-pelvienne de débrouillage à discuter si le patient est instable.**",
            "pictures": [
                { "img": "4033.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un défaut de réhaussement massif des anses digestives gêliques marquées par une étoile rouge, ainsi qu'une pneumatose pariétale diffuse de celles-ci (flèches rouge). Le tout fait évoquer une ischémie mésentérique. A titre de comparaison, le réhaussement normal des anses digestives non touchées est marqué par des flèches vertes." },
                { "img": "4033.0.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un défaut de réhaussement de multiples segments hépatiques (étoiles jaunes), ainsi qu'une aéroportie diffuse et massive (flèches rouges), s'intrégrant dans un contexte d'ischémie mésentérique." }
            ]
        },
        {
            "id": "4.0.3.4",
            "title": "Appendicite aigue",
            "description": "*v#gbu#dig*Echographie abdomino-pelvienne en urgence à préférer chez les enfants, les femmes enceintes, et les adultes accessibles.<br/>\
            Scanner abdomino-pelvien injecté en urgence dans les autres cas ou en complément de l'échographie si besoin.**",
            "pictures": [
                { "img": "4034.jpg", "caption": "Image échographique d'une appendicite aigue, montrant un aspect inflammatoire de l'appendice (marqué par une étoile rouge), avec un franc épaississement de ses parois, un stercolithe en son sein (flèche rouge) et un aspect très inflammatoire de la graisse à son pourtour (étoiles vertes)" },
                { "img": "4034.1.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une appendicite aigue (flèche rouge) compliquée d'un volumineux abcès sous-cutané (étoile rouge), communiquant avec l'appendice par un pertuis (flèche verte)." }
            ]
        },
        {
            "id": "4.0.3.5",
            "title": "Diverticulite aigue / Sigmoïdite aigue",
            "description": "*v#gbu#gastro#dig*Scanner abdomino-pelvien injecté en urgence.**<br/>\
            Réaliser une coloscopie à distance de l'épisode aigu pour ne pas méconnaître une lésion sous-jacente.",
            "pictures": [
                { "img": "4035.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une inflammation de la paroi du colon sigmoïde (étoile verte), une perforation d'un diverticule avec de multiples bulles gazeuses extra-digestives (flèche rouge) et un aspect de péritonite localisée avec un réhaussement marqué du péritoine (flèche rose)." }
            ]
        },
        {
            "id": "4.0.3.6",
            "title": "Suites chirurgicales compliquées",
            "description": "*v#gbu#dig*Scanner abdomino-pelvien injecté en urgence.<br/>\
            Il pourra être couplé à une opacification digestive haute ou basse au besoin.**",
            "pictures": [
                { "img": "4036.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une large plaie vésicale sur vessie radique (étoile rouge) communiquant (flèche rouge) avec la loge de colectomie (étoile bleue). Voir aussi image 2." },
                { "img": "4036.1.jpg", "caption": "Coupe coronale épaisse (présentée en fenêtre osseuse) au temps tardif (urinaire) du même scanner abdomino-pelvien que sur l'image 1, montrant la plaie vésicale sur vessie radique (étoile rouge) communiquant (flèche rouge) avec la loge de colectomie (étoile bleue). Voir aussi image 1." },
                { "img": "4036.2.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé sans injection de produit de contraste, et montrant une volumineuse collection abdominale post-chirurgicale de densité hydrique, plaidant pour une lymphocèle." }
            ]
        },
        //start URONEPHRO 4.1
        //start CN
        {
            "id": "4.1.0",
            "title": "Colique néphrétique",
            "description": "Le patient présente-t-il au moins l'une de ces caractéristiques?<br/>\
            [[Moins de 65 ans]\
            [Fièvre]\
            [Oligo-anurie]\
            [Insuffisance rénale aigue]\
            [Symptômes de plus de 24h ou absence de réponse à un traitement bien mené]\
            [Rein unique]\
            [Doute diagnostique]]",
            "leadsTo": ["4.1.0.0", "4.1.0.1"]
        },
        //start IU
        {
            "id": "4.1.1",
            "title": "Infection urinaire",
            "description": "Quel est le sexe de votre patient?",
            "leadsTo": ["4.1.1.0", "4.1.1.1"]
        },
        {
            "id": "4.1.2",
            "title": "Hématurie",
            "description": "*v#gbu#uro#nephro*Echographie rénale et des voies urinaires en urgence (débrouillage)**<br/>\
            *o#gbu#uro#nephro*Scanner abdomino-pelvien injecté en semi-urgence (examen de référence).**",
            "pictures": [
                { "img": "412.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien au temps portal de l'injection de produit de contraste, montrant un foyer d'infarctus rénal récent (étoile jaune). On note la préservation du cortex corticis (flèche rouge), typique dans un cas d'infarctus rénal." }
            ]
        },
        {
            "id": "4.1.3",
            "title": "Insuffisance rénale aigue",
            "description": "*v#gbu#uro#nephro*Echographie rénale et des voies urinaires en urgence (<24h).**<br/>\
            *o#gbu#uro#nephro*Scanner abdomino-pelvien sans injection en urgence en complément si cause obstructive suspectée, notamment après l'échographie.**",
            "pictures": [
                { "img": "413.jpg", "caption": "Coupe échographique long axe à la sonde convexe du rein gauche, montrant une dilatation du pyélon (étoile rouge) et du fond des calices (flèches rouges). Cette dilatation des cavités pyélocalicielles plaide pour une insuffisance rénale aigue obstructive." },
                { "img": "4101.jpg", "caption": "Coupe oblique d'un scanner abdomino-pelvien sans injection de produit de contraste, montrant l'enclavement d'une lithiase dense (flèche rouge) au sein du tiers proximal de l'uretère gauche, responsable d'une dilatation d'amont des cavités pyélocalicielles (étoile rouge)." }
            ]
        },
        {
            "id": "4.1.4",
            "title": "Traumatisme du système urinaire",
            "description": "",
            "leadsTo": ["4.1.4.0", "4.1.4.1"]
        },
        {
            "id": "4.1.5",
            "title": "Suspicion de globe urinaire",
            "description": "*v#gbu#uro#nephro*Bladderscan au lit ou échographie rénale et des voies urinaires en urgence, si doute clinique.**",
            "pictures": [
                { "img": "415.jpg", "caption": "Coupe échographique en axial sur la vessie, dont le contenu est anéchogène. Celle-ci est en réplétion complète. Une mesure orthogonale peut permettre d'estimer le volume vésical." },
                { "img": "413.jpg", "caption": "Coupe échographique long axe à la sonde convexe, d'un rein gauche montrant une dilatation du pyélon (étoile rouge) et du fond des calices (flèches rouges), secondaire à un volumineux globe urinaire non montré ici." }
            ]
        },
        {
            "id": "4.1.0.0",
            "title": "Aucune caractéristique",
            "description": "*o#gbu#uro#nephro*Echographie rénale et des voies urinaires en semi-urgence (48h).<br/>\
            Scanner abdomino-pelvien sans injection en semi-urgence (48h) à discuter en remplacement.**",
            "pictures": [
                { "img": "4100.jpg", "caption": "Coupe échographique à la sonde convexe, montrant un rein gauche en coupe long axe, avec une dilatation marquée des cavités pyélocalicielles (étoile rouge) et de l'uretère proximal homolatéral. Voir aussi image 2." },
                { "img": "4100.1.jpg", "caption": "Coupe échographique déroulant l'uretère homolatéral et montrant une lithiase (étoile rouge) enclavée dans l'uretère, et responsable de la dilatation d'amont. La flèche rouge montre le cône d'ombre postérieur en arrière de la lithiase. Voir aussi image 1." }
            ]
        },
        {
            "id": "4.1.0.1",
            "title": "Au moins une caractéristique",
            "description": "*v#gbu#uro#nephro*Scanner abdomino-pelvien sans injection en urgence (<24h).<br/>\
            Echographie rénale et des voies urinaires (<24h) si scanner non accessible.**",
            "pictures": [
                { "img": "4101.jpg", "caption": "Coupe oblique d'un scanner abdomino-pelvien sans injection de produit de contraste, montrant l'enclavement d'une lithiase dense (flèche rouge) au sein du tiers proximal de l'uretère gauche, responsable d'une dilatation d'amont des cavités pyélocalicielles (étoile rouge)." }
            ]
        },
        {
            "id": "4.1.1.0",
            "title": "Masculin",
            "description": "*v#gbu#uro#nephro*Echographie rénale et des voies urinaires en urgence.<br/>\
            L'absence de réponse au traitement antibiotique à 72h doit faire répéter l'échographie.**",
        },
        {
            "id": "4.1.1.1",
            "title": "Féminin",
            "description": "Localisation suspectée de l'atteinte infectieuse",
            "leadsTo": ["4.1.1.1.0", "4.1.1.1.1"]
        },
        {
            "id": "4.1.1.1.0",
            "title": "Basse (cystite)",
            "description": "La patiente présente-t-elle au moins un de ces critères ?\
            [[65 ans ou plus ET critères cliniques de fragilité]\
            [Enceinte]\
            [Antécédent urologique]\
            [Immunodépression]]",
            "leadsTo": ["4.1.1.1.0.0", "4.1.1.1.0.1"]
        },
        {
            "id": "4.1.1.1.1",
            "title": "Haute (Pyélonéphrite)",
            "description": "La patiente présente-t-elle au moins un des critères du sepsis sévère?<br/>\
            [[PAS < 90 mmHg ou PAD < 40 mmHg ou PAM < 65 mmHg ou chute tensionnelle > 40 mmHg]\
            [Lactates > 2 mmol/L]\
            [PaO2 < 60 ou SaO2 < 90 % en air ambiant]\
            [Oligo-anurie]\
            [Augmentation de la créatininémie au-delà de 50%]\
            [Thrombopénie (< 100 G/L) ou TP < 50 % ou bilirubinémie > 34 µmol/L]]",
            "leadsTo": ["4.1.1.1.1.0", "4.1.1.1.1.1"]
        },
        {
            "id": "4.1.1.1.0.0",
            "title": "Aucun critère",
            "description": "Cystite simple.<br/>\
            *r#gbu#uro#nephro*Pas d'imagerie indiquée.**",
        },
        {
            "id": "4.1.1.1.0.1",
            "title": "Au moins l'un de ces critères",
            "description": "Cystite sur terrain particulier.<br/>\
            *o#gbu#uro#nephro*Echographie rénale et des voies urinaires en semi-urgence (<48h).**",
        },
        {
            "id": "4.1.1.1.1.0",
            "title": "Aucun critère de sepsis sévère",
            "description": "La patiente présente-t-elle au moins l'une des caractéristiques suivantes ?<br/>\
            [[65 ans ou plus ET critères cliniques de fragilité]\
            [Enceinte]\
            [Antécédent urologique]\
            [Immunodépression]]",
            "leadsTo": ["4.1.1.1.1.0.0", "4.1.1.1.1.0.1"]
        },
        {
            "id": "4.1.1.1.1.1",
            "title": "Au moins un critère de sepsis sévère",
            "description": "*v#gbu#uro#nephro*Scanner injecté en urgence (si la fonction rénale le permet).<br/>\
            A défaut : discuter l'intérêt d'une échographie rénale et des voies urinaires ou d'un scanner sans injection.**",
            "pictures": [
                { "img": "411111.jpg", "caption": "Coupe oblique déroulant le rein gauche, d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, et montrant une fonte purulente du pole supérieur du rein (flèche verte) ainsi qu'un volumineuse collection rétropéritonéale organisée (étoile rouge)." }
            ]
        },
        {
            "id": "4.1.1.1.1.0.0",
            "title": "Aucun des critères cités",
            "description": "Pyélonéphrite aigue simple.<br/>\
            *r#gbu#uro#nephro*Pas d'examen d'imagerie indiqué à la phase aigue.**<br/>\
            *o#gbu#uro#nephro*Echographie rénale et des voies urinaires en l'absence d'amélioration des symptômes après 72h d'antibiothérapie bien conduite.**",
        },
        {
            "id": "4.1.1.1.1.0.1",
            "title": "Au moins un des critères cités",
            "description": "Pyélonéphrite aigue à risque de complication.<br/>\
            *v#gbu#uro#nephro*Scanner abdomino-pelvien injecté en urgence (< 24h).<br/>\
            Si la patiente est enceinte : échographie rénale et des voies urinaires en urgence (<24h).**",
            "pictures": [
                { "img": "4111101.jpg", "caption": "Coupe oblique déroulant le rein droit, d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant des plages triangulaires hypodenses à base périphérique (têtes de flèches rouges), témoignant de plages de néphrite ; et une prise de contraste des parois pyéliques (flèche jaune)." }
            ]
        },
        {
            "id": "4.1.4.0",
            "title": "Traumatisme vésical isolé suspecté",
            "description": "Avis spécialisé en urgence.<br/>\
            *v#gbu#uro*Scanner abdomino-pelvien injecté en urgence.<br/>\
            Une cystographie rétrograde est également possible, en cas d'indisponibilité du scanner.**",
            "pictures": [
                { "img": "4140.jpg", "caption": "Coupe sagittale d'un scanner abdomino-pelvien sans injection réalisé après opacification vésicale (étoile rouge) par du produit de contraste (via la sonde urinaire). Il montre une large plaie vésicale (flèche rouge) et du produit de contraste s'insinuant au sein du péritoine (flèches roses). L'étoile verte marque le dispositif intra-utérin de la patiente." }
            ]
        },
        {
            "id": "4.1.4.1",
            "title": "Traumatisme du haut appareil urinaire suspecté",
            "description": "*v#gbu#uro*Scanner abdomino-pelvien injecté en urgence.<br/>\
            Echographie rénale et des voies urinaires en urgence en cas d'indisponibilité du scanner.**",
            "pictures": [
                { "img": "4141.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un volumineux hémo-rétropéritoine (étoiles rouges), en rapport avec une fracture rénale droite transfixiante de la lèvre rénale antérieure (flèche jaune). La flèche rouge désigne le morceau rénal fracturé, et dont le réhaussement est très peu marqué (car il n'est plus vascularisé)." },
                { "img": "4141.0.jpg", "caption": "Coupe sagittale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une fracture de la lèvre postérieure du rein gauche (flèche rouge) et un hématome de la loge rénale (flèches jaunes)." }
            ]
        },
        //start genitaux
        {
            "id": "4.2.0",
            "title": "Traumatisme des organes génitaux externes",
            "description": "",
            "leadsTo": ["4.2.0.0", "4.2.0.1"]
        },
        {
            "id": "4.2.0.0",
            "title": "Traumatisme de la verge et de l'urètre",
            "description": "Avis urologique en urgence.<br/>\
            En fonction de l'avis urologique, discuter l'intérêt de plusieurs examens:<br/>\
            *v#gbu#uro*Cystographie et urétrographie par cathéter sus-pubien.**<br/>\
            *v#gbu#uro*Urétrographie rétrograde.**<br/>\
            *v#gbu#uro*Echographie des organes génitaux externes.**<br/>\
            *o#gbu#uro*Scanner abdomino-pelvien injecté (dans le cadre du polytraumatisme).**",
            "pictures": [
                { "img": "4200.jpg", "caption": "Coupe échographique transversale sur la verge montrant un hématome de verge (étoile rouge) secondaire à une rupture du corps caverneux droit (flèche rouge)." },
                { "img": "4200.0.jpg", "caption": "Coupe échographique longitudinale déroulant le corps caverneux gauche (noté CCG), et montrant un abcès de verge (A) secondaire à une plaie urétrale (non vue ici)." }
            ]
        },
        {
            "id": "4.2.0.1",
            "title": "Traumatisme du scrotum",
            "description": "Avis urologique en urgence.<br/>\
            *v#gbu#uro*Echographie-doppler du scrotum en urgence.**",
            "pictures": [
                { "img": "4201.jpg", "caption": "Coupe échographique sur le testicule droit, qui apparaît oedématié avec une région antérieure hétérogène (étoiles vertes). A son pourtour, on individualise une hydrocèle marquée (étoiles rouges)." },
                { "img": "4201.0.jpg", "caption": "Coupe échographique sur le testicule gauche dans les suites d'un traumatisme, permettant de découvrir une volumineuse masse paratesticulaire suspecte et une hydrocèle." }
            ]
        },
        {
            "id": "4.2.1",
            "title": "Scrotum aigu / Torsion testiculaire",
            "description": "Avis urologique en urgence.<br/>\
            *o#gbu#uro*Echographie scrotale à réaliser en urgence seulement après avis urologique.**<br/>\
            *r#gbu#uro*En cas de suspicion de torsion testiculaire, l'exploration chirurgicale est licite d'emblée et rien ne doit la retarder, pas même un examen d'imagerie.**<br/>\
            *o#gbu#uro*Dans le cadre des hernies non compliquées, l'échographie scrotale et le scanner pelvien sans injection pourront se faire à distance de l'urgence.**",
            "pictures": [
                { "img": "421.jpg", "caption": "Coupe échographique sur le testicule droit (qui apparaît oedématié). A son pourtour, on individualise une hydrocèle marquée (étoiles rouges). La fenêtre doppler ne prend pas la couleur, témoignant d'une absence de vascularisation testiculaire. Voir aussi image 2." },
                { "img": "421.0.jpg", "caption": "Coupe échographique centrée sur le cordon tordu du testicule droit et montrant un flux doppler de butée (flux très résistif). Voir aussi image 1. Le tout fait évoquer une torsion testiculaire." }
            ]
        },
        //Gyn Obst PostPart
        {
            "id": "4.3.0",
            "title": "Gynécologie",
            "description": "",
            "leadsTo": ["4.3.0.0", "4.3.0.1", "4.3.0.2"]
        },
        {
            "id": "4.3.1",
            "title": "Obstétrique / Post-partum",
            "description": "",
            "leadsTo": ["4.3.1.0", "4.3.1.1", "4.3.1.2"]
        },
        {
            "id": "4.3.0.0",
            "title": "DIU perdu (migration)",
            "description": "",
            "leadsTo": ["4.3.0.0.0", "4.3.0.0.1"]
        },
        {
            "id": "4.3.0.1",
            "title": "Douleurs pelviennes aigues",
            "description": "Avis gynécologique en urgence.<br/>\
            *v#gbu#gyneco*Echographie abdomino-pelvienne et endovaginale en urgence (opérateur entraîné).<br/>\
            Scanner abdomino-pelvien injecté en urgence en seconde intention.**<br/>\
            *o#gbu#gyneco*Si suspicion d'endométriose : bilan complet à réaliser à distance.**",
            "pictures": [
                { "img": "4301.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste iodé, mettant en évidence un abcès tubo-ovarien (étoile verte) ainsi qu'une collection pelvienne organisée (étoile rouge). Sigmoïdite de contact (flèches rouges). Voir aussi images 2 et 3." },
                { "img": "4301.0.jpg", "caption": "Coupe sagittale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste iodé, mettant en évidence un abcès tubo-ovarien (étoile verte) ainsi qu'une collection pelvienne organisée (étoile rouge). Voir aussi images 1 et 3." },
                { "img": "4301.1.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps artériel de l'injection de produit de contraste iodé, mettant en évidence un aspect de péri-hépatite (flèches rouges) devant le réhaussement précoce, marqué et diffus de la capsulte hépatique, en faveur d'un syndrome de Fitz-Hugh-Curtis vu le contexte. Voir aussi images 1 et 2." },
            ]
        },
        {
            "id": "4.3.0.2",
            "title": "Métrorragies",
            "description": "Avis gynécologique en urgence.<br/>\
            *v#gbu#gyneco*Echographie abdomino-pelvienne et endovaginale en urgence (opérateur entraîné).<br/>\
            Scanner abdomino-pelvien injecté en cas de saignement important retentissant sur l'état hémodynamique.**<br/>\
             <br/>\
            En cas de métrorragies de la femme ménopausée:<br/>\
            *o#gbu#gyneco*Echographie abdomino-pelvienne et endovaginale à prévoir hors urgence, dans un délai raisonnable.**",
            "pictures": [
                { "img": "4302.jpg", "caption": "Une échographie réalisée pour bilan de métrorragies chez une femme ménopausée, montrait une hématométrie. L'IRM réalisée en complément la confirme. Sur cette séquence IRM pondérée T2 et dans un plan sagittal sur le pelvis, le corps utérin est le siège d'un volumineux syndrome de masse endométrial (flèche rouge) et l'hématométrie est marquée d'une étoile verte. Voir aussi image 2." },
                { "img": "4302.0.jpg", "caption": "Image montrant une séquence diffusion à b élevée dans le plan axial sur le corps utérin, montrant un syndrome de masse du corps utérin en hypersignal diffusion, envahissant le myomètre de plus de 50 %. Voir aussi image 1." },
            ]
        },
        {
            "id": "4.3.0.0.0",
            "title": "Sans symptômes",
            "description": "Avis spécialisé.<br/>\
            *o#gbu#gyneco*Echographie pelvienne et endovaginale en semi-urgence.<br/>\
            Radiographie (abdomen sans préparation) à discuter en complément.<br/>\
            Scanner abdomino-pelvien injecté en dernier recours ou en pré-chirurgical.**",
            "pictures": [
                { "img": "43000.jpg", "caption": "Radiographie d'Abdomen Sans Préparation (ASP) montrant une très probable migration du dispositif intra-utérin (flèche rouge). Sa place attendue se situe au niveau de l'étoile rouge, qui marque un myome utérin calcifié." },
                { "img": "43000.0.jpg", "caption": "Coupe d'une échographie pelvienne endovaginale, montrant la vacuité du col utérin et de la région proximale de la cavité utérine (flèche rouge). Epanchement liquidien du cul de sac de Douglas marqué d'une étoile rouge." }
            ]
        },
        {
            "id": "4.3.0.0.1",
            "title": "Suspicion de complication",
            "description": "Avis spécialisé.<br/>\
            *o#gbu#gyneco*Scanner abdomino-pelvien injecté à discuter d'emblée.<br/>\
            Sinon : échographie pelvienne et endovaginale en urgence par un opérateur entraîné et radiographie (Abdomen Sans Préparation) à discuter en complément.**",
            "pictures": [
                { "img": "43001.jpg", "caption": "Coupe oblique d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un dispositif intra-utérin (flèche rouge) à distance de la cavité utérine (marquée par une étoile rouge). Il se situe au sein du mésentère, et est responsable d'adhérences des anses digestives, conduisant à un tableau de syndrome occlusif. L'étoile verte montre une réplétion gastrique quasi complète." }
            ]
        },
        //obst
        {
            "id": "4.3.1.0",
            "title": "Grossesse extra-utérine",
            "description": "Avis spécialisé et prise en charge en centre de référence.<br/>\
            *v#gbu#gyneco*Echographie abdomino-pelvienne et endo-vaginale en urgence (opérateur entraîné).**<br/>\
            A confronter au dosage quantitatif plasmatique des bHCG.",
            "pictures": [
                { "img": "4310.jpg", "caption": "Coupes échographiques selon les trois plans sur l'utérus (avec reconstruction de la cavité en trois dimensions en bas à droite), montrant une vacuité parfaite du corps utérin. Voir aussi l'image 2." },
                { "img": "4310.0.jpg", "caption": "Coupe échographique par voie endovaginale montrant un embryon avec activité cardiaque (étoile jaune et mesure) au sein de la portion ampullaire de la trompe droite, avec vésicule vitelline et poche gestationnelle. Voir aussi l'image 1." }
            ]
        },
        {
            "id": "4.3.1.1",
            "title": "Grossesse non viable / avortée",
            "description": "Avis spécialisé.<br/>\
            *v#gbu#gyneco*Echographie endovaginale et obstétricale en urgence (opérateur entraîné) souvent réalisée, même s'il n'y a pas d'indication systématique (dans le cas d'un avortement spontané).**",
        },
        {
            "id": "4.3.1.2",
            "title": "Douleur abdominale aigue",
            "description": "Au cours de la grossesse ou du post-partum?",
            "leadsTo": ["4.3.1.2.0", "4.3.1.2.1"]
        },
        {
            "id": "4.3.1.2.0",
            "title": "Grossesse",
            "description": "Avis spécialisé.<br/>\
            *v#gbu#gyneco*Echographie abdomino-pelvienne (voire endovaginale) en urgence (opérateur entraîné).**<br/>\
            *o#gbu#gyneco*Scanner abdomino-pelvien injecté à discuter en milieu spécialisé.**",
            "pictures": [
                { "img": "4034.jpg", "caption": "Image échographique d'une appendicite aigue, montrant un aspect inflammatoire de l'appendice (marqué par une étoile rouge), avec un franc épaississement de ses parois, un stercolithe en son sein (flèche rouge) et un aspect tr!s inflammatoire de la graisse à son pourtour (étoiles vertes)" },
            ]
        },
        {
            "id": "4.3.1.2.1",
            "title": "Post-partum",
            "description": "Avis spécialisé.<br/>\
            *v#gbu#gyneco*Echographie abdomino-pelvienne (voire endovaginale) en urgence (opérateur entraîné).**<br/>\
            *o#gbu#gyneco*Scanner abdomino-pelvien injecté à discuter en milieu spécialisé.**",
            "pictures": [
                { "img": "4034.jpg", "caption": "Image échographique d'une appendicite aigue, montrant un aspect inflammatoire de l'appendice (marqué par une étoile rouge), avec un franc épaississement de ses parois, un stercolithe en son sein (flèche rouge) et un aspect tr!s inflammatoire de la graisse à son pourtour (étoiles vertes)" },
                { "img": "43121.jpg", "caption": "Coupe sagittale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un globe utérin avec un défect de réhaussement sur la voie d'abord de la césarienne (flèche rouge) et du fond utérin (étoile rouge)." },
            ]
        },
        //start autres 4.4
        {
            "id": "4.4",
            "title": "ABDOMEN CHIRURGICAL SANS ORIENTATION CLINIQUE EVIDENTE",
            "description": "*v#gbu#dig*Scanner abdomino-pelvien indiqué en urgence.**<br/>\
            *o#gbu#dig*Echographie abdomino-pelvienne à discuter dans des cas particuliers.**",
            "pictures": [
                { "img": "44.0.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant des défects de réhaussement des organes abdomino-pelviens atteignant les reins (flèches rouges), le foie (étoiles vertes), la rate (étoile rouge). Le tout s'intègre dans un contexte de bas débit diffus." },
                { "img": "44.1.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps artériel de l'injection de produit de contraste, montrant un volumineux faux-anévrisme (hématome circulant) de l'artère gastro-duodénale (étoile rouge), compliquant à distance une pancréatite aigue. Voir aussi image 3." },
                { "img": "44.2.jpg", "caption": "Image coronale d'artériographie pré-thérapeutique et post-thérapeutique du patient de l'image 2. Sur l'image pré-thérapeutique, le volumineux faux-anévrisme est marqué d'une étoile rouge. Sur l'image post-thérapeutique, le matériel d'embolisation (coils) est marqué de flèches rouges et le faux-anévrisme n'est plus visible, témoignant de l'absence de reperméabilisation. Les cathéters sont marqués de têtes de flèches vertes. Voir aussi image 2." },
            ]
        },
        {
            "id": "4.5",
            "title": "TRAUMATISMES ABDOMINAUX GRAVES",
            "description": "Quel est l'état clinique du patient?",
            "leadsTo": ["4.5.0", "4.5.1"]
        },
        {
            "id": "4.5.0",
            "title": "Hémodynamique stable",
            "description": "*v#gbu#dig#rea*FAST-echo pour bilan sur place ou à l'arrivée au déchocage.<br/>\
            Scanner abdomino-pelvien injecté en urgence.**",
            "pictures": [
                { "img": "450.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien sans injection de produit de contraste, montrant une volumineuse collection spontanément dense, correspondant à un hématome rétropéritonéal (étoile rouge) comprimant le rein homolatéral (flèche rouge). Voir aussi l'image 2." },
                { "img": "450.0.jpg", "caption": "Coupe sagittale d'un scanner abdomino-pelvien au temps portal de l'injection de produit de contraste, montrant l'hématome rétropéritonéal (étoile rouge) secondaire à une fracture de la lèvre antérieure du rein gauche (flèche rouge). Voir aussi l'image 1." },
                { "img": "450.1.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien au temps portal de l'injection de produit de contraste, montrant un oedème diffus des anses de l'intestin grêle (flèches rouges), une importante infiltration mésentérique avec un épanchement de densité paraliquidienne (étoiles vertes). Le tout fait évoquer une désinsertion mésentérique dans le contexte traumatique." }
            ]
        },
        {
            "id": "4.5.1",
            "title": "Hémodynamique instable",
            "description": "*r#gbu#dig#rea*Pas d'imagerie indiquée en urgence (hormis FAST-echo si elle ne retarde pas la prise en charge chirurgicale).**<br/>\
            Prise en charge chirurgicale de sauvetage en extrême urgence.",
            "pictures": [
                { "img": "451.jpg", "caption": "Coupe échographique centrée sur le foie, montrant un épanchement intra-péritonéal péri-hépatique (étoiles rouges)." },
              { "img": "6011.jpg", "caption": "Coupe échographique centrée sur l'espace inter-hépato-rénal (auparavant nommé espace de Morison), montrant un épanchement échogène (étoiles rouges), en faveur d'un volumineux hémopéritoine. Voir aussi image 3." },
                { "img": "6011.0.jpg", "caption": "Coupe échographique centrée en fosse iliaque droite montrant un volumineux épanchement échogène (étoile rouge), en faveur d'un volumineux hémopéritoine. A titre de comparaison, la vessie (siège d'urine anéchogène) est marquée d'une flèche verte. Voir aussi image 2." },
            ]
        },
        //start ascite
        {
            "id": "4.6",
            "title": "ASCITE",
            "description": "*o#gbu#gastro*Echographie abdomino-pelvienne en semi-urgence.<br/>\
            (repérage, bilan étiologique, réalisation de ponction...).**<br/>\
            *r#gbu#gastro*Scanner abdomino-pelvien injecté sans urgence si besoin pour bilan étiologique.**",
            "pictures": [
                { "img": "46.jpg", "caption": "Coupe échographique centrée sous-hépatique, montrant un épanchement intra-péritonéal abondant (étoiles rouges). Les anses digestives flottent au sein de l'épanchement." },
                { "img": "46.0.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une volumineuse ascite (étoiles jaunes) et de la carcinose péritonéale diffuse (flèche rouge). On peut parler de <<gateau omental>> devant cette localisation tumorale." }
            ]
        },
        //start 5 non traumatisme
        {
            "id": "5.0.0",
            "title": "Par région",
            "description": "",
            "leadsTo": ["5.0.0.0", "5.0.0.1", "5.0.0.2"]
        },
        {
            "id": "5.0.1",
            "title": "Infection ostéo-articulaire / Arthropathie microcristalline",
            "description": "",
            "leadsTo": ["5.0.1.0", "5.0.1.1"]
        },
        {
            "id": "5.0.2",
            "title": "Matériel douloureux",
            "description": "*v#gbu#rhumato#ortho*Radiographies orthogonales de la zone douloureuse en urgence.**<br/>\
                *o#gbu#rhumato#ortho*Discuter l'intérêt d'une échographie ciblée complémentaire en semi-urgence.**<br/>\
                *o#gbu#rhumato#ortho*Discuter l'intéret d'un scanner complémentaire en semi-urgence.**",
            "pictures": [
                { "img": "502.jpg", "caption": "Coupe axiale d'un scanner réalisé sur le bassin au temps portal après injection de produit de contraste, et montrant un épanchement liquidien organisé de la loge prothétique droite." },
                { "img": "502.0.jpg", "caption": "Radiographie de la hanche droite de face, montrant une luxation de la prothèse totale de hanche, avec une décoaptation complète du cotyle et de la tête prothétique (étoiles rouges)." }
            ]
        },
        //start 500 par région
        {
            "id": "5.0.0.0",
            "title": "Rachis",
            "description": "Quel étage est atteint selon la clinique ?",
            "leadsTo": ["50.0", "50.1", "50.2"]
        },
        {
            "id": "5.0.0.1",
            "title": "Membre inférieur",
            "description": "",
            "leadsTo": ["51.0", "51.1", "51.2", "51.3"]
        },
        {
            "id": "5.0.0.2",
            "title": "Membre supérieur",
            "description": "",
            "leadsTo": ["52.0", "52.1", "52.2", "52.3"]
        },
        //start rachis
        {
            "id": "50.0",
            "title": "Cervical",
            "description": "",
            "leadsTo": ["50.0.0", "50.0.1", "50.0.2", "50.0.3"]
        },
        {
            "id": "50.1",
            "title": "Dorsal",
            "description": "",
            "leadsTo": ["50.1.0"]
        },
        {
            "id": "50.2",
            "title": "Lombaire",
            "description": "",
            "leadsTo": ["50.2.0", "50.2.1", "50.2.2", "50.2.3"]
        },
        //start cervicalgie atraumatique
        {
            "id": "50.0.0",
            "title": "Cervicalgie atraumatique",
            "description": "Le patient présente-t-il au moins l'un des *o*RED FLAGS** ? <br/>\
                [[Contexte infectieux]\
                [Contexte inflammatoire rhumatismal]\
                [Contexte néoplasique]\
                [Fièvre, fatigue générale, sudations nocturnes, AEG]\
                [Adénopathies associées]\
                [Douleurs rachidiennes inflammatoires]\
                [Immunosuppression, corticothérapie, toxicomanie]]",
            "leadsTo": ["50.0.0.0", "50.0.0.1"]
        },
        {
            "id": "50.0.0.0",
            "title": "Aucun RED FLAG",
            "description": "Le patient présente-t-il au moins l'un des critères suivants?<br/>\
                [[Poussée douloureuse inaugurale]\
                [Douleur rebelle au traitement antalgique bien conduit]\
                [Traitement par manipulation envisagé]]",
            "leadsTo": ["50.0.0.0.0", "50.0.0.0.1"]
        },
        {
            "id": "50.0.0.1",
            "title": "Au moins un RED FLAG",
            "description": "*v#gbu#rhumato*Radiographies cervicales (face, profil, bouche ouverte) à réaliser en urgence.**<br/>\
                *o#gbu#rhumato*L'IRM cervicale est à réaliser en semi-urgence (ou dans un délai raisonnable) en complément du bilan radiographique.**",
                "pictures": [
                { "img": "5003.jpg", "caption": "Radiographie du rachis cervical de face montrant une ostéolyse diffuse de C3 et C4, centrée sur la région gauche, très suspecte. Voir aussi image 2." },
                { "img": "5003.0.jpg", "caption": "Radiographie du rachis cervical de profil montrant une ostéolyse diffuse de C3 et C4, centrée sur la région gauche, très suspecte. Voir aussi image 1." },
                { "img": "50001.jpg", "caption": "IRM cervicale en pondération T1 après injection de produit de contraste, montrant une anomalie de signal focale suspecte du corps de C2 (flèche rouge). Le signal normal de la moelle osseuse est marqué d'une flèche verte." }
            ]
        },
        {
            "id": "50.0.0.0.0",
            "title": "Aucun des critères cités",
            "description": "*r#gbu#rhumato*Aucun examen radiographique ne doit être réalisé en urgence.**",
        },
        {
            "id": "50.0.0.0.1",
            "title": "Au moins un des critères cités",
            "description": "*o#gbu#rhumato*Radiographies cervicales (face, profil, bouche ouverte) à réaliser en semi-urgence.<br/>\
                L'IRM cervicale peut être prescrite en complément du bilan radiographique, sans urgence.**",
            "pictures": [
                { "img": "5002.jpg", "caption": "IRM cervicale en pondération T2, présentée dans le plan axial, et montrant une importante uncarthrose droite, réduisant le foramen de conjugaison  de l'étage intéressé (flèche rouge) comparativement au côte controlatéral (flèche verte)." }
            ]
        },
        //start NCB non déficitaire 50.0.1
        {
            "id": "50.0.1",
            "title": "Névralgie cervico-brachiale non déficitaire",
            "description": "Le patient présente-t-il au moins l'un des *o*RED FLAGS** ? <br/>\
                [[Contexte infectieux]\
                [Contexte inflammatoire rhumatismal]\
                [Contexte néoplasique]\
                [Fièvre, fatigue générale, sudations nocturnes, AEG]\
                [Adénopathies associées]\
                [Douleurs rachidiennes inflammatoires]\
                [Immunosuppression, corticothérapie, toxicomanie]]",
            "leadsTo": ["50.0.1.0", "50.0.1.1"]
        },
        {
            "id": "50.0.1.0",
            "title": "Aucun RED FLAG",
            "description": "Le patient présente-t-il au moins l'un des critères suivants?<br/>\
                [[Poussée douloureuse inaugurale]\
                [Douleur rebelle au traitement antalgique bien conduit]\
                [Traitement par manipulation envisagé]]",
            "leadsTo": ["50.0.1.0.0", "50.0.1.0.1"]
        },
        {
            "id": "50.0.1.1",
            "title": "Au moins un RED FLAG",
            "description": "*v#gbu#rhumato*Radiographies cervicales (face, profil, bouche ouverte, 3/4 droit et gauche) à réaliser en urgence.**<br/>\
                *o#gbu#rhumato*L'IRM cervicale est à réaliser en semi-urgence (ou dans un délai raisonnable) en complément du bilan radiographique**",
            "pictures": [
                { "img": "5003.jpg", "caption": "Radiographie du rachis cervical de face montrant une ostéolyse diffuse de C3 et C4, centrée sur la région gauche, très suspecte. Voir aussi image 2." },
                { "img": "5003.0.jpg", "caption": "Radiographie du rachis cervical de profil montrant une ostéolyse diffuse de C3 et C4, centrée sur la région gauche, très suspecte. Voir aussi image 1." },
                { "img": "50001.jpg", "caption": "IRM cervicale en pondération T1 après injection de produit de contraste, montrant une anomalie de signal focale suspecte du corps de C2 (flèche rouge). Le signal normal de la moelle osseuse est marqué d'une flèche verte."}
            ]
        },
        {
            "id": "50.0.1.0.0",
            "title": "Aucun des critères cités",
            "description": "*r#gbu#rhumato*Aucun examen radiographique ne doit être réalisé en urgence.**",
        },
        {
            "id": "50.0.1.0.1",
            "title": "Au moins un des critères cités",
            "description": "*o#gbu#rhumato*Radiographies cervicales (face, profil, bouche ouverte et 3/4 droit et gauche) à réaliser en semi-urgence.<br/>\
                L'IRM cervicale peut être réalisée en complément, sans urgence.**",
            "pictures": [
                { "img": "5002.jpg", "caption": "IRM cervicale en pondération T2, présentée dans le plan axial, et montrant une importante uncarthrose droite, réduisant le foramen de conjugaison  de l'étage intéressé (flèche rouge) comparativement au côte controlatéral (flèche verte)." }
            ]
        },
        //start NCB déficitaire
        {
            "id": "50.0.2",
            "title": "Névralgie cervico-brachiale déficitaire",
            "description": "Avis spécialisé neurochirurgical.<br/>\
                *v#gbu#rhumato#ortho*IRM cervicale indiquée en urgence (ou scanner sans injection à défaut).**<br/>\
                *o#gbu#rhumato#ortho*Radiographies du rachis cervical (face, profil, 3/4 droit et gauche, bouche ouverte) à discuter en complément.**<br/>\
                Redirection vers Névralgie cervico-brachiale non déficitaire si déficit côté à 4 ou 5 selon l'échelle MRC ou si le patient est récusé de la chirurgie",
            "leadsTo": ["50.0.1"],
            "pictures": [
                { "img": "5002.jpg", "caption": "IRM cervicale en pondération T2, présentée dans le plan axial, et montrant une importante uncarthrose droite, réduisant le foramen de conjugaison  de l'étage intéressé (flèche rouge) comparativement au côte controlatéral (flèche verte)." }
            ]
        },
        //start Fx cervicale atraumatique
        {
            "id": "50.0.3",
            "title": "Fracture cervicale spontanée.",
            "description": "*v#gbu#rhumato#ortho*Radiographies du rachis cervical (face, profil, bouche ouverte) en urgence.**<br/>\
                *o#gbu#rhumato#ortho*IRM cervicale (ou scanner à défaut) en semi-urgence en cas de fracture sur os suspecté pathologique.**<br/>\
                *r#gbu#rhumato#ortho*L'ostéodensitométrie est à réaliser hors urgence.**",
                "pictures": [
                { "img": "5003.jpg", "caption": "Radiographie du rachis cervical de face montrant une ostéolyse diffuse de C3 et C4, centrée sur la région gauche, très suspecte. Voir aussi image 2." },
                { "img": "5003.0.jpg", "caption": "Radiographie du rachis cervical de profil montrant une ostéolyse diffuse de C3 et C4, centrée sur la région gauche, très suspecte. Voir aussi image 1." },
                { "img": "50001.jpg", "caption": "IRM cervicale en pondération T1 après injection de produit de contraste, montrant une anomalie de signal focale suspecte du corps de C2 (flèche rouge). Le signal normal de la moelle osseuse est marqué d'une flèche verte."}
            ]
        },
        //start dorsalgie
        {
            "id": "50.1",
            "title": "Dorsal",
            "description": "*o#gbu#rhumato#ortho*Radiographies du rachis dorsal (face, profil) systématiques en semi-urgence.**<br/>\
                En cas de fracture spontanée : l'ostéodensitométrie est à réaliser hors urgence.",
            "pictures": [
                {"img": "50.1.jpg", "caption": "Radiographie du rachis dorsal de face montrant une lyse osseuse du pédicule gauche de T12 (flèche rouge), avec aspect de vertèbre borgne et de fracture pathologique. Le pédicule d'une vertèbre normale est marqué d'une flèche verte. Voir aussi image 2."},
                {"img": "50.10.jpg", "caption": "Radiographie du rachis dorsal de profil montrant une lyse osseuse diffuse de T12 (flèche rouge) avec fracture pathologique. Une vertèbre normale est marquée d'une flèche verte. Voir aussi image 1."},
                {"img": "50.888.jpg", "caption": "Radiographie du rachis dorsal de face montrant un aspect borgne de T10, en faveur d'une atteinte lytique du pédicule droit (flèche rouge). La vertèbre sous-jacente, qui est normale, est marquée d'une flèche verte."}
            ]
        },
        //start lombaire
        {
            "id": "50.2.0",
            "title": "Lombalgie",
            "description": "Le patient présente-t-il au moins l'un des *o*RED FLAGS** ?<br/>\
                [[Contexte infectieux]\
                [Contexte inflammatoire rhumatismal]\
                [Contexte néoplasique]\
                [Fièvre, fatigue générale, sudations nocturnes, AEG]\
                [Adénopathies associées]\
                [Douleurs rachidiennes inflammatoires]\
                [Immunosuppression, corticothérapie, toxicomanie]]",
            "leadsTo": ["50.2.0.0", "50.2.0.1"]
        },
        {
            "id": "50.2.0.1",
            "title": "Aucun RED FLAG",
            "description": "Le patient présente-t-il au moins l'un des critères suivants?<br/>\
                [[Poussée douloureuse inaugurale]\
                [Douleur rebelle au traitement antalgique bien conduit]\
                [Traitement par manipulation envisagé]]",
            "leadsTo": ["50.2.0.1.0", "50.2.0.1.1"]
        },
        {
            "id": "50.2.0.0",
            "title": "Au moins un RED FLAG",
            "description": "*v#gbu#rhumato*Radiographies lombaires (face et profil voire 3/4 droit et gauche en complément) à réaliser en urgence.**<br/>\
                *o#gbu#rhumato*L'IRM lombaire est à réaliser en semi-urgence (ou dans un délai raisonnable) en complément.**",
            "pictures": [
                { "img": "50200.jpg", "caption": "IRM du rachis lombaire en pondération T1 fat sat après injection de produit de contraste, montrant une anomalie de signal disco-vertébrale en L5-S1, associé à une infiltration des parties molles pré-vertébrales (flèches rouges), en faveur d'une spondylodiscite L5-S1." },
                { "img": "50200.0.jpg", "caption": "Scanner thoraco-abdomino-pelvien réalisé dans un contexte de lombalgies et franche altération de l'état général, présenté en fenêtre osseuse et en coupe sagittale, montrant une condensation osseuse diffuse de l'ensemble des structures osseuses visibles, en faveur d'une infiltration tumorale. Un cancer prostatique est très probable." }
            ]
        },
        {
            "id": "50.2.0.1.0",
            "title": "Aucun des critères cités",
            "description": "*r#gbu#rhumato*Aucun examen radiographique ne doit être réalisé en urgence.**",
        },
        {
            "id": "50.2.0.1.1",
            "title": "Au moins un des critères cités",
            "description": "*o#gbu#rhumato*Radiographies lombaires (face, profil voire 3/4 droit et gauche en complément) à réaliser en semi-urgence.<br/>\
                L'IRM lombaire peut être réalisée en complément, sans urgence.**",
            "pictures": [
                { "img": "1210.1.jpg", "caption": "IRM du rachis lombaire en pondération T2 dans un plan sagittal, montrant une réduction de calibre du canal rachidien secondaire à une volumineuse protrusion discale à l'étage L3-L4. Voir aussi image 2." },
                { "img": "1210.2.jpg", "caption": "IRM du rachis lombaire en pondération T2 dans un plan axial oblique en L3-L4, confirmant une franche réduction de calibre du canal rachidien secondaire à une hernie discale paramédiane gauche (étoile rouge), venant au contact de la racine L4 gauche dans son trajet pré-foraminal (flèche verte). Voir aussi image 1." }
            ]
        },
        //start LS non déficitaire
        {
            "id": "50.2.1",
            "title": "Lombosciatique/Cruralgie non déficitaire",
            "description": "Le patient présente-t-il au moins l'un des *o*RED FLAGS** ? <br/>\
                [[Contexte infectieux]\
                [Contexte inflammatoire rhumatismal]\
                [Contexte néoplasique]\
                [Fièvre, fatigue générale, sudations nocturnes, AEG]\
                [Adénopathies associées]\
                [Douleurs rachidiennes inflammatoires]\
                [Immunosuppression, corticothérapie, toxicomanie]]",
            "leadsTo": ["50.2.1.0", "50.2.1.1"]
        },
        {
            "id": "50.2.1.1",
            "title": "Aucun RED FLAG",
            "description": "Le patient présente-t-il au moins l'un des critères suivants?<br/>\
                [[Poussée douloureuse inaugurale]\
                [Douleur rebelle au traitement antalgique bien conduit]\
                [Douleur persistante au-delà de 6 semaines du début des symptômes]\
                [Traitement par manipulation envisagé]]",
            "leadsTo": ["50.2.1.1.0", "50.2.1.1.1"]
        },
        {
            "id": "50.2.1.0",
            "title": "Au moins un RED FLAG",
            "description": "*v#gbu#rhumato*Radiographies lombaires (face, profil voire 3/4 droit et gauche en complément) à réaliser en urgence.**<br/>\
                *o#gbu#rhumato*L'IRM lombaire est à réaliser en semi-urgence (ou dans un délai raisonnable) en complément.**",
            "pictures": [
                { "img": "50210.jpg", "caption": "IRM du rachis lombaire en pondération STIR dans un plan sagittal, montrant un hypersignal diffus (flèche rouge) des muscles para-vertébraux, en faveur d'une myosite." },
                { "img": "50210.0.jpg", "caption": "Scanner du rachis lombaire (réalisé car l'IRM n'était pas disponible dans un délai raisonnable), montrant une infiltration tumorale de T12 (flèche rouge) en limite de champ, réduisant le calibre du canal rachidien (étoile jaune). Le tout est en faveur d'une épidurite compressive." }
            ]
        },
        {
            "id": "50.2.1.1.0",
            "title": "Aucun des critères cités",
            "description": "*r#gbu#rhumato*Aucun examen radiographique ne doit être réalisé en urgence.**",
        },
        {
            "id": "50.2.1.1.1",
            "title": "Au moins un des critères cités",
            "description": "*o#gbu#rhumato*Radiographies lombaires (face, profil voire 3/4 droit et gauche en complément) à réaliser en semi-urgence.<br/>\
                L'IRM lombaire peut être prescrite en complément, sans urgence.**<br/>\
                En cas de lombosciatique ou cruralgie déficitaire ou hyperalgique: redirection vers Lombosciatique hyperalgique",
            "leadsTo": ["50.2.2"],
            "pictures": [
                { "img": "1210.1.jpg", "caption": "IRM du rachis lombaire en pondération T2 dans un plan sagittal, montrant une réduction de calibre du canal rachidien secondaire à une volumineuse protrusion discale à l'étage L3-L4. Voir aussi image 2." },
                { "img": "1210.2.jpg", "caption": "IRM du rachis lombaire en pondération T2 dans un plan axial oblique en L3-L4, confirmant une franche réduction de calibre du canal rachidien secondaire à une hernie discale paramédiane gauche (étoile rouge), venant au contact de la racine L4 gauche dans son trajet pré-foraminal (flèche verte). Voir aussi image 1." }
            ]
        },
        {
            "id": "50.2.2",
            "title": "Lombosciatique / Cruralgie déficitaire ou hyperalgique",
            "description": "*v#gbu#rhumato*IRM lombaire indiquée en urgence (ou scanner sans injection à défaut).**<br/>\
            *o#gbu#rhumato#ortho*Radiographies du rachis lombaire (face, profil, voire 3/4 droit et gauche en complément) à discuter en complément.**<br/>\
                Redirection vers Lombosciatique / Cruralgie non déficitaire si déficit à 4 ou 5 selon l'échelle mMRC ou si patient récusé de la chirurgie.",
            "leadsTo": ["50.2.1"],
            "pictures": [
                { "img": "1210.1.jpg", "caption": "IRM du rachis lombaire en pondération T2 dans un plan sagittal, montrant une réduction de calibre du canal rachidien secondaire à une volumineuse protrusion discale à l'étage L3-L4. Voir aussi image 2." },
                { "img": "1210.2.jpg", "caption": "IRM du rachis lombaire en pondération T2 dans un plan axial oblique en L3-L4, confirmant une franche réduction de calibre du canal rachidien secondaire à une hernie discale paramédiane gauche (étoile rouge), venant au contact de la racine L4 gauche dans son trajet pré-foraminal (flèche verte). Voir aussi image 1." }
            ]
        },
        {
            "id": "50.2.3",
            "title": "Syndrome médullaire aigu / Queue de cheval",
            "description": "Demander un avis neurochirurgical en urgence.<br/>\
                 *v#gbu#rhumato#ortho*IRM médullaire en urgence.<br/>\
                 Si IRM non disponible, faire un scanner du rachis centré sur la zone suspecte de compression en urgence, possiblement guidé par des radiographies du rachis entier (face et profil).**",
            "pictures": [
                { "img": "5023.jpg", "caption": "IRM en pondération T1 et dans le plan sagittal, montrant une volumineuse hernie discale exclue en L5-S1 (flèche rouge). Elle refoule en arrière et comprime les racines de la queue de cheval. Voir aussi image 2." },
                { "img": "5023.0.jpg", "caption": "IRM en pondération T2 et dans le plan axial, montrant une volumineuse hernie discale exclue (flèche rouge). Elle refoule en arrière et comprime les racines de la queue de cheval. Globe urinaire (étoile verte). Voir aussi image 1." }
            ]
        },
        //start mb inf
        {
            "id": "51.0",
            "title": "Hanche",
            "description": "*o#gbu#rhumato#ortho*Radiographies de la hanche (bassin, puis hanche douloureuse face et profil) en semi-urgence.<br/>\
                En cas d'absence d'argument étiologique évident, discuter l'intérêt d'un scanner ou d'une IRM de la hanche complémentaire, souvent à réaliser hors de l'urgence.**",
            "pictures": [
                { "img": "510.jpg", "caption": "Radiographie de la hanche droite de face, montrant une coxarthrose terminale (flèche rouge), avec un pincement diffus de l'interligne articulaire (prédominant en supéro-externe), une ostéocondensation des lames osseuses sous-chondrales, des ostéophytes." },
                { "img": "510.0.jpg", "caption": "Radiographie du bassin de face montrant un aplatissement de la tête fémorale droite avec un aspect en coquille d'oeuf (flèche rouge), en faveur d'une ostéonécrose de la tête fémorale droite. Remaniements dégénératifs coxo-fémoraux secondaires marqués." }
            ]
        },
        {
            "id": "51.1",
            "title": "Genou",
            "description": "*o#gbu#rhumato#ortho*Radiographies du genou (face et profil) en semi-urgence.<br/>\
                Echographie du genou en semi-urgence par un opérateur entraîné, à discuter en complément.<br/>\
                IRM du genou à réaliser hors urgence dans des cas particuliers (notamment si suspicion d'atteinte méniscale).**",
            "pictures": [
                { "img": "511.jpg", "caption": "Séquence T1 Fat-Sat après injection de produit de contraste Gadoliné d'une IRM du genou droit, montrant une masse synoviale prenant le contraste de façon hétérogène (étoile rouge)." },
                { "img": "511.0.jpg", "caption": "Radiographie de profil d'un genou gauche, montrant de multiples corps étrangers libres ostéochondromateux (flèches rouges), dans les recessus articulaires fémoro-tibiaux." }
            ]
        },
        {
            "id": "51.2",
            "title": "Cheville",
            "description": "*o#gbu#rhumato#ortho*Radiographies de la cheville (face, profil) en semi-urgence.<br/>\
                Echographie de la cheville en semi-urgence par un opérateur entraîné à discuter en complément.<br/>\
                IRM de la cheville à réaliser hors urgence dans des cas particuliers.**",
            "pictures": [
                { "img": "512.jpg", "caption": "IRM de la cheville droite présentée en séquence de soustraction (séquence faisant apparaître en hypersignal les structures prenant le contraste et en hyposignal les structures ne prenant pas le contraste), montrant un processus tissulaire intra-articulaire (étoiles rouges) de l'articulation talo-crurale. Cela est évocateur (avec les autres séquences non montrées ici), d'une synovite villonodulaire." },
                { "img": "512.0.jpg", "caption": "Scanner de la cheville gauche montrant un ostéome ostéoïde fibulaire (flèche rouge) avec une ostéosclérose périphérique marquée (étoiles rouges). A titre de comparaison, la médullaire osseuse normale est marquée d'une étoile verte." }
            ]
        },
        {
            "id": "51.3",
            "title": "Pied",
            "description": "*o#gbu#rhumato#ortho*Radiographies du pied (face et 3/4) en semi-urgence.<br/>\
                Echographie en semi-urgence par un opérateur entraîné à discuter en complément.**<br/>\
                *r#gbu#rhumato#ortho*IRM du pied à réaliser sans urgence dans des cas particuliers.<br/>\
                En cas d'hallux valgus, les radiographies ne sont pas systématiques (seulement à réaliser pour le bilan pré-opératoire).**",
            "pictures": [
                { "img": "513.jpg", "caption": "Coupe échographique dans le plan axial du tendon d'achille (dénommé TA) montrant un épaississement du péri-tendon (flèché) comparativement au côté controlatéral (non montré ici). Le tout peut faire évoquer une péri-tendinite dans un contexte d'achillodynie chronique du sportif." },
            ]
        },
        //start mb sup
        {
            "id": "52.0",
            "title": "Epaule",
            "description": "L'épaule est-elle instable?",
            "leadsTo": ["52.0.0", "52.0.1"]
        },
        {
            "id": "52.1",
            "title": "Coude",
            "description": "*o#gbu#rhumato#ortho*Radiographies du coude (face, profil) en semi-urgence.**<br/>\
                Discuter l'intérêt d'une échographie complémentaire par un opérateur entraîné hors urgence.",
            "pictures": [
                { "img": "522.jpg", "caption": "Radiographie du coude gauche de profil (réalisée dans un contexte non traumatique) mettant en évidence un épanchement intra-articulaire (étoile rouge) avec décollement du liseré graisseux antérieur (flèche rouge)." },
                { "img": "522.0.jpg", "caption": "IRM du coude droit en séquence DPFS (Densité de Proton Fat Sat) (réalisée après l'échographie) mettant en évidence une désorganisation des fibres du tendon conjoint des épicondyliens latéraux (flèche rouge)." }
            ]
        },
        {
            "id": "52.2",
            "title": "Poignet",
            "description": "*o#gbu#rhumato#ortho*Radiographies du poignet (face, profil) en semi-urgence.**<br/>\
                Discuter l'intérêt d'une échographie complémentaire par un opérateur entraîné hors urgence.",
            "pictures": [
                { "img": "521.jpg", "caption": "Arthroscanner du poignet (scanner réalisé après injection de produit de contraste au sein des interlignes articulaires au préalable), montrant une fracture ancienne de l'os scaphoïde, visible sous la forme d'une solution de continuité et d'issue de contraste au sein du foyer fracturaire (flèche rouge)." },
            ]
        },
        {
            "id": "52.0.0",
            "title": "Epaule douloureuse stable",
            "description": "*r#gbu#rhumato#ortho*Pas d'imagerie nécessaire en urgence.**<br/>\
                *v#gbu#rhumato#ortho*Radiographies de l'épaule douloureuse(face 3 rotations et profil si possible, ou à défaut face rotation neutre et profil), en urgence si tableau d'épaule hyperalgique.**<br/>\
                *o#gbu#rhumato#ortho*Dans les autres cas, les radiographies et l'échographie de l'épaule sont à réaliser après au moins 4 semaines d'évolution des douleurs.**",
            "pictures": [
                { "img": "5200.jpg", "caption": "Radiographie de l'épaule droite réalisée en position couchée, montrant une omarthrose excentrée terminale (étoile rouge), avec franche rupture du cintre gléno-huméral. Néo-articulation acromio-capitale marquée d'une flèche rouge. Le non respect du cintre gléno-huméral témoigne d'une rupture étendue et ancienne des tendons constitutifs de la coiffe des rotateurs. Voir aussi image 2." },
                { "img": "5200.0.jpg", "caption": "Radiographie de l'épaule gauche réalisée en position neutre, montrant une omarthrose gauche centrée débutante. Le respect du cintre gléno-huméral (qui est marqué en rouge), témoigne d'une bonne continence des tendons constitutifs de la coiffe des rotateurs. La flèche verte montre un ostéophyte capital inférieur. Voir aussi image 1." }
            ]
        },
        {
            "id": "52.0.1",
            "title": "Epaule douloureuse instable",
            "description": "*o#gbu#rhumato#ortho*Radiographies de l'épaule douloureuse (face 3 rotations et profil) indiquées en semi-urgence**<br/>\
                Discuter l'intérêt d'une échographie complémentaire ou d'une imagerie en coupe sans urgence",
            "pictures": [
                { "img": "5201.jpg", "caption": "Radiographie de l'épaule gauche après réduction chez une patiente âgée connue pour luxation gléno-humérale gauche à répétition. Le cliché montre la persistance d'une luxation gléno-humérale antéro-inférieure avec encoche de Malgaigne (flèche rouge), malgré les tentatives de réduction." },
            ]
        },
            {
            "id": "52.3",
            "title": "Main",
            "description": "*o#gbu#rhumato#ortho*Radiographies de la main ou du rayon symptomatique en semi-urgence**<br/>\
                Discuter l'intérêt d'une échographie complémentaire ou d'une imagerie en coupe sans urgence.",
            "pictures": [
                { "img": "523.jpg", "caption": "Radiographie du pouce droit de profil montrant un oedème des parties molles de la face palmaire (flèche rouge), sans lésion osseuse. A confronter à l'échographie (masse sous-jacente?)." },
                { "img": "52.30.jpg", "caption": "Radiographie de la main droit montrant une infiltration gazeuse des parties molles (flèches roses) à distance d'une plaie par arme blanche, en faveur d'une infection à germe anaérobie." }
            ]
        },
        //start 501 IOA arthropathie infl
        {
            "id": "5.0.1.0",
            "title": "Suspicion d'infection ostéo-articulaire",
            "description": "",
            "leadsTo": ["5.0.1.0.0", "5.0.1.0.1"]
        },
        {
            "id": "5.0.1.1",
            "title": "Suspicion d'arthropathie inflammatoire",
            "description": "Avis spécialisé.<br/>\
                *v#gbu#rhumato#ortho*Radiographies orthogonales en urgence la région lésée.**<br/>\
                *o#gbu#rhumato#ortho*Echographie articulaire à discuter en complément en semi-urgence.**<br/>\
                Une ponction articulaire pourra être réalisée sous guidage échographique.<br/>\
                *r#gbu#rhumato#ortho*Le bilan radiographique complet à visée rhumatologique (notamment syndromique) ne relève pas de l'urgence.**<br/>\
                En cas de suspicion d'infection articulaire : Redirection vers Suspicion d'infection ostéo-articulaire",
            "leadsTo": ["5.0.1.0"],
            "pictures": [
                { "img": "5011.jpg", "caption": "Radiographie de la main gauche de 3/4 montrant une importante atteinte érosive excentrée et sclérotique de l'articulation métacarpo-phalangienne du premier rayon (étoile rouge) avec oedème des parties molles adjacentes (flèche rouge). Le tout est typique d'atteinte goutteuse érosive." },
                { "img": "5011.0.jpg", "caption": "Radiographie du bassin de face montrant des remaniements de la symphyse pubienne (flèche rouge), avec une condensation des lames osseuses sous-chondrales, un aspect irréguliers des surfaces articulaires et des arrachements osseux. Le tout est évocateur de séquelles d'apophysopathie de croissance." }
            ]
        },
        {
            "id": "5.0.1.0.0",
            "title": "Suspicion d'arthrite isolée de cause infectieuse",
            "description": "Avis spécialisé.<br/>\
                *v#gbu#rhumato#ortho*Radiographies orthogonales de la région lésée en urgence.<br/>\
                Echographie de la région lésée en urgence.**<br/>\
                *o#gbu#rhumato#ortho*Discuter l'intérêt d'un scanner injecté complémentaire ou en remplacement de l'échographie, si l'articulation est profonde.<br/>\
                L'IRM peut être utile en semi-urgence en cas de bilan initial non contributif.**",
            "pictures": [
                { "img": "50100.jpg", "caption": "Coupe axiale d'un scanner d'épaule droite réalisé deux minutes après l'injection de produit de contraste iodé, montrant un épanchement intra-articulaire gléno-huméral (flèche rouge) et un volumineux épanchement de la bourse sous-acromio-deltoïdienne (étoiles rouges) dont les parois sont réhaussées (têtes de flèche verte)" }
            ]
        },
        {
            "id": "5.0.1.0.1",
            "title": "Suspicion d'ostéite ou ostéo-arthrite de cause infectieuse",
            "description": "Avis spécialisé.<br/>\
                *v#gbu#rhumato#ortho*Radiographies orthogonales de la région lésée en urgence.**<br/>\
                *o#gbu#rhumato#ortho*IRM de la région lésée (ou scanner injecté à défaut) à discuter en complément et en semi-urgence.<br/>\
                La ponction-biopsie (à but bactériologique) peut être réalisée sous guidage imagerie en semi-urgence.**",
            "pictures": [
                { "img": "50101.jpg", "caption": "Séquence IRM pondérée en T1 Fat Sat après injection de produit de contraste Gadoliné, montrant une prise de contraste du plateau inférieur de la vertèbre L5 et du promontoire sacré (flèches rouges), avec des remaniements des surfaces osseuses. Abcès des parties molles pré-sacrées (tête de flèche verte). Le tout est en faveur d'une spondylodiscite probablement infectieuse." },
                { "img": "50101.0.jpg", "caption": "Coupe dans un plan sagittal, d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste (dans une autre indication), et montrant des remaniements de l'interligne disco-vertébral L1-L2, avec infiltration marquée des parties molles péri-articulaires. Le tout est évocateur de spondylodiscite (probablement infectieuse)." },
                { "img": "50101.1.jpg", "caption": "Coupe axiale d'un scanner du bassin présenté en fenêtre osseuse, montrant un aspect de sacro-iliite droite (flèche rouge). L'articulation sacro-iliaque gauche (qui apparaît normale), est marquée d'une flèche verte." },
            ]
        },
        //start VASCU
        {
            "id": "6.0",
            "title": "Anévrime de l'aorte",
            "description": "Est-il symptomatique ?",
            "leadsTo": ["6.0.0", "6.0.1"]
        },
        {
            "id": "6.1",
            "title": "Traumatisme vasculaire",
            "description": "",
            "leadsTo": ["6.1.0", "6.1.1", "6.1.2"]
        },
        {
            "id": "6.2",
            "title": "Ischémie aigue de membre",
            "description": "LE DIAGNOSTIC POSITIF D'ISCHEMIE AIGUE EST CLINIQUE.<br/>\
            Aucun examen ne doit retarder la prise en charge chirurgicale.<br/>\
            *o#gbu#vascu*Dans certains cas (ischémie bilatérale des membres inférieurs, ischémie subaigue...) et à la demande du chirurgien vasculaire, un angioscanner de l'aorte et des membres inférieurs peut être réalisé, dans un délai à confronter à la clinique.**",
            "pictures": [
                { "img": "62.jpg", "caption": "Reconstruction volumique dans un plan coronal, d'un angioscanner de l'aorte et des membres inférieurs, montrant un défaut d'opacification proximal de l'artère iliaque primitive droite (flèche rouge). A titre de comparaison, l'artère iliaque primitive gauche est correctement opacifiée (flèche verte)." }
            ]
        },
        {
            "id": "6.3",
            "title": "Suspicion de thrombose veineuse profonde",
            "description": "*o#gbu#vascu*Echo-doppler des membres inférieurs en semi-urgence.**<br/>\
            En cas de dyspnée associée à une thrombose veineuse profonde proximale (sus-poplitée), le diagnostic d'embolie pulmonaire est retenu sans réaliser d'angioscanner des artères pulmonaires.<br/>\
            La gravité de l'embolie pulmonaire et sa prise en charge sont fonction de la clinique, du résultat de l'échographie trans-thoracique et du dosage des BNP.",
            "pictures": [
                { "img": "63.jpg", "caption": "Coupe échographique transverse de la veine fémorale commune droite, montrant du matériel échogène en son sein (étoile rouge) et l'absence de compression aux manoeuvres. Voir aussi les autres images." },
                { "img": "63.1.jpg", "caption": "Coupe échographique longitudinale de la veine fémorale commune droite, montrant du matériel échogène en son sein (étoile rouge) mesurant près de 5cm dans la portion explorée. Voir aussi les autres images." }
            ]
        },
        //start anevrisme
        {
            "id": "6.0.0",
            "title": "Asymptomatique",
            "description": "Découverte fortuite le plus souvent.<br/>\
            Avis chirurgical de principe.<br/>\
            *r#gbu#vascu*Bilan sans urgence par angioscanner de l'aorte.**",
            "pictures": [
                { "img": "600.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien au temps artériel de l'injection de produit de contraste, montrant un anévrisme de l'aorte abdominale sous-rénale (dont le grand axe est marqué par la ligne verte), avec un thrombus mural (étoile rouge)." }
            ]
        },
        {
            "id": "6.0.1",
            "title": "Symptomatique",
            "description": "Quel est l'état clinique du patient ?",
            "leadsTo": ["6.0.1.0", "6.0.1.1"]
        },
        {
            "id": "6.0.1.0",
            "title": "Patient stable",
            "description": "*v#gbu#vascu*Angioscanner aortique en urgence.**",
            "pictures": [
                { "img": "6010.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé sans injection, montrant un volumineux anévrisme de l'aorte abdominale sous-rénale (dont le grand axe est marqué par la ligne verte). A son pourtour, épanchement dense de grande abondance correspondant à un hémorétropéritoine (étoiles rouges), signant la rupture de l'anévrisme. Le précédent contrôle de l'anévrisme est visible sur l'image 2." },
                { "img": "600.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien au temps artériel de l'injection de produit de contraste, montrant un anévrisme de l'aorte abdominale sous-rénale (dont le grand axe est marqué par la ligne verte), avec un thrombus mural (étoile rouge). Voir aussi l'image 1." }
            ]
        },
        {
            "id": "6.0.1.1",
            "title": "Patient instable",
            "description": "*v#gbu#vascu*Radiographie thoracique (de débrouillage) en urgence.<br/>\
            Echographie abdominale en urgence.**<br/>\
            Ces examens ne doivent pas retarder une chirurgie de sauvetage.",
            "pictures": [
                { "img": "6011.jpg", "caption": "Coupe échographique centrée sur l'espace inter-hépato-rénal (auparavant nommé espace de Morison), montrant un épanchement échogène (étoiles rouges), en faveur d'un volumineux hémopéritoine. Voir aussi image 2." },
                { "img": "6011.0.jpg", "caption": "Coupe échographique centrée en fosse iliaque droite montrant un volumineux épanchement échogène (étoile rouge), en faveur d'un volumineux hémopéritoine. A titre de comparaison, la vessie (siège d'urine anéchogène) est marquée d'une flèche verte. Voir aussi image 1." },
            ]
        },
        //start trauma vasculaires
        {
            "id": "6.1.0",
            "title": "Cervical",
            "description": "*v#gbu#vascu#neurol*Angioscanner des troncs supra-aortiques en urgence.**",
            "pictures": [
                { "img": "610.jpg", "caption": "Coupe axiale d'un scanner des troncs supra-aortiques au temps artériel de l'injection de produit de contraste, montrant un aspect de dissection de l'artère vertébrale droite (flèche rouge). L'artère vertébrale gauche normale est marquée d'une flèche verte. Voir aussi image 2." },
                { "img": "610.0.jpg", "caption": "Coupe coronale oblique d'un scanner des troncs supra-aortiques au temps artériel de l'injection de produit de contraste (en coupes épaisses), montrant un aspect de dissection de l'artère vertébrale droite (flèche rouge). L'artère vertébrale gauche normale est marquée d'une flèche verte. Voir aussi image 1." }
            ]
        },
        {
            "id": "6.1.1",
            "title": "Thoracique / Abdominal",
            "description": "*v#gbu#vascu*Angioscanner aortique en urgence.**<br/>\
            En pratique, cet examen est souvent réalisé au cours du scanner corps-entier dans le cadre d'un polytraumatisme.",
            "pictures": [
                { "img": "611.jpg", "caption": "Coupe axiale d'un scanner thoracique sans injection montrant un aspect spontanément dense de la paroi gauche de la crosse aortique (flèches rouge), en faveur d'un hématome de paroi de la crosse aortique." },
                { "img": "611.0.jpg", "caption": "Coupe oblique déroulant la crosse de l'aorte, d'un angioscanner aortique réalisé dans un contexte traumatique, montrant une rupture de l'isthme aortique de grade III (flèche rouge)." },
                { "img": "611.1.jpg", "caption": "A : Coupe parasagittale d'un scanner abdomino-pelvien injecté montrant un volumineux hématome du muscle grand droit gauche (étoile rouge). B : Le cathétérisme sélectif de l'artère épigastrique inférieure homolatérale en radiologie inteventionnelle objective l'origine de l'extravasation (flèche rouge). C : Des coils sont mis en place et permettent de tarir le saignement (flèche verte)." }
            ]
        },
        {
            "id": "6.1.2",
            "title": "Membres",
            "description": "*v#gbu#vascu#ortho*Echographie-doppler vasculaire en première intention.**<br/>\
            *o#gbu#vascu#ortho*Angioscanner des membres (inférieurs ou supérieurs) à discuter en fonction de la clinique, après avis spécialisé.**",
            "pictures": [
                { "img": "612.jpg", "caption": "Reconstruction volumique sur la racine du membre inférieur droit, d'un angioscanner réalisé dans un contexte de plaie délabrante du membre inférieur droit, montrant une infiltration athéromateuse diffuse du vaisseau et un anévrisme poplité de découverte fortuite (flèche rouge)."},
                { "img": "612.0.jpg", "caption": "Reconstruction en coupes épaisses déroulant l'artère sous-clavière gauche (flèches vertes) qui apparaît intègre, dans un contexte de plaie délabrante de la racine du membre. L'infiltration aérique profonde des parties molles (flèche rouge) témoigne d'une plaie délabrante."}
            ]
        },
        //start pathologies traumatiques / polytraumatisme
        {
            "id": "7.0",
            "title": "Polytraumatisé",
            "description": "Définition : un patient polytraumatisé est un patient porteur d'une ou plusieurs lésions traumatiques, dont au moins une met en jeu son pronostic vital.",
            "leadsTo": ["7.0.0", "7.0.1"]
        },
        {
            "id": "7.1",
            "title": "Traumatisme thoracique",
            "description": "",
            "leadsTo": ["7.1.0", "7.1.1", "7.1.2"]
        },
        {
            "id": "7.2",
            "title": "Traumatisme abdomino-pelvien / Bassin",
            "description": "",
            "leadsTo": ["7.2.0", "7.2.1"]
        },
        {
            "id": "7.3",
            "title": "Traumatisme du rachis",
            "description": "",
            "leadsTo": ["7.3.0", "7.3.1"]
        },
        {
            "id": "7.4",
            "title": "Traumatisme du membre supérieur",
            "description": "Quel site est lésé cliniquement?",
            "leadsTo": ["7.4.0", "7.4.1", "7.4.2", "7.4.3", "7.4.4"]
        },
        {
            "id": "7.5",
            "title": "Traumatisme du membre inférieur",
            "description": "Quel site est lésé cliniquement ?",
            "leadsTo": ["7.5.0", "7.5.1", "7.5.2", "7.5.3", "7.5.4"]
        },
        {
            "id": "7.6",
            "title": "Fracture de fatigue",
            "description": "*v#gbu#ortho#rhumato*Radiographies (dans des plans orthogonaux, si possible face et profil) de la région lésée en urgence.**<br/>\
            *o#gbu#ortho#rhumato*Echographie de la région lésée en semi-urgence et par un opérateur entraîné, en cas de discordance radio-clinique.<br/>\
            Discuter l'intérêt d'un scanner, une IRM ou une scintigraphie complémentaire en seconde intention, et après avis spécialisé.**",
            "pictures": [
                { "img": "76.jpg", "caption": "Séquence IRM pondérée T1 de l'arrière pied gauche (présentée dans un plan sagittal), montrant de multiples fractures de fatigue (flèches rouges) dans un contexte d'anorexie mentale et de douleur chronique de l'arrière pied." }
            ]
        },
        {
            "id": "7.7",
            "title": "Corps étranger des parties molles",
            "description": "*v#gbu#ortho*Radiographies des parties molles en urgence.**<br/>\
            *o#gbu#ortho*Echographie des parties molles en urgence en cas de radiographie non diagnostique.**<br/>\
            Exploration au bloc opératoire en cas de doute persistant après bilan à l'imagerie.",
            "pictures": [
                { "img": "77.jpg", "caption": "Radiographie du pied gauche de 3/4, montrant un corps étranger radio-opaque des parties molles (flèche rouge), correspondant à une épine d'oursin." },
                { "img": "77.0.jpg", "caption": "Radiographie du pied droit de profil, montrant un corps étranger metallique des parties molles (flèche rouge), correspondant à un clou." }
            ]
        },
        //start polytraumatisé
        {
            "id": "7.0.0",
            "title": "Patient stable",
            "description": "*v#gbu#ortho#rea*Body-scanner injecté en urgence.**",
            "pictures": [
                { "img": "700.1.jpg", "caption": "Dans les suites d'un accident de la voie publique à haute cinétique, le scanner injecté met en évidence une fracture splénique et un hématome périplénique (flèche rouge sur l'image A présentée en vue coronale). Les images B et C issues de l'artériographie montrent respectivement l'aspect pré-thérapeutique et l'aspect post-thérapeutique (occlusion tronculaire de l'artère splénique par plug (flèche verte)). Il persiste une circulation splénique à bas débit via des anastomoses pancréatiques (flèche bleue), ce qui est souhaité. Le scanner injecté réalisé à distance (image D) montre la résorption de l'hématome périsplénique et la cicatrisation partielle du parenchyme splénique." },
                { "img": "700.jpg", "caption": "Coupe sagittale oblique (déroulant l'aorte), d'un scanner thoraco-abdomino-pelvien réalisé au temps artériel de l'injection de contraste, et montrant une rupture traumatique de l'isthme aortique (flèche rouge). Il s'associe un hémopéricarde (non visible ici)." },
                { "img": "700.0.jpg", "caption": "Coupe sagittale dans le plan du sternum d'un scanner thoracique injecté, présentée en fenêtre osseuse, montrant une fracture déplacée au moins bifocale du corps sternal (flèche rouge)." }
            ]
        },
        {
            "id": "7.0.1",
            "title": "Patient instable",
            "description": "*v#gbu#ortho#rea*Radiographie du thorax en urgence.<br/>\
            Radiographie du bassin en urgence.<br/>\
            FAST échographie en urgence**.<br/>\
            Ces imageries ne doivent pas retarder une prise en charge réanimatoire ou chirurgicale de sauvetage.<br/>\
            *o#gbu#ortho#rea*Après stabilisation de l'état clinique, la réalisation d'un body-scanner peut être discuté.**",
            "pictures": [
                { "img": "7101.jpg", "caption": "Radiographie du thorax de face réalisée en position assise, montrant un épanchement pleural droit unilatéral, suspect d'hémothorax dans le contexte traumatique." },
                { "img": "6011.jpg", "caption": "Coupe échographique centrée sur l'espace inter-hépato-rénal (auparavant nommé espace de Morison), montrant un épanchement échogène (étoiles rouges), en faveur d'un volumineux hémopéritoine." },
                { "img": "701000.jpg", "caption": "Patient adressé dans les suites d'un traumatisme thoracique. A : Hémothorax droit complet (étoile rouge). B : Extravasation de produit de contraste de la 11ème artère intercostale droite sur l'angioscanner réalisé (flèche rouge). C : Cathétérisme sélectif de celle-ci en radiologie interventionnelle, montrant l'origine du saignement actif (flèche rouge). D : Aspect après mise en place de coils (flèches vertes) en amont et en aval du site du saignement actif. Le saignement actif a été tari." },
            ]
        },
        //start trauma thorax
        {
            "id": "7.1.0",
            "title": "Traumatisme majeur du thorax",
            "description": "Quel est l'état clinique du patient?",
            "leadsTo": ["7.1.0.0", "7.1.0.1"]
        },
        {
            "id": "7.1.1",
            "title": "Traumatisme thoracique mineur",
            "description": "*v#gbu#ortho#rea*Radiographie thoracique en urgence.**<br/>\
            *o#gbu#ortho#rea*Elle pourra être complétée par un scanner thoracique en cas d'anomalie, de doute sur une lésion ou de discordance radio-clinique.**",
            "pictures": [
                { "img": "711.jpg", "caption": "Coupe axiale d'un scanner du thorax réalisé sans injection et présenté en fenêtre osseuse, montrant une fracture de l'arc moyen de la 8ème côte droite. Voir aussi image 2." },
                { "img": "711.0.jpg", "caption": "Coupe axiale d'un scanner du thorax réalisé sans injection et présenté en fenêtre parenchymateuse mettant en évidence un pneumothorax (flèche rouge), secondaire à la fracture costale montrée sur l'image 1." }
            ]
        },
        {
            "id": "7.1.2",
            "title": "Traumatisme costal",
            "description": "*v#gbu#ortho#rea*Radiographie thoracique de face en urgence.**<br/>\
            *r#gbu#ortho#rea*Attention : les clichés du gril costal ne sont pas recommandés en première intention.<br/>\
            Ils sont à réaliser en complément, en cas d'anomalie sur la radiographie thoracique.**",
            "pictures": [
                { "img": "RPN.jpg", "caption": "Radiographie pulmonaire debout de face normale." }
            ]
        },
        {
            "id": "7.1.0.0",
            "title": "Patient stable",
            "description": "*v#gbu#ortho#rea*Scanner thoracique injecté en urgence (souvent réalisé dans le cadre du polytraumatisme).**",
            "pictures": [
                { "img": "611.jpg", "caption": "Coupe axiale d'un scanner thoracique sans injection montrant un aspect spontanément dense de la paroi gauche de la crosse aortique (flèches rouge), en faveur d'un hématome de paroi de la crosse aortique." },
                { "img": "611.0.jpg", "caption": "Coupe axiale d'un scanner thoracique présenté en fenêtre osseuse, montrant une fracture de l'arc moyen de la 8ème côte droite (flèche verte)." },
                { "img": "71111.jpg", "caption": "Coupe axiale d'un scanner thoracique injecté (temps artériel), montrant un volumineux hémothorax droit (étoile rouge), ainsi qu'un emphysème sous-cutané (têtes de flèche roses) et un objet métallique (balle) fiché dans l'hémithorax homolatéral (flèche verte)." },
            ]
        },
        {
            "id": "7.1.0.1",
            "title": "Patient instable",
            "description": "*v#gbu#ortho#rea*Radiographie du thorax en urgence.<br/>\
            Echographie thoracique en urgence (FAST Echo).**<br/>\
            *o#gbu#ortho#rea*En cas de stabilisation de l'état clinique, la réalisation d'un scanner thoracique injecté peut être discutée.**",
            "pictures": [
                { "img": "7101.jpg", "caption": "Radiographie du thorax de face réalisée en position assise, montrant un épanchement pleural droit unilatéral, suspect d'hémothorax dans le contexte traumatique." },
                { "img": "451.jpg", "caption": "Coupe échographique centrée sur le foie, montrant un épanchement intra-péritonéal péri-hépatique (étoiles rouges)." }
            ]
        },
        //start trauma abdo et bassin
        {
            "id": "7.2.0",
            "title": "Traumatisme isolé du bassin",
            "description": "L'urgence est à l'antalgie et à l'immobilisation du site lésé, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho#dig*Radiographie du bassin (face) et des hanches (face,profil) en urgence.**<br/>\
            *o#gbu#ortho#dig*Un scanner du bassin complémentaire en urgence ou semi-urgence peut être proposé, notamment en cas de discordance radio-clinique.**",
            "pictures": [
                { "img": "720.jpg", "caption": "Radiographie du bassin réalisée en position allongée, montrant une fracture déplacée et complexe du massif trochantérien du fémur gauche (flèche rouge)." },
                { "img": "720.0.jpg", "caption": "Radiographie de la hanche droite, montrant une luxation de prothèse totale de hanche droite, avec vacuité du cotyle prothétique (étoile rouge) et perte de congruence complète de la tête prothétique (flèche rouge)." },
                { "img": "720.1.jpg", "caption": "Reconstruction volumique d'un scanner abdomino-pelvien sans injection montrant un fracs complexe du bassin. Ces lésions sont à haut risque hémorragique et le scanner a été complété par une injection de produit de contraste Iodé." }
            ]
        },
        {
            "id": "7.2.1",
            "title": "Traumatisme majeur de l'abdomen et/ou du bassin",
            "description": "Quel est l'état clinique du patient?",
            "leadsTo": ["7.2.1.0", "7.2.1.1"]
        },
        {
            "id": "7.2.1.0",
            "title": "Patient stable",
            "description": "*v#gbu#ortho#dig*Scanner abdomino-pelvien injecté en urgence.**",
            "pictures": [
                { "img": "7210.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une fracture pancréatique (étoile rouge) quasi transfixiante, associée à un hématome en regard du site fracturaire (flèche rouge). Les têtes de flèches vertes soulignent le pancréas." },
                { "img": "7210.0.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant une fracture hépatique transfixiante (flèche rouge), associée à un hémopéritoine abondant (étoiles rouges)." },
                  { "img": "7210.1.jpg", "caption": "Coupe axiale d'un scanner abdomino-pelvien réalisé au temps portal de l'injection de produit de contraste, montrant un pneumopéritoine (flèches rouges), une infiltration hématique du mésentère (étoiles vertes) et un oedème grêlique diffus (têtes de flèche roses). Le tout évoque une désinsertion mésentérique." }
            ]
        },
        {
            "id": "7.2.1.1",
            "title": "Patient instable",
            "description": "*v#gbu#ortho#dig*Radiographie du bassin et du thorax en urgence.<br/>\
            FAST echo en urgence.**<br/>\
            Ces examens ne doivent pas retarder une prise en charge chirurgicale de sauvetage.<br/>\
            <br/>\
            *o#gbu#ortho#dig*En cas de stabilisation de l'état clinique, la réalisation d'un scanner abdomino-pelvien injecté peut être discutée.**",
            "pictures": [
                { "img": "6011.jpg", "caption": "Coupe échographique centrée sur l'espace inter-hépato-rénal (auparavant nommé espace de Morison), montrant un épanchement échogène (étoiles rouges), en faveur d'un volumineux hémopéritoine. Voir aussi image 2." },
                { "img": "6011.0.jpg", "caption": "Coupe échographique centrée en fosse iliaque droite montrant un volumineux épanchement échogène (étoile rouge), en faveur d'un volumineux hémopéritoine. A titre de comparaison, la vessie (siège d'urine anéchogène) est marquée d'une flèche verte. Voir aussi image 1." },
                { "img": "7211.22.jpg", "caption": "Après stabilisation de l'état clinique d'un patient qui a subi un écrasement du bassin, un angioscanner est réalisé et montre un saignement actif abondant (flèches blanches). A et B représentent respectivement une vue axiale native et une reconstruction volumique du scanner. L'artériographie réalisée ensuite (C) retrouve un saignement actif de l'artère glutéale supérieure droite, branche de l'artère iliaque interne (flèches). L'image D montre l'aspect après embolisation réversible (gélatine) de l'artère iliaque interne droite."},
            ]
        },
        //start rachis
        {
            "id": "7.3.0",
            "title": "Rachis cervical",
            "description": "",
            "leadsTo": ["7.3.0.0", "7.3.0.1", "7.3.0.2", "7.3.0.3"]
        },
        {
            "id": "7.3.1",
            "title": "Rachis dorso-lombaire",
            "description": "",
            "leadsTo": ["7.3.1.0", "7.3.1.1", "7.3.1.2"]
        },
        //Start cervical
        {
            "id": "7.3.0.0",
            "title": "Traumatisme bénin",
            "description": "Le patient présente-t-il l'ensemble des critères NEXUS ?<br/>\
            [[Pas de douleur à la palpation des épineuses]\
            [Pas de déficit neurologique focal]\
            [Etat de veille normal (GCS 15)]\
            [Pas d'intoxication]\
            [Pas d'autre lésion douloureuse qui pourrait masquer la douleur cervicale]]",
            "leadsTo": ["7.3.0.0.0", "7.3.0.0.1"]
        },
        {
            "id": "7.3.0.0.0",
            "title": "TOUS les critères NEXUS sont remplis",
            "description": "*r*Pas de radiographie cervicale nécessaire en urgence.**<br/>\
            En cas de persistance de douleur à 10 jours du traumatisme.",
            "leadsTo": ["7.3.0.3"]
        },
        {
            "id": "7.3.0.0.1",
            "title": "Au moins un des critères NEXUS n'est pas rempli",
            "description": "*v#gbu#ortho*Radiographies du rachis cervical (face, profil et bouche ouverte) en urgence.**<br/>\
            *o#gbu#ortho*En cas de discordance radio-clinique ou de doute sur les radiographies, discuter l'intérêt d'un scanner cervical complémentaire.**<br/>\
            En cas de persistance de douleur à 10 jours du traumatisme.",
            "pictures": [
                { "img": "73001.jpg", "caption": "Radiographie du rachis cervical de face et de bonne qualité, ne montrant pas de lésion post-traumatique. Voir aussi image 2." },
                { "img": "73001.0.jpg", "caption": "Radiographie du rachis cervical de profil et de bonne qualité, ne montrant pas de lésion post-traumatique. Voir aussi image 1." }
            ],
            "leadsTo": ["7.3.0.3"]
        },
        {
            "id": "7.3.0.1",
            "title": "Traumatisme sévère sans trouble neurologique",
            "description": "*v#gbu#ortho*Scanner cervical sans injection en urgence.**<br/>\
            *o#gbu#ortho*A 10 jours du traumatisme : clichés radiographiques statiques et dynamiques systématiques.**<br/>\
            *o#gbu#ortho*IRM cervicale en semi-urgence si suspicion d'entorse cervicale grave (anomalies sur les clichés statiques ou dynamiques au dixième jour).**",
            "pictures": [
                { "img": "7301.jpg", "caption": "Coupe sagittale en fenêtre osseuse d'un scanner du rachis cervical sans injection, montrant une fracture de la base de l'odontoïde (flèche rouge). Les vertèbres cervicales sont nommées en rouge." },
                { "img": "7301.0.jpg", "caption": "Coupe sagittale en fenêtre osseuse d'un scanner du rachis cervical sans injection, montrant une fracture-luxation C2-C3 (flèche rouge), avec issue de fragments osseux dans le canal rachidien (étoile verte). Les vertèbres sont marquées en rouge." }
            ]
        },
        {
            "id": "7.3.0.2",
            "title": "Traumatisme avec trouble neurologique",
            "description": "Avis spécialisé.<br/>\
            *v#gbu#ortho#neurol#neuroch*Scanner cervical sans injection en urgence.**<br/>\
            *o#gbu#ortho#neurol#neuroch*IRM cervicale en urgence en cas de scanner normal et/ou pour rechercher une compression médullaire.**<br/>\
            *r#gbu#neurol#ortho#neuroch*Dans le cas d'un traumatisme cervical avec trouble neurologique secondaire, les radiographies du rachis ne doivent pas être réalisées.**",
            "pictures": [
                { "img": "7304.jpg", "caption": "Séquence IRM T2 dans un plan sagittal d'un rachis cervical traumatisé, montrant un hématome épidural postérieur (flèches rouges) venant comprimer la moelle cervicale. Anomalie de signal discale (tête de flèche verte) en C6-C7 évocatrice de fracture trans-discale dans le contexte." },
                { "img": "7301.0.jpg", "caption": "Coupe sagittale en fenêtre osseuse d'un scanner du rachis cervical sans injection, montrant une fracture-luxation C2-C3 (flèche rouge), avec issue de fragments osseux dans le canal rachidien (étoile verte). Les vertèbres sont marquées en rouge." }
            ]
        },
        {
            "id": "7.3.0.3",
            "title": "Cervicalgies post-traumatiques à bilan initial normal",
            "description": "*o#gbu#ortho*Réaliser des radiographies cervicales statiques de face et de profil à 10 jours du traumatisme.<br/>\
            Elles seront complétées par des clichés dynamiques de profil sous contrôle scopique.<br/>\
            IRM cervicale à réaliser en semi-urgence après les clichés dynamiques, en cas de négativité du bilan radiographique et de douleurs persistantes.**",
        },
        //start rachis dorsolombaire
        {
            "id": "7.3.1.0",
            "title": "Traumatisme bénin",
            "description": "L'urgence est à l'antalgie et à l'immobilisation du patient, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies du rachis thoraco-lombaire (face, profil) en urgence.**<br/>\
            *o#gbu#ortho*Elles seront complétées par un scanner sans injection centré sur la zone douloureuse en cas de discordance radio-clinique ou de doute sur une lésion osseuse.**",
            "pictures": [
                { "img": "7310.jpg", "caption": "Radiographie du rachis lombaire de face (avec les vertèbres numérotées en rouge), ne montrant pas de lésion post-traumatique. Voir aussi image 2." },
                { "img": "7310.0.jpg", "caption": "Radiographie du rachis lombaire de profil (avec les vertèbres nommées en rouge), ne montrant pas de lésion post-traumatique. Voir aussi image 1." }
            ]
        },
        {
            "id": "7.3.1.1",
            "title": "Traumatisme sévère sans trouble neurologique",
            "description": "*v#gbu#ortho*Scanner du rachis thoraco-lombaire sans injection d'emblée.**<br/>\
            *o#gbu#ortho*L'IRM du rachis thoraco-lombaire sera réalisée en complément si suspicion de lésion disco-ligamentaire ou en cas de discordance entre les examens réalisés et la clinique.**<br/>\
            *r#gbu#ortho*Les radiographies ne sont pas indiquées dans ce cas.**",
            "pictures": [
                { "img": "7311.jpg", "caption": "Coupe sagittale d'un scanner du rachis lombaire sans injection de produit de contraste (présenté en fenêtre osseuse) montrant de multiples hernies intra-spongieuses séquellaires de dystrophie de croissance (flèches rouges), à ne pas méconnaître, afin de ne pas les confondre avec des lésions post-traumatiques." },
                { "img": "7311.0.jpg", "caption": "Coupe sagittale d'un scanner du rachis dorso-lombaire sans injection de produit de contraste et centré sur la vertèbre T10 (présenté en fenêtre osseuse), mettant en évidence une fracture d'allure ostéoporotique ancienne de T10 (étoile rouge), avec une perte de hauteur du corps vertébral et un bombement de sa région postérieure vers le canal rachidien, dont le calibre antéro-postérieur semble bien conservé." }
            ]
        },
        {
            "id": "7.3.1.2",
            "title": "Traumatisme avec trouble neurologique",
            "description": "Avis spécialisé.<br/>\
            *v#gbu#neurol#neuroch#ortho*Scanner du rachis thoraco-lombaire sans injection d'emblée.**<br/>\
            *o#gbu#neurol#neuroch#ortho*L'IRM du rachis thoraco-lombaire sera réalisée en complément et en urgence si le scanner est normal.**<br/>\
            *r#gbu#neurol#neuroch#ortho*Les radiographies ne sont pas indiquées dans ce cas.**",
            "pictures": [
                { "img": "7312.jpg", "caption": "Coupe sagittale oblique en fenêtre osseuse, d'un scanner thoraco-abdomino-pelvien injecté réalisé dans un contexte de polytraumatisme, montrant une fracture-luxation complexe des vertèbres T10 et T11 (flèche rouge), avec franche réduction de calibre du canal rachidien." }
            ]
        },
        //start membre sup
        {
            "id": "7.4.0",
            "title": "Epaule-Clavicule",
            "description": "L'urgence est à l'antalgie, à la réduction des déformations et à l'immobilisation du membre lésé, \
            et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies orthogonales (si possible face et profil) de l'épaule lésée en urgence.<br/>\
            Une incidence claviculaire spécifique peut-être réalisée en complément.**<br/>\
            *o#gbu#ortho*Scanner de l'épaule à discuter en complément en cas de doute à la radiographie, de discordance radio-clinique, \
            ou pour le bilan pré-opératoire à la demande du chirurgien.**",
            "pictures": [
                { "img": "740.jpg", "caption": "Radiographie selon une incidence claviculaire de face, montrant une fracture déplacée (avec les deux berges fracturaires marquées par des étoiles rouges) de la clavicule gauche et une saillie du fragment osseux le plus proximal (flèche rouge) vers les parties molles, risquant de léser ces dernières." },
                 { "img": "741.jpg", "caption": "Radiographie de l'humérus gauche de face montrant une fracture articulaire complexe de son extrémité supérieure, avec plusieurs foyers fracturaires marqués par des têtes de flèches vertes." },
                
            ]
        },
        {
            "id": "7.4.1",
            "title": "Humérus",
            "description": "L'urgence est à l'antalgie, à la réduction des déformations et à l'immobilisation du membre lésé, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies orthogonales (si possible face et profil) de l'humérus en urgence.**<br/>\
            *o#gbu#ortho*Scanner de l'humérus à discuter en complément en cas de doute à la radiographie, de discordance radio-clinique, ou pour le bilan pré-opératoire à la demande du chirurgien.**",
            "pictures": [
                { "img": "740.0.jpg", "caption": "Radiographie humérale gauche de face, montrant un foyer de fracture récent (flèche rouge) sur un foyer de fracture ancien avec un cal osseux en cours d'ossification. Les appositions périostées (témoignant du processus de consolidation active) sont marquées par la tête de flèche verte." },
                { "img": "741.0.jpg", "caption": "Radiographie de l'humérus gauche de face (chez une enfant) réalisée dans les suites d'un traumatisme mineur. Cette radiographie montre une fracture diaphysaire sur os pathologique (condensation osseuse, aspect en feu d'herbe, apositions périostées)." }
            ]
        },
        {
            "id": "7.4.2",
            "title": "Coude",
            "description": "L'urgence est à l'antalgie, à la réduction des déformations et à l'immobilisation du membre lésé, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies orthogonales (si possible face et profil) du coude lésé en urgence.**<br/>\
            *o#gbu#ortho*Scanner du coude à discuter en complément en cas de doute à la radiographie, de discordance radio-clinique, ou pour le bilan pré-opératoire à la demande du chirurgien.**",
            "pictures": [
                { "img": "742.jpg", "caption": "Radiographie du coude droit montrant une fracture complexe, articulaire et déplacée, de la palette humérale et de l'olécrâne (étoile rouge). La résine d'immobilisation radio-transparente est soulignée de flèches rouges." },
                { "img": "742.0.jpg", "caption": "Radiographie du coude gauche montrant un épanchement hématique articulaire (étoile rouge) déplaçant le liseré graisseux péri-articulaire normalement accolé à l'os (flèche rouge). Cet épanchement sentinelle permet de révéler une fracture engrenée de l'extrémité supérieure du radius (flèche verte), très peu visible." }
            ]
        },
        {
            "id": "7.4.3",
            "title": "Radius - Ulna",
            "description": "L'urgence est à l'antalgie, à la réduction des déformations et à l'immobilisation du membre lésé, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies orthogonales (si possible face et profil) de l'avant-bras lésé en urgence.**<br/>\
            *o#gbu#ortho*Scanner de l'avant-bras à discuter en complément en cas de doute à la radiographie, de discordance radio-clinique, ou pour le bilan pré-opératoire à la demande du chirurgien.**",
            "pictures": [
                { "img": "743.jpg", "caption": "Radiographie du poignet gauche de profil montrant une fracture déplacée en antérieur de l'extrémité inférieure du radius (flèche rouge). Voir aussi image 2." },
                { "img": "743.0.jpg", "caption": "Radiographie du poignet gauche de face montrant une fracture déplacée en latéral de l'extrémité inférieure du radius (flèche rouge). Voir aussi image 1." },
            ]
        },
        {
            "id": "7.4.4",
            "title": "Poignet - Main",
            "description": "L'urgence est à l'antalgie, à la réduction des déformations et à l'immobilisation du membre lésé, \
            et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies orthogonales (si possible face et profil) du site lésé (poignet et/ou main) en urgence.<br/>\
            En cas de traumatisme d'un rayon de la main, des incidences centrées sur le rayon sont à demander spécifiquement.**<br/>\
            *o#gbu#ortho*Scanner du poignet (et/ou de la main) à discuter en complément en cas de doute à la radiographie, \
            de discordance radio-clinique, ou pour le bilan pré-opératoire à la demande du chirurgien.**",
            "pictures": [
                { "img": "744.jpg", "caption": "Radiographie du poignet gauche en inclinaison ulnaire pour dégager l'os scaphoïde, montrant une fracture monofocale de l'os scaphoïde (flèches rouges)." },
                { "img": "744.0.jpg", "caption": "Radiographie du poignet gauche de profil montrant une solution de continuité sur la face dorsale d'un os de la première rangée du carpe (flèche rouge). Un scanner réalisé en complément montre qu'il s'agit de la face dorsale de l'os Triquetrum." }
            ]
        },
        //start membre inf
        {
            "id": "7.5.0",
            "title": "Hanche",
            "description": "L'urgence est à l'antalgie, à la réduction des déformations et à l'immobilisation du membre lésé, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies du bassin de face, et de la hanche lésée de profil, en urgence.**<br/>\
            *o#gbu#ortho*Scanner du bassin en complément en cas de doute à la radiographie ou de discordance radio-clinique.**",
            "pictures": [
                { "img": "720.jpg", "caption": "Radiographie du bassin réalisée en position allongée, montrant une fracture déplacée et complexe du massif trochantérien du fémur gauche (flèche rouge)." },
                { "img": "720.0.jpg", "caption": "Radiographie de la hanche droite, montrant une luxation de prothèse totale de hanche droite, avec vacuité du cotyle prothétique (étoile rouge) et perte de congruence complète de la tête prothétique (flèche rouge)." },
                { "img": "720.1.jpg", "caption": "Reconstruction volumique d'un scanner abdomino-pelvien sans injection montrant un fracs complexe du bassin. Ces lésions sont à haut risque hémorragique et le scanner a été complété par une injection de produit de contraste Iodé." }
            ]
        },
        {
            "id": "7.5.1",
            "title": "Fémur",
            "description": "L'urgence est à l'antalgie, à la réduction des déformations et à l'immobilisation du membre lésé, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies orthogonales du fémur (si possible face et profil), en urgence.**<br/>\
            *o#gbu#ortho*Scanner du fémur (incluant les articulations sus et sous-jacentes) en complément en cas de doute à la radiographie, de discordance radio-clinique ou avant prise en charge chirurgicale à la demande du chirurgien.**",
            "pictures": [
                { "img": "751.jpg", "caption": "Radiographie du bassin de face réalisée en position couchée, montrant une fracture pertrochantérienne déplacée de l'extrémité supérieure du fémur gauche." },
                { "img": "751.0.jpg", "caption": "Radiographie du bassin de face réalisée en position couchée, montrant une fracture du fond cotyloïdien droit sous la forme d'une discrète solution de continuité (flèche rouge)." }
            ]
        },
        {
            "id": "7.5.2",
            "title": "Genou",
            "description": "Le patient présente-t-il au moins l'un de ces critères ?<br/>\
            [[55 an ou plus]\
            [Douleur isolée de la rotule]\
            [Douleur de la tête de la fibula]\
            [Impossibilité de fléchir le genou de 90°]\
            [Impossibilité de faire 4 pas immédiatement après le traumatisme ET aux urgences.]]",
            "leadsTo": ["7.5.2.0", "7.5.2.1"]
        },
        {
            "id": "7.5.3",
            "title": "Tibia - Fibula",
            "description": "L'urgence est à l'antalgie, à la réduction des déformations et à l'immobilisation du membre lésé, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies orthogonales du tibia et de la fibula (si possible face et profil), en urgence.**<br/>\
            *o#gbu#ortho*Scanner du tibia et de la fibula (incluant les articulations sus et sous-jacentes) en complément en cas de doute à la radiographie, de discordance radio-clinique ou avant prise en charge chirurgicale à la demande du chirurgien.**",
            "pictures": [
                { "img": "753.jpg", "caption": "Radiographie de la jambe droite de face montrant deux foyers de fracture à trait spiroïde, l'un développé au dépens de la diaphyse tibiale dans sa région inférieure et l'autre au col anatomique de la fibula (étoiles rouges). L'attelle radiotransparente d'immobilisation est marquée par les flèches rouges." }
            ]
        },
        {
            "id": "7.5.4",
            "title": "Cheville - Pied",
            "description": "Le patient présente-t-il au moins un de ces critères?<br/>\
            [[55 ans ou plus]\
            [Douleur de la pointe et des 6cm distaux du bord postérieur des malléoles]\
            [Douleur de la base du Vème métatarsien]\
            [Douleur du scaphoïde tarsien (os Naviculaire)]\
            [Impossibilité de faire 4 pas immédiatement après le traumatisme ET aux urgences.]]",
            "leadsTo": ["7.5.4.0", "7.5.4.1"]
        },
        //start genou
        {
            "id": "7.5.2.0",
            "title": "Au moins un des critères",
            "description": "L'urgence est à l'antalgie, la réduction des déformations et à l'immobilisation du membre lésé, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies du genou de face et de profil en urgence.**<br/>\
            *o#gbu#ortho*Scanner du genou en cas de discordance radio-clinique ou de doute sur les radiographies.**",
            "pictures": [
                { "img": "7520.jpg", "caption": "Radiographie du genou gauche montrant un épanchement intra-articulaire (étoile rouge), et plusieurs traits de fracture de l'extrémité supérieure du tibia (flèches vertes). Voir aussi image 2." },
                { "img": "7520.0.jpg", "caption": "Reconstruction volumique sur le genou gauche d'un angio-scanner réalisé après la radiographie de l'image 1, montrant le foyer fracturaire complexe du plateau tibial latéral (flèche verte). Voir aussi image 1." }
            ]
        },
        {
            "id": "7.5.2.1",
            "title": "Aucun critère",
            "description": "*r*Pas d'examen d'imagerie indiqué en urgence.**",
        },
        //Start cheville pied
        {
            "id": "7.5.4.0",
            "title": "Au moins un des critères",
            "description": "L'urgence est à l'antalgie, la réduction des déformations et à l'immobilisation du membre lésé, et ce avant la réalisation des radiographies.<br/>\
            *v#gbu#ortho*Radiographies de la cheville de face et de profil et/ou du pied de face et de 3/4 en urgence en fonction des symptômes.**<br/>\
            *o#gbu#ortho*Scanner du site lésé en cas de discordance radio-clinique ou de doute sur une lésion osseuse à la radiographie.**",
            "pictures": [
                { "img": "7540.jpg", "caption": "Radiographie de la cheville gauche montrant un foyer fracturaire du versant fibulaire du dôme talien (flèche rouge), à risque arthrogène." },
                { "img": "7540.0.jpg", "caption": "Radiographie de la cheville droite de profil montrant une fracture de la base du cinquième métatarsien (flèche rouge)." }
            ]
        },
        {
            "id": "7.5.4.1",
            "title": "Aucun critère",
            "description": "*r#gbu#ortho*Pas d'examen d'imagerie indiqué en urgence.**",
        },
        //start SOS PDC
            {
            "id": "8.0",
            "title": "Scanner / Produits de contraste Iodés",
            "description": "Choisissez la rubrique voulue.",
            "leadsTo": ["8.0.0", "8.0.1", "8.0.2"]
        },
         {
            "id": "8.1",
            "title": "IRM / Sels de Gadolinium",
            "description": "Choisissez la rubrique voulue.",
            "leadsTo": ["8.1.0", "8.0.1", "8.0.2"]
        },
//start contraste et rein
            {
            "id": "8.0.0",
            "title": "Produits de contraste Iodés et rein",
            "description": "L'insuffisance rénale aigue post-contraste (IRA-PC) se définit comme l'augmentation de la créatininémie de plus de 26,5 micromol/l ou 1,5 fois le taux de base dans les 48H à 72H après injection de produit de contraste.",
            "leadsTo": ["8.0.0.0", "8.0.0.1", "8.0.0.2", "8.0.0.3", "8.0.0.4", "8.0.0.5", "8.0.0.6", "8.0.0.7"]
        },
        {
            "id": "8.0.0.0",
            "title": "Facteurs de risque",
            "description": "",
            "leadsTo": ["8.0.0.0.0", "8.0.0.0.1"]
        },
        {
            "id": "8.0.0.1",
            "title": "Seuil de risque",
            "description": "Injection par voie veineuse : DFG < 30 mL/min/1.73m2<br/>\
            Injection par voie artérielle : DFG < 45 mL/min/1.73m2",
        },
        {
            "id": "8.0.0.2",
            "title": "Chez qui dépister l'insuffisance rénale ?",
            "description": "",
            "leadsTo": ["8.0.0.2.0", "8.0.0.2.1"]
        },
        {
            "id": "8.0.0.3",
            "title": "Délai théorique à respecter entre deux injections",
            "description": "L'état clinique du patient permet-il de patienter ?",
            "leadsTo": ["8.0.0.3.0", "8.0.0.3.1"]    
        },
        {
            "id": "8.0.0.4",
            "title": "Quel(s) médicament(s) arrêter ?",
            "description": "",
            "leadsTo": ["8.0.0.4.0", "8.0.0.4.1", "8.0.0.4.2", "8.0.0.4.3"]    
        },
        {
            "id": "8.0.0.2.0",
            "title": "Hors urgence",
            "description": "Le patient présente :<br/>\
            [[Une pathologie rénale connue ou suspectée]\
            [Une maladie susceptible d'être associée à une atteinte rénale (lupus, ...)]\
            [Une hypertension artérielle]\
            [Un diabète sucré]]",
            "leadsTo": ["8.0.0.2.0.0", "8.0.0.2.0.1"]
        },
        {
            "id": "8.0.0.2.0.0",
            "title": "Oui",
            "description": "Dosage systématique de la créatininémie avant l'examen.<br/>\
            Un dosage de créatininémie est valable 3 mois en l'absence d'évènement intercurrent.",
        },
        {
            "id": "8.0.0.2.0.1",
            "title": "Non",
            "description": "Pas de dosage systématique de la créatininémie avant l'examen.",
        },
               {
            "id": "8.0.0.2.1",
            "title": "En urgence",
            "description": "L'état clinique du patient permet-il de réaliser un dosage de créatininémie ?",
            "leadsTo": ["8.0.0.2.1.0", "8.0.0.2.1.1"]
        },
            {
            "id": "8.0.0.2.1.0",
            "title": "Oui",
            "description": "Réaliser le dosage de la créatininémie avant l'injection.<br/>\
            Pour rappel : le dosage de la créatininémie est valable 3 mois en l'absence d'évènement intercurrent ou 7 jours chez un patient hospitalisé ou dans un contexte aigu.",
        },
        {
            "id": "8.0.0.2.1.1",
            "title": "Non",
            "description": "Imagerie avec injection en urgence. Dosage de la créatininémie dans les suites, avec protocole d'hydratation si besoin dans les suites de l'examen.",
        },
        {
            "id": "8.0.0.0.0",
            "title": "Liés au patient",
            "description": "[[Insuffisance rénale chronique pré-existante]\
            [Insuffisance rénale aigue connue ou suspectée]\
            [Déshydratation marquée]]",
            "leadsTo": ["8.0.0.0.1"]
        },
        {
            "id": "8.0.0.0.1",
            "title": "Liés à la procédure",
            "description": "[[Forte dose de produit de contraste administré en intra-artériel]\
            [Forte osmolalité du produit de contraste utilisé]\
            [Multiples injections de produit de contraste dans les 48 ou 72 H précédentes]\
            [Actes interventionnels cardio-vasculaires]]",
            "leadsTo": ["8.0.0.0.0"]
        },
        {
            "id": "8.0.0.3.0",
            "title": "Nécessité de réalisation du scanner injecté en urgence.",
            "description": "Surseoir à l'interdiction théorique d'injection rapprochée.",
        },
        {
            "id": "8.0.0.3.1",
            "title": "Attente possible devant l'état clinique.",
            "description": "Dans quel cas se trouve le patient ?",
            "leadsTo": ["8.0.0.3.1.0", "8.0.0.3.1.1", "8.0.0.3.1.2"]
          },
        {
            "id": "8.0.0.3.1.0",
            "title": "DFG > 30 mL/min/1.73m2",
            "description": "Délai de 4h recommandé entre deux injections",
        },             
         {
            "id": "8.0.0.3.1.1",
            "title": "DFG < 30 mL/min/1.73m2",
            "description": "Délai de 48h recommandé entre deux injections.",
          },
        {
            "id": "8.0.0.3.1.2",
            "title": "Dialyse",
            "description": "Délai de 48h recommandé entre deux injections.",
          },
        {
            "id": "8.0.0.4.0",
            "title": "METFORMINE",
            "description": "Dans quel cas se trouve votre patient ?",
            "leadsTo": ["8.0.0.4.0.0", "8.0.0.4.0.1", "8.0.0.4.0.2"]    
        },
        {
            "id": "8.0.0.4.0.0",
            "title": "Voie d'injection intra-artérielle",
            "description": "Arrêt de la METFORMINE le jour de l'examen et pendant 48h",
        },
        {
            "id": "8.0.0.4.0.1",
            "title": "Voie d'injection intra-veineuse",
            "description": "Quel est le DFG du patient ?",
            "leadsTo": ["8.0.0.4.0.1.0", "8.0.0.4.0.1.1"]    
        },
        {
            "id": "8.0.0.4.0.2",
            "title": "Insuffisance rénale aigue",
            "description": "Arrêt de la METFORMINE le jour de l'examen et ce pendant 48h",
        },
        {
            "id": "8.0.0.4.0.1.1",
            "title": "DFG > 30 mL/min/1.73m2",
            "description": "Pas d'arrêt de la METFORMINE.",
        },
        {
            "id": "8.0.0.4.1",
            "title": "AINS",
            "description": "Les AINS sont contre-indiqués en cas de maladie rénale stade IV et V.<br/>\
            Ils peuvent être arrêtés en fonction du rapport bénéfice/risque.",
        },
        {
            "id": "8.0.0.4.2",
            "title": "IEC / ARA2",
            "description": "Pas d'arrêt nécessaire sauf hypovolémie.",
        },
        {
            "id": "8.0.0.4.3",
            "title": "Diurétiques",
            "description": "Evaluation individualisée du risque d'insuffisance rénale aigue post injection de produit de contraste et de surcharge hydrosodée. Arrêt au besoin.",
        },
        {
            "id": "8.0.0.4.0.1.0",
            "title": "DFG < 30 mL/min/1.73m2",
            "description": "Arrêt de la METFORMINE le jour de l'examen et ce pendant 48h.",
        },
        {
            "id": "8.0.0.5",
            "title": "Cas particulier du patient dialysé",
            "description": "De quelle type de dialyse bénéficie le patient ?",
            "leadsTo": ["8.0.0.5.0", "8.0.0.5.1"]    
        },
        {
            "id": "8.0.0.5.0",
            "title": "Hémodialyse",
            "description": "Pas de contre-indication à l'injection de produit de contraste iodé. Pas nécessité d'ajout de séance de dialyse ou modification de la périodicité des séances habituelles.", 
        },
        {
            "id": "8.0.0.5.1",
            "title": "Dialyse péritonéale",
            "description": "Contacter le néphrologue pour discuter de l'intérêt d'un protocole d'hydratation spécifique.", 
        },
        {
            "id": "8.0.0.6",
            "title": "Cas particulier du myélome",
            "description": "[[L'hydratation systématique n'est pas nécessaire]\
            [Corriger l'hypercalcémie si elle existe]\
            [Un patient porteur de myélome sans insuffisance rénale pré-existante n'est pas considéré à risque rénal]]",
        },
        {
            "id": "8.0.0.7",
            "title": "Exemple de protocole d'hydratation si nécessaire",
            "description": "[[Le protocole doit être adapté à l'état clinique du patient, en étant particulièrement vigilant au patient insuffisant cardiaque et/ou insuffisant rénal terminal]\
            [En pratique : hydratation parentérale comprise entre 7 et 15 mL/kg de NaCl 0,9% ou Bicarbonate de Sodium 1,4%, à répartir avant, pendant et après l'injection de contraste.]\
            [L'hydratation orale n'est pas recommandée]]",
        },
        //start rein et gado
        {
            "id": "8.1.0",
            "title": "Sels de Gadolinium et rein",
            "description": "",
            "leadsTo": ["8.1.0.0", "8.1.0.1", "8.1.0.2"]
        },
        {
            "id": "8.1.0.0",
            "title": "Dépistage de l'insuffisance rénale avant injection ?",
            "description": "Pas de dosage de Créatininémie recommandée avant IRM injectée sauf si facteur de risque rénal (nécessité d'attente théorique de 7 jours entre deux injections de Gadolinium si DFG < 30 mL/min/1.73m2).",
        },
        {
            "id": "8.1.0.1",
            "title": "Dialyse après injection de sels de Gadolinium ?",
            "description": "Non recommandée en l'absence de preuve de son efficacité.",
        },
            {
            "id": "8.1.0.2",
            "title": "Délai entre deux injections de sels de Gadolinium",
            "description": "Le patient présente-il une insuffisance rénale aigue ?",
            "leadsTo": ["8.1.0.2.0", "8.1.0.2.1"]
        },
         {
            "id": "8.1.0.2.0",
            "title": "Le patient présente un DFG < 30 mL/min/1.73m2",
            "description": "Délai théorique de 7 jours entre deux injections. Le rapport bénéfice/risque prime et ce délai est seulement théorique.",
        },
        {
            "id": "8.1.0.2.1",
            "title": "Le patient présente un DFG > 30 mL/min/1.73m2",
            "description": "Pas de délai particulier à respecter.",
        },
//start extravasation
            {
            "id": "8.0.1",
            "title": "Extravasation",
            "description": "",
            "leadsTo": ["8.0.1.0", "8.0.1.1", "8.0.1.2"]
        },
            {
            "id": "8.0.1.0",
            "title": "Avant injection - Prévenir l'extravasation (dans le service d'imagerie)",
            "description": "[[Eviter d'utiliser une voie veineuse déjà en place]\
            [Recourir à un cathéter court en adaptant le débit au calibre]\
            [Privilégier une voie au pli du coude]\
            [Eviter toute compression du membre perfusé]\
            [Vérifier le cathéter par une injection test]\
            [Prévenir le patient du risque et lui demander de se manifester en cas de douleur]\
            [Surveiller le début de l'injection (surveillance visuelle et tactile)]]",
            "leadsTo": ["8.0.1.1", "8.0.1.2"]
        },
            {
            "id": "8.0.1.1",
            "title": "Extravasation - mesures immédiates (dans le service d'imagerie)",
            "description": "[[Arrêt immédiat de l'injection si perception du problème]\
            [Tentative d'aspiration du produit extravasé par le cathéter laissé en place]\
            [Surélévation du membre pendant les 3 heures suivantes]\
            [Application de glace (pendant au moins 20 minutes ; puis toutes les heures pendant 6 heures]\
            [L'utilisation de hyaluronidase n'est plus recommandée]\
            [Le pansement alcoolisé n'a pas prouvé son efficacité]]",
            "leadsTo": ["8.0.1.0", "8.0.1.2"]
        },
        {
            "id": "8.0.1.2",
            "title": "Extravasation - mesures différées (hors du service d'imagerie)",
            "description": "[[Evaluation de la gravité potentielle]\
            [Estimation du volume injecté]\
            [Réalisation de clichés radiographiques dédiés (zone d'extravasation)]\
            [Recherche de signes de mauvaise tolérance (peau cartonnée, phlyctènes, oedème important, trouble de perfusion distale, paresthésies, hypoesthésie, diminution de force musculaire)]\
            [En cas de signes de mauvaise tolérance, s'apparentant cliniquement à un syndrome des loges : avis chirurgical en urgence (chirurgie plastique au CHRU de Tours]\
            [Information du patient]\
            [Contrôle médical le lendemain en cas de signe de gravité]\
            [Signalement de l'extravasation dans le compte-rendu et au(x) médecin(s) traitant(s)]]",   
            "leadsTo": ["8.0.1.0", "8.0.1.1"]
        },
//start allergie et PDC
        {
            "id": "8.0.2",
            "title": "Allergie",
            "description": "",
            "leadsTo" : ["8.0.2.0", "8.0.2.1", "8.0.2.2", "8.0.2.3"]          
        },
        {
            "id": "8.0.2.0",
            "title": "Evaluation de la gravité clinique",
            "description": "Classification de Ring et Messmer des réactions d'hypersensibilités immédiates:<br/>\
            [[Grade I : signes cutanéo-muqueux (érythème étendu, urticaire localisée ou étendue, avec ou sans angioedème)]\
            [Grade II : atteinte multiviscérale modérée (signes cutanéo-muqueux, hypotension, tachycardie, toux, dyspnée, sibilants, signes digestifs)]\
            [Grade III : atteinte mono ou multiviscérale grave (collapsus, tachycardie, troubles du rythme cardiaque, bronchospasme, signes digestifs)]\
            [Grade IV : arrêt cardiaque]]",      
            "leadsTo" : ["8.0.2.1", "8.0.2.2", "8.0.2.3"]    
        },
        {
            "id": "8.0.2.1",
            "title": "Facteurs de risque d'une réaction d'hypersensibilité immédiate aux produits de contraste",
            "description": "Hypersensibilité non allergique<br/>\
            [[Asthme non équilibré : avis pneumologique nécessaire le cas échéant]\
            [Atopie / terrain histaminolibérateur : réaction souvent peu sévère]]\<br/><br/>\
            Hypersensibilité allergique<br/>\
            [[Antécédent de réaction d'hypersensibilité allergique immédiate à un produit de contraste]]<br/>\
            Pour rappel : l'asthme et les bêta-bloquants ne sont pas des facteurs de risque d'hypersensibilité allergique.<br/>\
            La réaction croisée entre produit de contraste iodé et gadoliné n'existe pas.",     
            "leadsTo" : ["8.0.2.2", "8.0.2.3"]    
        },
        {
            "id": "8.0.2.2",
            "title": "Fausses croyances / Idées reçues",
            "description": "[[Les réactions allergiques à la povidone iodée (comme la Bétadine) ou aux poissons, mollusques et crustacés ne sont pas dues à l'Iode (qui est un atome), mais aux molécules biocompatibles qui portent l'atome.<br/>\
            Ainsi, ces antécédents ne sont pas des facteurs de risque de réaction allergique au produit de contraste Iodé]\
            [Un antécédent de réaction anaphylactique à un médicament autre qu'un produit de contraste ne constitue pas un facteur de risque d'anaphylaxie au produit de contraste]\
            [La prémédication n'empêche pas les réactions graves]\
            [L'asthme et les bêta-bloquants ne sont pas des facteurs de risque d'hypersensibilité allergique]\
            [La réaction croisée entre produit de contraste iodé et gadoliné n'est pas prouvée]]\
            ",
              "leadsTo" : ["8.0.2.1", "8.0.2.3"]    
        },
        {
            "id": "8.0.2.3",
            "title": "Prévention du risque allergique.",
            "description": "De quelle situation relève votre patient?",
            "leadsTo" : ["8.0.2.3.0", "8.0.2.3.1"]    
        },
        {
            "id": "8.0.2.3.0",
            "title": "Antécédent de réaction immédiate et bilan allergologique réalisé.",
            "description": "Si le bilan a confirmé la responsabilité du produit de contraste injecté et a permis d'identifier un ou plusiurs produits non réactifs en test cutané, un de ceux-ci pourra être proposé pour les injections ultérieures.",
        },
        {
            "id": "8.0.2.3.1",
            "title": "Antécédent de réaction immédiate et bilan allergologique non réalisé.",
            "description": "De quelle situation clinique relève le patient ?",
            "leadsTo" : ["8.0.2.3.1.0", "8.0.2.3.1.1"]   
        },
        {
            "id": "8.0.2.3.1.0",
            "title": "L'examen doit être réalisé en urgence.",
            "description": "[[Discuter la réalisation d'une autre modalité d'imagerie (pas de réaction croisée produits de contraste Iodés - sels de Gadolinium.) ou de la rentabilité d'un examen non injecté]\
            [Si le recours à l'injection de produit de contraste est indispensable, l'injection d'un produit de contraste que le patient n'a jamais reçu est recommandé]\
            [Discuter l'intérêt de l'injection de Gadolinium au scanner (en fonction de l'indication) si allergie au produit de contraste Iodé]]",
        },
        {
            "id": "8.0.2.3.1.1",
            "title": "L'examen n'est pas urgent.",
            "description": "Bilan allergologique spécialisé recommandé avant toute injection de produit de contraste.",
        },
    ];

    startingOnes() {
        let ret = [];
        this.dataBase.forEach(element => {
            if (element.type === 'initial')
                ret.push(element);
        });
        return ret;
    }

    getDataById(id: string) {
        let ret = null;
        let defaut = null;
        this.dataBase.forEach(element => {
            if (element.id === id) {
                ret = element;
            }
            if (element.id === "Erreur") {
                defaut = element;
            }
        });
        if (ret === null) {
            ret = defaut;
        }
        return ret;
    }
}