<accordion-group heading="{{title}}" [isOpen]="isFirstOpen">
  <div class="modal-body" [innerHTML]="description"></div>
  <div class="modal-footer,container">
    <div class="row">
        <div class="border,rounded,button," *ngFor="let son of sons" 
        [ngClass]="colNumber2bootstrap('fils')" (click)="selectSon(son.id)">
        {{son.title}}
        </div>
    </div>
</div>
<br/>
<div class="row">
      <div *ngFor="let i of images" 
      [ngClass]="colNumber2bootstrap('image')">
      <img class="image" src="assets/{{i.img}}" 
      (click)="openModal(i)" data-toggle="modal" data-placement="right" title="{{i.caption}}"/><br/>
      {{i.caption}}
      </div>
  </div>
</accordion-group>
<ng-container *ngIf="selectedSon!=0">
  <app-form-step [id]="selectedSon"></app-form-step>  
</ng-container>
